import React from 'react';
import SettingSection from '../SettingSection';

const teams = [
    { value: 'None', label: 'None' },
    { value: 'Silver', label: 'Silver' },
    { value: 'Blue', label: 'Blue' },
    { value: 'Transplant', label: 'Transplant' }
];

function TeamSection({ localSettings, handleSettingChange }) {
    return (
        <SettingSection title="Team">
            <div className="inline-flex rounded-md shadow-sm" role="group">
                {teams.map((team, index) => (
                    <button
                        key={team.value}
                        type="button"
                        onClick={() => handleSettingChange('team', team.value)}
                        className={`
                            px-4 py-2 text-sm font-medium
                            ${index === 0 ? 'rounded-l-lg' : ''}
                            ${index === teams.length - 1 ? 'rounded-r-lg' : ''}
                            ${localSettings.team === team.value
                                ? 'bg-[#1976d2] text-white'
                                : 'bg-[#2a2a2a] text-[#e0e0e0] hover:bg-[#323232] hover:text-white'
                            }
                            focus:z-10 focus:ring-1 focus:ring-white focus:text-white flash-button
                            transition-colors duration-200 ease-in-out
                            border-t border-b ${index === 0 ? 'border-l' : ''} ${index === teams.length - 1 ? 'border-r' : ''}
                            border-[#404040]
                        `}
                    >
                        {team.label}
                    </button>
                ))}
            </div>
        </SettingSection>
    );
}

export default TeamSection; 