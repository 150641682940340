import React, { useState, useEffect } from 'react';
import DotPhraseTextArea from './DotPhraseTextArea';
import AudioTranscription from './buttons/AudioTranscriptionButton';
import BillingButton from './buttons/BillingButton';
import HashProblemsButton from './buttons/HashProblemsButton';
import AIFormatComparison from './AIFormatComparison';
import { useTabNavigation } from '../../hooks/useTabNavigation';
import getDefaultSectionText from '../data/defaultSectionText';
import NoteEditorSectionButtons from './buttons/NoteEditorSectionButtons';
import PatientNameField from '../common/PatientNameField';

export const sectionLabels = {
    consultInfo: 'Consult Information',
    background: 'Background',
    hpi: 'History of Present Illness',
    medicalHistory: 'Medical History | Surgical History | Allergies',
    socialHistory: 'Social History',
    antimicrobials: 'Medications | Antimicrobials',
    physicalExam: 'Physical Examination',
    labs: 'Labs | Microbiology',
    imaging: 'Radiology | Cardiology',
    recommendations: 'Assessment and Recommendations'
};

const NoteEditorSections = ({
    note,
    currentPreset,
    updateNote,
    summarizeNote,
    isLoading,
    isSummarizing,
    handleCopySection,
    handleTranscriptionUpdate,
    startRecordingSession,
    endRecordingSession,
    presetSections,
    actionSuccess,
    refs,
    pinnedSection,
    setPinnedSection,
    collapseHeader,
    showComparisons,
    setShowComparisons,
    preFormatTexts,
    setPreFormatTexts,
}) => {
    const headerHeight = collapseHeader ? '48px' : '96px';
    const [activeRecordingSection, setActiveRecordingSection] = useState(null);
    const [activeSection, setActiveSection] = useState(null);
    const [collapsedSections, setCollapsedSections] = useState(note.collapsedSections || {});

    // Update collapsedSections when note changes
    useEffect(() => {
        setCollapsedSections(note.collapsedSections || {});
    }, [note.id, note.collapsedSections]);

    const toggleCollapse = (section) => {
        const newCollapsedSections = {
            ...collapsedSections,
            [section]: !collapsedSections[section]
        };
        setCollapsedSections(newCollapsedSections);
        updateNote('collapsedSections', newCollapsedSections);
    };

    const renderSection = (section, label) => {
        // Parse label to handle | separated lists
        const labelParts = label.split('|').map(part => part.trim());
        
        return (
            <div 
                className={`mb-6 ${
                    pinnedSection === section 
                        ? 'sticky z-10 surface-bg bg-opacity-70 p-4 shadow-lg border border-blue-500 rounded-lg backdrop-filter backdrop-blur-sm' 
                        : ''
                }`} 
                style={pinnedSection === section ? { 
                    top: `calc(${headerHeight} + 4px)`,
                    bottom: headerHeight,
                    // This ensures the element sticks to either top or bottom depending on scroll direction
                    position: 'sticky'
                } : {}}
                key={section}
            >
                <div className={`flex items-center justify-between ${
                    collapsedSections[section] && pinnedSection !== section 
                        ? 'bg-[#2a2a2a] bg-opacity-40 rounded-md' 
                        : ''
                }`}>
                    <div 
                        className="flex items-center gap-2 flex-wrap mb-0 cursor-pointer"
                        onClick={() => pinnedSection !== section && toggleCollapse(section)}
                    >
                        {labelParts.map((part, index) => (
                            <span 
                                key={index}
                                className={`px-3 py-1.5 text-sm font-medium base-surface surface-border rounded-t-md ${
                                    collapsedSections[section] && pinnedSection !== section
                                        ? 'text-gray-400'  // Greyed out text for collapsed sections
                                        : 'text-white'
                                }`}
                            >
                                {part}
                            </span>
                        ))}
                    </div>
                    <div className="flex items-center gap-1">
                        {/* Pin section button */}
                        {!collapsedSections[section] && (
                            <button
                                onClick={() => setPinnedSection(pinnedSection === section ? null : section)}
                                className={`p-1 rounded-t transition-colors duration-200 flash-button ${
                                    pinnedSection === section 
                                        ? 'text-[#90caf9] bg-[#1976d2] bg-opacity-20' 
                                        : 'text-[#bdbdbd] hover:text-[#e0e0e0]'
                                }`}
                                title={pinnedSection === section ? "Unpin section" : "Pin section"}
                            >
                                <span className={`material-icons text-base transition-transform duration-200 ${
                                    pinnedSection === section ? 'transform rotate-90' : ''
                                }`}>
                                    push_pin
                                </span>
                            </button>
                        )}
                    </div>
                </div>

                {(!collapsedSections[section] || pinnedSection === section) && (
                    <>
                        <div className="relative -mt-[1px]">
                            <DotPhraseTextArea
                                section={section}
                                id={section}
                                value={note[section] || getDefaultSectionText(section, currentPreset) || ""}
                                onChange={(e) => updateNote(section, e.target.value)}
                                ref={refs[`${section}Ref`]}
                                fixedHeight={pinnedSection === section ? 200 : undefined}
                                isRecording={activeRecordingSection === section}
                                onFocus={() => setActiveSection(section)}
                                onBlur={() => setActiveSection(null)}
                                presetSections={presetSections}
                                currentPreset={currentPreset}
                                allRefs={refs}
                                borderRounding= "rounded-tr rounded-b-md"
                                collapsedSections={collapsedSections}
                                pinnedSection={pinnedSection}
                            />
                        </div>

                        {pinnedSection !== section && (
                            <div className="mt-2">
                                <div className="flex justify-between">
                                    <div>
                                        {section === 'recommendations' && (
                                            <div className="flex items-center gap-2">
                                                <BillingButton 
                                                    assessment={note[section] || ''} 
                                                    updateNote={updateNote}
                                                />
                                                <HashProblemsButton
                                                    assessment={note[section] || ''}
                                                    updateNote={(text) => updateNote(section, text)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <NoteEditorSectionButtons 
                                        section={section}
                                        handleCopySection={handleCopySection}
                                        handleSummarize={handleSummarize}
                                        isLoading={isLoading}
                                        isSummarizing={isSummarizing}
                                        actionSuccess={actionSuccess}
                                        preFormatText={preFormatTexts[section]}
                                        setShowComparison={setShowComparisons}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    const handleRecordingStart = (section) => {
        setActiveRecordingSection(section);
        startRecordingSession(section);
    };

    const handleRecordingEnd = (section) => {
        setActiveRecordingSection(null);
        endRecordingSession(section);
    };

    const handleSummarize = async (section) => {
        // Store the pre-format state for this section right before formatting
        if (note[section]) {
            const newPreFormatTexts = {
                ...preFormatTexts,
                [section]: note[section]
            };
            setPreFormatTexts(newPreFormatTexts);
            updateNote('preFormatTexts', newPreFormatTexts);
        }
        
        // Call the original summarize function
        await summarizeNote(section);
    };

    const handleRevertFormat = (section) => {
        if (preFormatTexts[section]) {
            updateNote(section, preFormatTexts[section]);
            setShowComparisons(prev => ({
                ...prev,
                [section]: false
            }));
            // Remove the pre-format text and update the backend
            setPreFormatTexts(prev => {
                const newState = { ...prev };
                delete newState[section];
                updateNote('preFormatTexts', newState);
                return newState;
            });
        }
    };

    const handleCloseComparison = (section) => {
        // Only hide the modal without changing state
        setShowComparisons(prev => ({
            ...prev,
            [section]: false
        }));
    };

    const handleCompleteComparison = (section) => {
        // First update the note content using handleUpdateNote to maintain undo stack
        updateNote(section, note[section]);
        
        // Then handle UI state updates
        setShowComparisons(prev => ({
            ...prev,
            [section]: false
        }));
        
        // Update preFormatTexts
        setPreFormatTexts(prev => {
            const newState = { ...prev };
            delete newState[section];
            updateNote('preFormatTexts', newState);
            return newState;
        });
    };

    const handleCompleteAndNextComparison = (currentSection) => {
        // First update the note content using handleUpdateNote to maintain undo stack
        updateNote(currentSection, note[currentSection]);
        
        // Get the list of sections before completing the current one
        const preformatSections = Object.keys(preFormatTexts);
        const currentIndex = preformatSections.indexOf(currentSection);
        
        // Find the next valid section
        let nextIndex = currentIndex;
        let nextSection = null;
        
        // Look for the next section that isn't the current one
        do {
            nextIndex = (nextIndex + 1) % preformatSections.length;
            nextSection = preformatSections[nextIndex];
            if (nextIndex === currentIndex) break; // Prevent infinite loop
        } while (nextSection === currentSection);
        
        // Update UI state
        setShowComparisons(prev => ({
            ...prev,
            [currentSection]: false
        }));

        // Update preFormatTexts
        const newPreFormatTexts = { ...preFormatTexts };
        delete newPreFormatTexts[currentSection];
        setPreFormatTexts(newPreFormatTexts);
        updateNote('preFormatTexts', newPreFormatTexts);

        // Show next comparison if available
        if (nextSection && nextSection !== currentSection) {
            // Use setTimeout to ensure this runs after the state updates
            setTimeout(() => {
                setShowComparisons(prev => ({
                    ...prev,
                    [nextSection]: true
                }));
            }, 0);
        }
    };

    return (
        <>
            <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
                <div className="mb-6 mt-6">
                    <span className="px-3 py-1.5 text-sm font-medium base-surface surface-border text-white rounded-t-md">
                        Patient Name
                    </span>
                    <PatientNameField
                        value={note.title === "Untitled Note" ? "" : note.title || ""}
                        onChange={(e) => updateNote('title', e.target.value || "Untitled Note")}
                        placeholder={note.title === "Untitled Note" ? "Untitled Note" : ""}
                        ref={refs.titleRef}
                        onKeyDown={useTabNavigation('title', presetSections, currentPreset, refs)}
                        inputStyle={{
                            borderTopLeftRadius: '0',
                        }}
                    />
                </div>

                {presetSections[currentPreset].map(section => 
                    renderSection(section, sectionLabels[section])
                )}
                
                <AudioTranscription
                    onTranscriptionUpdate={(transcript, isFinal) => 
                        handleTranscriptionUpdate(activeSection, transcript, isFinal)
                    }
                    onRecordingStart={() => handleRecordingStart(activeSection)}
                    onRecordingEnd={() => handleRecordingEnd(activeSection)}
                    isActiveSection={activeSection !== null}
                />
            </form>

            {/* Render modals outside the form */}
            {Object.keys(preFormatTexts).map(section => (
                <AIFormatComparison
                    key={section}
                    isVisible={showComparisons[section]}
                    originalText={preFormatTexts[section]}
                    formattedText={note[section]}
                    onClose={() => handleCloseComparison(section)}
                    onComplete={() => handleCompleteComparison(section)}
                    onCompleteAndNext={() => handleCompleteAndNextComparison(section)}
                    onRevert={() => handleRevertFormat(section)}
                    sectionName={sectionLabels[section]}
                    onTextChange={(newText) => updateNote(section, newText)}
                    hasMoreComparisons={Object.keys(preFormatTexts).length > 1}
                />
            ))}
        </>
    );
};

export default NoteEditorSections;