import React, { useState, useMemo, useRef, useEffect } from 'react';

/**
 * PatientNameField - A reusable component for patient name input fields
 * that highlights numbers and date-like patterns.
 * 
 * @param {Object} props - Component props
 * @param {string} props.value - The current input value
 * @param {Function} props.onChange - Function called when value changes
 * @param {string} props.placeholder - Placeholder text
 * @param {string} props.id - Input field ID
 * @param {boolean} props.disabled - Whether the field is disabled
 * @param {Function} props.onFocus - Function called when field is focused
 * @param {Function} props.onBlur - Function called when field loses focus
 * @param {Function} props.onKeyDown - Function called on key down events
 * @param {Object} props.style - Additional styles for the container
 * @param {Object} props.inputStyle - Additional styles for the input element
 */
const PatientNameField = React.forwardRef(({ 
    value = '', 
    onChange, 
    placeholder = 'Enter patient name',
    id,
    disabled = false,
    onFocus,
    onBlur,
    onKeyDown,
    style = {},
    inputStyle = {},
}, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const textareaRef = useRef(null);

    // Extract out number patterns for highlighting
    const { textParts } = useMemo(() => {
        const parts = [];
        
        // Match both plain numbers and date-like patterns
        const numberPattern = /\b\d+(?:\/\d+)*\b/g;
        let match;
        let lastIndex = 0;

        while ((match = numberPattern.exec(value)) !== null) {
            // Add text before the number
            if (match.index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: value.slice(lastIndex, match.index)
                });
            }
            // Add the number
            parts.push({
                type: 'number',
                content: match[0]
            });
            lastIndex = match.index + match[0].length;
        }
        
        // Add remaining text
        if (lastIndex < value.length) {
            parts.push({
                type: 'text',
                content: value.slice(lastIndex)
            });
        }

        return { textParts: parts };
    }, [value]);

    // Auto-resize the textarea to fit content
    const autoResize = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    // Handle input and resize
    const handleChange = (e) => {
        onChange(e);
        setTimeout(autoResize, 0);
    };

    // Handle focus events
    const handleFocus = (e) => {
        setIsFocused(true);
        if (onFocus) onFocus(e);
    };

    // Handle blur events
    const handleBlur = (e) => {
        setIsFocused(false);
        if (onBlur) onBlur(e);
    };

    // Resize on window resize and initial render
    useEffect(() => {
        autoResize();
        window.addEventListener('resize', autoResize);
        return () => window.removeEventListener('resize', autoResize);
    }, [value]);

    return (
        <div className="relative" style={style}>
            <textarea
                ref={(element) => {
                    textareaRef.current = element;
                    if (typeof ref === 'function') {
                        ref(element);
                    } else if (ref) {
                        ref.current = element;
                    }
                }}
                id={id}
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={onKeyDown}
                disabled={disabled}
                className="block w-full rounded-md input-base transition duration-150 ease-in-out shadow-sm px-3 py-2 resize-none overflow-hidden"
                style={{
                    caretColor: 'white',
                    color: isFocused ? 'white' : 'transparent',
                    minHeight: '42px',
                    height: 'auto',
                    ...inputStyle
                }}
                rows={1}
                onInput={autoResize}
                autoComplete="off"
            />
            {!isFocused && (
                <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none px-3 py-2 text-white overflow-hidden">
                    <div className="flex flex-wrap items-center w-full">
                        {textParts.map((part, index) => (
                            part.type === 'text' ? (
                                <span key={`text-${index}`} className="whitespace-pre-wrap break-words">
                                    {part.content}
                                </span>
                            ) : (
                                <span
                                    key={`number-${index}-${part.content}`}
                                    className="inline-flex items-center px-1.5 py-0.5 rounded-md text-xs bg-blue-500 bg-opacity-20 text-blue-300"
                                    style={{ 
                                        wordBreak: 'break-all',
                                        maxWidth: '200px',
                                        overflow: 'hidden'
                                    }}
                                >
                                    {part.content}
                                </span>
                            )
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});

export default PatientNameField; 