import React, { useState, useEffect, useCallback } from 'react';
import { useSettings } from '../../context/SettingsContext';
import PresetSection from './sections/PresetSection';
import AIProviderSection from './sections/AIProviderSection';
import SignoutSection from './sections/SignoutSection';
import TeamSection from './sections/TeamSection';
import BaseModal from '../common/BaseModal';

function SettingsDialog({ isOpen, setIsOpen }) {
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const { settings, updateSettings } = useSettings();
    const [localSettings, setLocalSettings] = useState({ ...settings });

    const handleSettingChange = useCallback((key, value) => {
        setLocalSettings(prevSettings => ({ ...prevSettings, [key]: value }));
    }, []);

    const handleSaveSettings = async () => {
        setIsSaving(true);
        setSaveError(null);
        try {
            await updateSettings(localSettings);
            setIsOpen(false);
        } catch (error) {
            console.error('Error saving settings:', error);
            setSaveError('Failed to save settings. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        setLocalSettings(settings);
    }, [settings]);

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title="Settings"
            maxWidth="2xl"
            className="max-h-[90vh] overflow-y-auto text-gray-100"
        >
            <PresetSection
                localSettings={localSettings}
                handleSettingChange={handleSettingChange}
            />

            <TeamSection
                localSettings={localSettings}
                handleSettingChange={handleSettingChange}
            />

            <AIProviderSection
                localSettings={localSettings}
                handleSettingChange={handleSettingChange}
            />

            <SignoutSection
                localSettings={localSettings}
                handleSettingChange={handleSettingChange}
            />

            {saveError && <p className="text-red-500 mb-3 sm:mb-4">{saveError}</p>}

            <div className="flex justify-center mt-6 sm:mt-8">
                <button
                    onClick={handleSaveSettings}
                    disabled={isSaving}
                    className="px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white flash-button border surface-border"
                >
                    {isSaving ? (
                        <>
                            <span className="material-symbols-outlined text-white text-base animate-spin mr-1">hourglass_empty</span>
                            <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Saving...</span>
                        </>
                    ) : (
                        <>
                            <span className="material-icons text-white text-base mr-1">save</span>
                            <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Save Settings</span>
                        </>
                    )}
                </button>
            </div>

            {saveError && <p className="text-red-500 mt-3 sm:mt-4 text-center">{saveError}</p>}
        </BaseModal>
    );
}

export default SettingsDialog; 