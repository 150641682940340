import React, { useState, useEffect, useCallback, useRef } from 'react';
import useCards from '../../hooks/useCards';
import CardItemDisplayView from './CardItemDisplayView';
import CardItemEditView from './CardItemEditView';
import CardItemActionButtons from './buttons/CardItemActionButtons';
import OPATNoteModal from './OPATNoteModal';

export const handleOPATSubmit = (card, parsedData, updateFn) => {
    // Merge all fields, appending strings with newlines
    const updatedCard = {
        ...card,
        ...Object.fromEntries(
            Object.entries(parsedData).map(([key, value]) => [
                key,
                typeof value === 'string' && card[key]
                    ? `${card[key]}\n\n${value}`
                    : value
            ])
        )
    };
    
    // Call the update function
    updateFn(updatedCard);
    return updatedCard;
};

const CardItem = ({ 
    card, 
    onUpdate, 
    onEditingStateChange, 
    isCurrentlyEditable, 
    isAttestationView = false 
}) => {
    const { countFilledFields } = useCards();
    // Local state only for tracking changes to card data, not editing state
    const [editedCard, setEditedCard] = useState({...card});
    const [isSaving, setIsSaving] = useState(false);
    const [localCard, setLocalCard] = useState({...card});
    const [showOPATModal, setShowOPATModal] = useState(false);
    
    // Reference to track previous editable state
    const prevEditableRef = useRef(isCurrentlyEditable);
    
    // Reset edited card data when card prop changes or edit mode starts
    useEffect(() => {
        if (!isCurrentlyEditable) {
            // Only update if not currently editing to avoid overwriting user changes
            setEditedCard({...card});
            setLocalCard({...card});
        } else {
            // When entering edit mode, reset to make sure we're working with latest data
            setEditedCard({...card});
        }
    }, [card, isCurrentlyEditable]);
    
    // When a card transitions from editable to non-editable, save its changes
    useEffect(() => {
        // If we were editable and now we're not, save changes
        if (prevEditableRef.current && !isCurrentlyEditable) {
            // Only save if there are changes
            if (JSON.stringify(editedCard) !== JSON.stringify(card)) {
                // Create a copy of the current edited card
                const cardToSave = {...editedCard};
                
                // Update the parent component
                onUpdate(cardToSave);
                
                // Also update local state
                setLocalCard(cardToSave);
            }
        }
        
        // Update ref for next render
        prevEditableRef.current = isCurrentlyEditable;
    }, [isCurrentlyEditable, card, editedCard, onUpdate]);
    
    // Add keyboard event listener for ESC key and Ctrl+S to save and exit editing mode
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key === 's' && isCurrentlyEditable) {
                e.preventDefault();
                saveChanges(e);
            } else if (e.key === 'Escape' && isCurrentlyEditable) {
                e.preventDefault();
                cancelEditing(e);
            }
        };
        
        if (isCurrentlyEditable) {
            document.addEventListener('keydown', handleKeyDown);
        }
        
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isCurrentlyEditable]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const handleChange = (field, value) => {
        setEditedCard(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Function to save changes - simplified to use the hook's updateCardWithAPI
    const saveChanges = useCallback(async (e) => {
        if (e) {
            e.stopPropagation();
        }
        
        // Tell parent we're done editing
        if (onEditingStateChange) {
            onEditingStateChange(card.id, false);
        }
        
        // Don't continue with save if no changes
        if (JSON.stringify(editedCard) === JSON.stringify(card)) {
            return;
        }
        
        try {
            setIsSaving(true);
            
            // Create a copy of the current edited card
            const cardToSave = {...editedCard};
            
            // Update the parent component
            onUpdate(cardToSave);
            
            // Also update local state
            setLocalCard(cardToSave);
        } catch (error) {
            console.error('Error saving card:', error);
            // Show error message to user
            alert('Failed to save changes. Please try again.');
            
            // Revert to original state
            setLocalCard({...card});
            onUpdate(card);
        } finally {
            setIsSaving(false);
        }
    }, [card, editedCard, onUpdate, onEditingStateChange]);

    // Function to cancel editing without saving
    const cancelEditing = (e) => {
        if (e) {
            e.stopPropagation();
        }
        
        // Tell parent we're done editing
        if (onEditingStateChange) {
            onEditingStateChange(card.id, false);
        }
        
        // Reset card data to original
        setEditedCard({...card});
    };

    // Inside the component, you would use the same function:
    const handleOPATModalSubmit = (parsedData) => {
        handleOPATSubmit(card, parsedData, onUpdate);
        setShowOPATModal(false);
    };

    // Field configuration for both view and edit modes
    const fieldConfig = [
        {
            id: 'title',
            label: 'Patient Name:',
            type: 'input',
            fullWidth: true,
        },
        {
            id: 'consultInfo',
            label: 'Main Diagnoses:',
            type: 'textarea',
            fullWidth: true,
        },
        {
            id: 'background',
            label: 'Background:',
            type: 'textarea',
            fullWidth: true,
        },
        {
            id: 'labs',
            label: 'Labs/Micro:',
            type: 'textarea',
            fullWidth: false, // Half width in a row
        },
        {
            id: 'antimicrobials',
            label: 'Antimicrobials:',
            type: 'textarea',
            fullWidth: false, // Half width in a row
        },
        {
            id: 'imaging',
            label: 'Imaging:',
            type: 'textarea',
            fullWidth: false, // Half width in a row
        },
        {
            id: 'recommendations',
            label: 'Plan/Notes:',
            type: 'textarea',
            fullWidth: true,
        },
    ];

    // Use the countFilledFields from the hook
    const filledFieldsCount = countFilledFields(localCard);

    // View mode rendering
    if (!isCurrentlyEditable) {
        return (
            <div className="relative h-full">
                <CardItemDisplayView 
                    card={localCard} 
                    fieldConfig={fieldConfig} 
                    filledFieldsCount={filledFieldsCount} 
                />

                <OPATNoteModal
                    isOpen={showOPATModal}
                    onClose={() => setShowOPATModal(false)}
                    onSubmit={handleOPATModalSubmit}
                />
            </div>
        );
    }

    // Edit mode rendering
    return (
        <div 
            className={`surface-bg ${!isAttestationView ? 'shadow-lg rounded-lg border border-blue-500' : ''} relative overflow-hidden`}
            data-card-id={card.id}
            onClick={(e) => e.stopPropagation()}
            onDragStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <CardItemEditView 
                card={editedCard} 
                fieldConfig={fieldConfig} 
                isSaving={isSaving} 
                onChange={handleChange} 
            />
        </div>
    );
};

export default CardItem; 