import { useState, useCallback, useEffect } from 'react';
import dotPhrases from '../components/data/dotPhrases';

export const useDotPhrase = (value, cursorPosition, onChange, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [matchingPhrases, setMatchingPhrases] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [dropdownPosition, setDropdownPosition] = useState({});

    const updateMatchingPhrases = useCallback(() => {
        const words = value.slice(0, cursorPosition).split(/\s+/);
        const lastWord = words[words.length - 1];

        // Check for dot phrases
        if (/^\.[a-zA-Z]+/.test(lastWord)) {
            const matches = Object.keys(dotPhrases).filter(key => key.startsWith(lastWord));
            setMatchingPhrases(matches);
            setIsVisible(matches.length > 0);
            setSelectedIndex(0);
            return true;
        }
        
        // If no dot phrase found, hide dot phrase dropdown
        setIsVisible(false);
        return false;
    }, [value, cursorPosition]);

    const handleSelect = useCallback((phrase) => {
        const replacement = dotPhrases[phrase];
        const beforeCursor = value.slice(0, cursorPosition);
        const afterCursor = value.slice(cursorPosition);

        // Find the dot phrase both before and potentially after the cursor
        const fullText = value;
        let startPosition = -1;
        let endPosition = -1;
        let newCursorPosition = cursorPosition;
        
        // Scan backwards from cursor to find the dot
        for (let i = cursorPosition - 1; i >= 0; i--) {
            if (fullText[i] === '.') {
                // Check if this is the start of a dot phrase that matches our selected phrase
                const potentialPhrase = fullText.substring(i, fullText.length);
                if (potentialPhrase.startsWith(phrase.slice(0, Math.min(cursorPosition - i, phrase.length)))) {
                    startPosition = i;
                    
                    // Find the end of this word (space or end of text)
                    for (let j = cursorPosition; j <= fullText.length; j++) {
                        if (j === fullText.length || /\s/.test(fullText[j])) {
                            endPosition = j;
                            break;
                        }
                    }
                    break;
                }
            }
            
            // Stop if we hit whitespace - we've gone past a word boundary
            if (/\s/.test(fullText[i])) {
                break;
            }
        }
        
        // If we found a valid dot phrase position
        if (startPosition !== -1 && endPosition !== -1) {
            // Replace the entire phrase regardless of cursor position
            const newContent = fullText.substring(0, startPosition) + 
                              replacement + 
                              fullText.substring(endPosition);
            newCursorPosition = startPosition + replacement.length;
            onChange({ target: { value: newContent } });
        } else {
            // Fall back to the original behavior for backwards compatibility
            const lastWordMatch = beforeCursor.match(/\.[a-zA-Z]\w*$/);
            const lastWord = lastWordMatch ? lastWordMatch[0] : '';
            const newContent = beforeCursor.slice(0, -lastWord.length) + replacement + afterCursor;
            newCursorPosition = beforeCursor.length - lastWord.length + replacement.length;
            onChange({ target: { value: newContent } });
        }
        
        // Set the cursor position after the replacement
        setTimeout(() => {
            if (ref?.current) {
                ref.current.selectionStart = newCursorPosition;
                ref.current.selectionEnd = newCursorPosition;
            }
        }, 0);
        
        setIsVisible(false);
    }, [value, cursorPosition, onChange, ref]);

    useEffect(() => {
        updateMatchingPhrases();
    }, [updateMatchingPhrases]);

    const calculateDropdownPosition = useCallback(() => {
        if (!ref?.current) {
            return {};
        }

        const textareaRect = ref.current.getBoundingClientRect();
        
        // Calculate viewport-relative position
        const dropdownWidth = Math.max(200, textareaRect.width * 0.5);
        
        // Ensure the dropdown doesn't go off-screen
        const leftPosition = Math.min(
            textareaRect.left,
            window.innerWidth - dropdownWidth
        );

        return {
            top: textareaRect.bottom,
            left: leftPosition,
            width: dropdownWidth,
        };
    }, [ref]);

    return {
        isVisible,
        setIsVisible,
        matchingPhrases,
        selectedIndex,
        setSelectedIndex,
        handleSelect,
        dropdownPosition,
        setDropdownPosition,
        calculateDropdownPosition
    };
}; 