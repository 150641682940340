import React, { useState, useRef } from 'react';
import apiService from '../../services/api';
import { useSettings } from '../../context/SettingsContext';
import { useVisualViewport } from '../../hooks/useVisualViewport';
import { useNotes } from '../../hooks/useNotes';
import { createDocumentSignature } from '../utils/signatureUtils';
import NoteContextButton from './buttons/NoteContextButton';
import CommonDocumentsSelector from './buttons/CommonDocumentsSelector';
import BaseModal from '../common/BaseModal';
import DotPhraseTextArea from '../NoteEditor/DotPhraseTextArea';

function ChatBox({ document, onUpdateDocument }) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const chatContainerRef = useRef(null);
    const textareaRef = useRef(null);
    const { settings } = useSettings();
    const bottomOffset = useVisualViewport();
    const { notes } = useNotes();

    const linkedNote = notes?.find(note => note.id === document.noteId);
    
    const getPresetDisplayName = (preset) => {
        switch (preset.toLowerCase()) {
            case 'letter':
                return 'Letter';
            case 'education':
                return 'Educational Document';
            case 'communications':
                return 'Communication';
            default:
                return preset;
        }
    };

    const handleSendMessage = async () => {
        if (!message.trim()) return;

        setIsLoading(true);
        const userMessage = message.trim();
        setMessage('');

        // Add user message to chat history
        setChatHistory(prev => [...prev, { role: 'user', content: userMessage }]);

        try {
            const response = await apiService.chatWithAI({
                message: userMessage,
                documentContent: document.content,
                documentTitle: document.title,
                documentPreset: document.preset,
                aiProvider: settings.aiProvider,
                noteContext: linkedNote || null
            });

            // Add AI response to chat history
            setChatHistory(prev => [...prev, { role: 'assistant', content: response.message }]);

            // If the AI suggests content or title changes, update the document
            if (response.suggestedContent || response.suggestedTitle) {
                const updates = {
                    ...document,
                    ...(response.suggestedContent && { content: response.suggestedContent.replace('<Signature>', createDocumentSignature()) }),
                    ...(response.suggestedTitle && { title: response.suggestedTitle })
                };
                onUpdateDocument(updates);
            }
        } catch (error) {
            console.error('Error in chat:', error);
            setChatHistory(prev => [...prev, {
                role: 'error',
                content: 'Sorry, there was an error processing your request.'
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleQuickPromptSelect = (promptText) => {
        setMessage(promptText);
        // Focus the textarea after selecting a prompt
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    return (
        <>
            {/* Chat Toggle Button */}
            <button
                onClick={() => setIsOpen(true)}
                className="fixed right-4 z-10 flex items-center justify-center p-2 sm:p-3 rounded-lg transition-all duration-300 ease-in-out bg-[#242424] hover:base-surface border-2 surface-border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] shadow-lg"
                style={{ 
                    bottom: `max(80px, calc(10vh + ${bottomOffset}px))`,
                }}
                title="Open Chat"
            >
                <span className="material-symbols-outlined text-white text-2xl">tooltip_2</span>
            </button>

            <BaseModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="4xl"
                className="!p-0 h-[90vh] flex flex-col"
                showCloseButton={false}
            >
                {/* Chat Header */}
                <div className="flex flex-col p-3 sm:p-4 border-b border-[#323232]">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <span className="material-symbols-outlined text-white text-2xl">tooltip_2</span>
                            <h3 className="text-lg font-semibold text-white justify-center">
                                Edit {getPresetDisplayName(document.preset)}
                            </h3>
                        </div>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="p-1 text-[#ababab] hover:text-white hover:bg-[#323232] rounded-md transition-colors duration-200"
                            title="Close Chat"
                        >
                            <span className="material-symbols-outlined">close</span>
                        </button>
                    </div>
                </div>

                {/* Chat Messages */}
                <div 
                    ref={chatContainerRef}
                    className="flex-1 overflow-y-auto p-4 space-y-4 app-bg"
                >
                    {chatHistory.map((msg, index) => (
                        <div
                            key={index}
                            className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                        >
                            <div
                                className={`max-w-[80%] rounded-lg p-3 ${
                                    msg.role === 'user'
                                        ? 'bg-[#1976d2] text-white'
                                        : msg.role === 'error'
                                        ? 'bg-red-500 text-white'
                                        : 'base-surface text-white'
                                }`}
                            >
                                <p className="whitespace-pre-wrap">{msg.content}</p>
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <div className="flex justify-start">
                            <div className="base-surface text-white rounded-lg p-3">
                                <div className="flex items-center">
                                    Processing
                                    <span className="animate-ellipsis">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Chat Input */}
                <div className="p-4 border-t border-[#323232]">
                    {/* Context Tools Row - Note Context and Quick Prompts inline */}
                    <div className="flex justify-between items-center mb-3">
                        <NoteContextButton 
                            document={document} 
                            onUpdateDocument={onUpdateDocument}
                        />
                        
                        <CommonDocumentsSelector 
                            onSelect={handleQuickPromptSelect}
                            documentPreset={document.preset}
                        />
                    </div>
                    
                    <div className="flex items-center space-x-2">
                        <DotPhraseTextArea
                            ref={textareaRef}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            fixedHeight={100}
                            placeholder="Type your message..."
                            disabled={isLoading}
                        />
                        <button
                            onClick={handleSendMessage}
                            disabled={isLoading || !message.trim()}
                            className="p-2 text-white rounded-md primary-button disabled:opacity-50"
                            title="Send message"
                        >
                            <span className="material-symbols-outlined">send</span>
                        </button>
                    </div>
                </div>
            </BaseModal>
        </>
    );
}

export default ChatBox; 