import { useState, useCallback, useEffect, useRef } from 'react';
import { snackbarError } from '../components/common/Snackbar';
import { useSettings } from '../context/SettingsContext';
import apiService from '../services/api';
import { useTextPostprocessing } from './useTextPostprocessing';

// Cache for notes data
let notesCache = null;
let lastFetchTimestamp = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const useNotes = () => {
    const { settings } = useSettings();
    const { postProcessText } = useTextPostprocessing();
    const [notes, setNotes] = useState(notesCache || []);
    const [currentNote, setCurrentNote] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(!notesCache);
    const initialFetchDone = useRef(false);
    const [changedFields, setChangedFields] = useState({});
    const [newNoteCreated, setNewNoteCreated] = useState(false);
    const [lastViewedNoteId, setLastViewedNoteId] = useState(null);

    const invalidateCache = useCallback(() => {
        notesCache = null;
        lastFetchTimestamp = null;
        initialFetchDone.current = false;
    }, []);

    const shouldRefetch = useCallback(() => {
        return !notesCache || !lastFetchTimestamp || (Date.now() - lastFetchTimestamp > CACHE_DURATION);
    }, []);

    const initializeNote = useCallback((note) => ({
        id: note.id || null,
        title: note.title || '',
        consultInfo: note.consultInfo || '',
        background: note.background || '',
        hpi: note.hpi || '',
        medicalHistory: note.medicalHistory || '',
        socialHistory: note.socialHistory || '',
        antimicrobials: note.antimicrobials || '',
        physicalExam: note.physicalExam || '',
        labs: note.labs || '',
        imaging: note.imaging || '',
        recommendations: note.recommendations || '',
        preset: note.preset || settings.defaultPreset || 'ID Consult',
        todos: note.todos || [],
        conversation: note.conversation || '',
        collapsedSections: note.collapsedSections || { background: true},
        preFormatTexts: note.preFormatTexts || {},
    }), [settings.defaultPreset]);

    // Helper function to select the appropriate note
    const autoSelectNote = useCallback((notes, lastViewedId) => {
        if (!notes || notes.length === 0) {
            setCurrentNote(null);
            setIsEditing(false);
            return;
        }

        // Convert IDs to strings for consistent comparison
        const targetId = lastViewedId?.toString();
        const noteToSelect = targetId 
            ? notes.find(note => note.id?.toString() === targetId)
            : null;

        // If we found the last viewed note, use it; otherwise use the first note
        const selectedNote = noteToSelect || notes[0];
        setCurrentNote(initializeNote(selectedNote));
        setIsEditing(true);
    }, [initializeNote]);

    const fetchNotes = useCallback(async (force = false) => {
        if (!force && (initialFetchDone.current || !apiService.authToken || !shouldRefetch())) {
            // Even when using cached data, fetch the last viewed note ID
            try {
                const { lastViewedNoteId: fetchedLastViewedId } = await apiService.fetchLastViewedNote();
                setLastViewedNoteId(fetchedLastViewedId);
                // If using cached data, ensure we have the correct note selected
                if (notesCache) {
                    autoSelectNote(notesCache, fetchedLastViewedId);
                }
            } catch (error) {
                console.error('Error fetching last viewed note ID:', error);
            }
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const fetchedNotes = await apiService.fetchNotes();
            const sortedNotes = fetchedNotes.map(initializeNote).sort((a, b) => a.order - b.order);
            notesCache = sortedNotes;
            lastFetchTimestamp = Date.now();
            setNotes(sortedNotes);

            // Fetch last viewed note ID
            const { lastViewedNoteId: fetchedLastViewedId } = await apiService.fetchLastViewedNote();
            setLastViewedNoteId(fetchedLastViewedId);

            // Select appropriate note
            autoSelectNote(sortedNotes, fetchedLastViewedId);

            initialFetchDone.current = true;
        } catch (error) {
            console.error('Error fetching notes:', error);
            invalidateCache();
            if (error.response?.status !== 401) { // Don't show error for auth failures
                setError(`Error fetching notes: ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    }, [initializeNote, autoSelectNote, shouldRefetch, invalidateCache]);

    useEffect(() => {
        fetchNotes();
    }, [fetchNotes]);

    const selectNote = useCallback(async (note) => {
        if (!note) return;
        
        try {
            const initializedNote = initializeNote(note);
            setCurrentNote(initializedNote);
            setIsEditing(true);
            const noteId = note.id?.toString();
            await apiService.updateLastViewedNote(note.id);
            setLastViewedNoteId(noteId);
        } catch (error) {
            console.error('Error updating last viewed note:', error);
        }
    }, [initializeNote]);

    const reorderNotes = useCallback(async (newOrderedNotes) => {
        if (!newOrderedNotes || newOrderedNotes.length === 0) return;
        
        // Store previous state for rollback
        const previousNotes = [...notes];
        const previousCache = notesCache ? [...notesCache] : null;
        
        try {
            // Update local state optimistically
            setNotes(newOrderedNotes);
            
            // Update cache
            notesCache = newOrderedNotes;
            lastFetchTimestamp = Date.now();
            
            // Prepare data for API call - we need to send the id and new order for each note
            const noteOrders = newOrderedNotes.map((note, index) => ({
                id: note.id,
                order: index
            }));
            
            // Call API to update order in database
            await apiService.reorderNotes(noteOrders);
        } catch (error) {
            console.error('Error reordering notes:', error);
            
            // Restore previous state on error
            setNotes(previousNotes);
            notesCache = previousCache;
            
            // Show error to user
            setError(`Error reordering notes: ${error.message}`);
        }
    }, [notes]);

    // Effect to update the last viewed note ID with debounce
    useEffect(() => {
        let timeoutId;
        if (lastViewedNoteId) {
            timeoutId = setTimeout(() => {
                apiService.updateLastViewedNote(lastViewedNoteId).catch(error => {
                    console.error('Error updating last viewed note:', error);
                });
            }, 1000); // Debounce for 1 second
        }
        return () => clearTimeout(timeoutId);
    }, [lastViewedNoteId]);

    const resetInitialFetchDone = useCallback(() => {
        initialFetchDone.current = false;
    }, []);

    const createNewNote = useCallback(async (title = 'Untitled Note', additionalData = {}) => {
        // Create a new note with the title and any additional data provided
        const newNote = initializeNote({
            title,
            preset: settings.defaultPreset || 'ID Consult',
            id: Date.now(), // Temporary ID
            order: notes.length, // Place the new note at the end
            ...additionalData // Include any additional data (like card data)
        });

        // For the first note, we want to ensure the cache is initialized
        if (!notesCache) {
            notesCache = [];
            lastFetchTimestamp = Date.now();
            initialFetchDone.current = true;
        }

        // Optimistically update local state
        setNotes(prevNotes => [...prevNotes, newNote].sort((a, b) => a.order - b.order));
        setCurrentNote(newNote);
        setIsEditing(true);
        setNewNoteCreated(true);
        setTimeout(() => setNewNoteCreated(false), 100);

        try {
            const savedNote = await apiService.createNote(newNote);
            const initializedNote = initializeNote(savedNote);

            // Update both the state and cache with the server-generated ID
            setNotes(prevNotes => {
                const updatedNotes = prevNotes.map(note =>
                    note.id === newNote.id ? initializedNote : note
                );
                notesCache = updatedNotes; // Update cache with the new state
                return updatedNotes;
            });
            setCurrentNote(initializedNote);

            return initializedNote;
        } catch (error) {
            console.error('Error creating new note:', error);
            // Revert the optimistic update
            setNotes(prevNotes => prevNotes.filter(note => note.id !== newNote.id));
            setCurrentNote(null);
            setIsEditing(false);
            setError(`Error creating new note: ${error.message}`);
            return null;
        }
    }, [initializeNote, settings.defaultPreset, notes.length]);

    const updateCurrentNoteLocally = useCallback((fieldOrUpdater, value) => {
        setCurrentNote(prevNote => {
            if (!prevNote) return null;
            if (typeof fieldOrUpdater === 'function') {
                return fieldOrUpdater(prevNote);
            }
            if (typeof fieldOrUpdater === 'object') {
                return { ...prevNote, ...fieldOrUpdater };
            }
            return { ...prevNote, [fieldOrUpdater]: value };
        });
        setChangedFields(prev => {
            if (typeof fieldOrUpdater === 'object') {
                return { ...prev, ...Object.fromEntries(Object.keys(fieldOrUpdater).map(key => [key, true])) };
            }
            if (typeof fieldOrUpdater === 'string') {
                return { ...prev, [fieldOrUpdater]: true };
            }
            return prev;
        });
    }, []);

    const saveCurrentNote = useCallback(async () => {
        if (!currentNote) return false;

        try {
            console.log("Attempting to save note...");
            const changedData = Object.keys(changedFields).reduce((acc, field) => {
                acc[field] = currentNote[field];
                return acc;
            }, {});

            // Only make API call if there are changes
            if (Object.keys(changedData).length > 0) {
                const savedNote = await apiService.updateNote(currentNote.id, changedData);
                console.log("Note saved successfully:", savedNote);
                const updatedNotes = notes.map(note => 
                    note.id === savedNote.id ? { ...note, ...savedNote } : note
                );
                setNotes(updatedNotes);
                notesCache = updatedNotes;
            }
            
            setChangedFields({});
            return true;
        } catch (error) {
            console.error('Error saving note:', error);
            setError(`Error saving note: ${error.message}`);
            return false;
        }
    }, [currentNote, changedFields, notes]);

    const deleteNote = useCallback(async (noteId) => {
        // Ensure consistent ID format for comparison
        const currentId = currentNote?.id?.toString();
        const targetId = noteId?.toString();
        const wasCurrentNote = currentId === targetId;

        // Store previous state for rollback
        const previousNotes = [...notes];
        const previousCache = notesCache ? [...notesCache] : null;
        const previousLastViewed = lastViewedNoteId;

        // Optimistically update state and cache
        const updatedNotes = notes.filter(note => note.id?.toString() !== targetId);
        setNotes(updatedNotes);
        notesCache = updatedNotes;
        lastFetchTimestamp = Date.now();

        // Handle current note selection optimistically
        if (wasCurrentNote && updatedNotes.length > 0) {
            const deletedIndex = previousNotes.findIndex(note => note.id?.toString() === targetId);
            const nextNote = updatedNotes[deletedIndex] || // Same position
                           updatedNotes[deletedIndex - 1] || // Previous
                           updatedNotes[0]; // First as fallback
            
            setCurrentNote(initializeNote(nextNote));
            setIsEditing(true);
            setLastViewedNoteId(nextNote.id.toString());
        } else if (updatedNotes.length === 0) {
            setCurrentNote(null);
            setIsEditing(false);
            setLastViewedNoteId(null);
        }

        try {
            await apiService.deleteNote(noteId);
        } catch (error) {
            // Restore previous state on error
            console.error('Error deleting note:', error);
            setNotes(previousNotes);
            notesCache = previousCache;
            if (wasCurrentNote) {
                const originalNote = previousNotes.find(note => note.id?.toString() === targetId);
                if (originalNote) {
                    setCurrentNote(initializeNote(originalNote));
                    setIsEditing(true);
                }
            }
            setLastViewedNoteId(previousLastViewed);
            throw error;
        }
    }, [currentNote?.id, initializeNote, lastViewedNoteId, notes]);

    const deleteAllNotes = useCallback(async () => {
        // Store previous state for rollback
        const previousNotes = [...notes];
        const previousCache = notesCache ? [...notesCache] : null;
        const previousCurrentNote = currentNote;
        const previousLastViewed = lastViewedNoteId;

        // Optimistically update state and cache
        setNotes([]);
        setCurrentNote(null);
        setIsEditing(false);
        setLastViewedNoteId(null);
        notesCache = [];
        lastFetchTimestamp = Date.now();

        try {
            await apiService.deleteAllNotes();
        } catch (error) {
            console.error('Error deleting all notes:', error);
            // Restore previous state on error
            setNotes(previousNotes);
            notesCache = previousCache;
            setCurrentNote(previousCurrentNote);
            setIsEditing(!!previousCurrentNote);
            setLastViewedNoteId(previousLastViewed);
            
            let errorMessage = 'Error deleting all notes';
            try {
                const parsedError = JSON.parse(error.message);
                errorMessage = `${parsedError.message}\n${parsedError.error}\n${parsedError.stack}`;
            } catch {
                errorMessage = error.toString();
            }
            setError(errorMessage);
        }
    }, [notes, currentNote, lastViewedNoteId]);

    const summarizeNote = useCallback(async (section, preset) => {
        if (!currentNote) return null;

        try {
            const data = await apiService.summarizeNote({
                note: currentNote[section],
                title: currentNote.title,
                section,
                preset,
                aiProvider: settings.aiProvider
            });
            const summarizedContent = data.summary;

            if (summarizedContent !== currentNote[section]) {
                // Post-process the summary before updating the note
                const processedContent = postProcessText(summarizedContent);
                updateCurrentNoteLocally(section, processedContent);
                return processedContent;
            }
            return null; // Return null if no change
        } catch (error) {
            console.error(`Error in summarizeNote for ${section}:`, error);
            snackbarError(`Error summarizing note: ${error.message}`);
            return null;
        }
    }, [currentNote, settings.aiProvider, updateCurrentNoteLocally, postProcessText]);

    const resetLastViewedNoteId = useCallback(() => {
        setLastViewedNoteId(null);
    }, []);

    const createBulkNotes = useCallback(async (noteNames) => {
        try {
            // Create optimistic notes
            const optimisticNotes = noteNames.map(title => ({
                id: `temp-${Date.now()}-${Math.random()}`,
                title,
                preset: settings.defaultPreset || 'ID Consult',
                isOptimistic: true,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            }));

            // Add optimistic notes to the state
            setNotes(prevNotes => [...optimisticNotes, ...prevNotes]);

            const createdNotes = await apiService.createBulkNotes(noteNames);

            // Replace optimistic notes with real ones
            setNotes(prevNotes => {
                const nonOptimisticNotes = prevNotes.filter(note => !note.isOptimistic);
                return [...createdNotes, ...nonOptimisticNotes];
            });

            return createdNotes;
        } catch (error) {
            // Remove optimistic notes on error
            setNotes(prevNotes => prevNotes.filter(note => !note.isOptimistic));
            console.error('Error creating bulk notes:', error);
            snackbarError('Failed to create notes');
            throw error;
        }
    }, [settings.defaultPreset]);

    return {
        notes,
        setNotes,
        currentNote,
        setCurrentNote,
        isEditing,
        error,
        isLoading,
        selectNote,
        createNewNote,
        newNoteCreated,
        updateCurrentNoteLocally,
        saveCurrentNote,
        changedFields,
        setChangedFields,
        deleteNote,
        deleteAllNotes,
        summarizeNote,
        reorderNotes,
        resetInitialFetchDone,
        fetchNotes,
        lastViewedNoteId,
        resetLastViewedNoteId,
        createBulkNotes,
        invalidateCache,
    };
};