import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

function BaseModal({ 
    isOpen, 
    onClose, 
    children, 
    title,
    maxWidth = '2xl', // Options: sm, md, lg, xl, 2xl, 3xl, 4xl, 5xl, 6xl, 7xl
    showCloseButton = true,
    preventScroll = true,
    className = ''
}) {
    useEffect(() => {
        if (isOpen && preventScroll) {
            // Calculate scrollbar width
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            // Prevent body scrolling when modal is open and add padding to compensate for scrollbar
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${scrollbarWidth}px`;

            return () => {
                // Restore body scrolling and remove padding when modal is closed
                document.body.style.overflow = 'unset';
                document.body.style.paddingRight = '0px';
            };
        }
    }, [isOpen, preventScroll]);

    if (!isOpen) return null;

    const maxWidthClasses = {
        'sm': 'max-w-sm',
        'md': 'max-w-md',
        'lg': 'max-w-lg',
        'xl': 'max-w-xl',
        '2xl': 'max-w-2xl',
        '3xl': 'max-w-3xl',
        '4xl': 'max-w-4xl',
        '5xl': 'max-w-5xl',
        '6xl': 'max-w-6xl',
        '7xl': 'max-w-7xl',
    };

    return createPortal(
        <div className="fixed inset-0 app-bg bg-opacity-50 flex items-center justify-center z-[9999] p-4 animate-fadeIn">
            <div className={`surface-bg p-4 sm:p-6 rounded-lg shadow-xl border surface-border w-full ${maxWidthClasses[maxWidth]} relative ${className} animate-slideUp`}>
                {showCloseButton && (
                    <button
                        onClick={onClose}
                        className="absolute top-2 right-2 text-[#ababab] hover:text-white hover:bg-[#323232] rounded-md p-1.5 focus:outline-none"
                        aria-label="Close"
                    >
                        <span className="material-icons">close</span>
                    </button>
                )}
                
                {title && (
                    <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-100 text-center">
                        {title}
                    </h2>
                )}
                
                {children}
            </div>
        </div>,
        document.body
    );
}

export default BaseModal; 