import React, { useState, useEffect } from 'react';
import NoteEditorSections from './NoteEditorSections';
import Insights from './Insights';
import Todo from './Todo';
import NoteEditorHeader from './NoteEditorHeader';
import SummarizeAllButton from './buttons/SummarizeAllButton';

function NoteEditorContent({
    activeTab,
    note,
    currentPreset,
    updateNote,
    summarizeNote,
    isLoading,
    handleCopySection,
    handleTranscriptionUpdate,
    startRecordingSession,
    endRecordingSession,
    currentSessionTranscripts,
    currentTranscript,
    activeRecordingSection,
    presetSections,
    actionSuccess,
    refs,
    handlePresetChange,
    handleSummarizeAll,
    isSummarizing,
    pinnedSection,
    setPinnedSection,
    collapseHeader,
}) {
    const [preFormatTexts, setPreFormatTexts] = useState(note.preFormatTexts || {});
    const [showComparisons, setShowComparisons] = useState({});

    // Reset preFormatTexts when note changes
    useEffect(() => {
        setPreFormatTexts(note.preFormatTexts || {});
        setShowComparisons({});
    }, [note.id, note.preFormatTexts]);

    // Wrap handleSummarizeAll to store pre-format texts
    const handleSummarizeAllWithPreFormat = async () => {
        // Store pre-format texts for all non-empty sections
        const sections = presetSections[currentPreset];
        const newPreFormatTexts = {};
        sections.forEach(section => {
            if (note[section] && note[section].trim()) {
                newPreFormatTexts[section] = note[section];
            }
        });
        
        // Update both local state and persist to backend
        setPreFormatTexts(prev => {
            const updatedTexts = { ...prev, ...newPreFormatTexts };
            updateNote('preFormatTexts', updatedTexts);
            return updatedTexts;
        });

        // Call the original handler
        await handleSummarizeAll();
    };

    return (
        <div className="surface-bg shadow-lg rounded-lg border border-[#2a2a2a]">
            <div className="p-4 sm:p-6">
                {activeTab === 'editor' && (
                    <>
                        <NoteEditorHeader
                            note={note}
                            updateNote={updateNote}
                            currentPreset={currentPreset}
                            handlePresetChange={handlePresetChange}
                            presetSections={presetSections}
                        />
                        <NoteEditorSections
                            note={note}
                            currentPreset={currentPreset}
                            updateNote={updateNote}
                            summarizeNote={summarizeNote}
                            isLoading={isLoading}
                            isSummarizing={isSummarizing}
                            handleCopySection={handleCopySection}
                            handleTranscriptionUpdate={handleTranscriptionUpdate}
                            startRecordingSession={startRecordingSession}
                            endRecordingSession={endRecordingSession}
                            currentSessionTranscripts={currentSessionTranscripts}
                            currentTranscript={currentTranscript}
                            activeRecordingSection={activeRecordingSection}
                            presetSections={presetSections}
                            actionSuccess={actionSuccess}
                            refs={refs}
                            pinnedSection={pinnedSection}
                            setPinnedSection={setPinnedSection}
                            collapseHeader={collapseHeader}
                            showComparisons={showComparisons}
                            setShowComparisons={setShowComparisons}
                            preFormatTexts={preFormatTexts}
                            setPreFormatTexts={setPreFormatTexts}
                        />
                        <div className="flex justify-center gap-4 mt-6">
                            <SummarizeAllButton
                                handleSummarizeAll={handleSummarizeAllWithPreFormat}
                                isSummarizing={isSummarizing}
                            />
                        </div>
                    </>
                )}
                {activeTab === 'todo' && (
                    <Todo
                        note={note}
                        updateNote={(field, value) => updateNote(field, value)}
                    />
                )}
                {activeTab === 'insights' && 
                    <Insights note={note} 
                        updateNote={updateNote}
                    />}
            </div>
        </div>
    );
}

export default NoteEditorContent;