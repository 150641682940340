import React, { useState } from 'react';

const FIB4Calculator = () => {
    const [age, setAge] = useState('');
    const [ast, setAst] = useState('');
    const [alt, setAlt] = useState('');
    const [platelets, setPlatelets] = useState('');
    const [fib4Score, setFib4Score] = useState(null);
    const [interpretation, setInterpretation] = useState('');

    const calculateFIB4 = () => {
        if (!age || !ast || !alt || !platelets) return;

        const ageNum = parseFloat(age);
        const astNum = parseFloat(ast);
        const altNum = parseFloat(alt);
        const plateletsNum = parseFloat(platelets);

        if (altNum <= 0 || plateletsNum <= 0) {
            alert("ALT and Platelets must be greater than zero");
            return;
        }

        // FIB-4 = (Age × AST) / (Platelets × √ALT)
        const score = (ageNum * astNum) / (plateletsNum * Math.sqrt(altNum));
        const roundedScore = Math.round(score * 100) / 100;
        setFib4Score(roundedScore);

        // Interpretation based on common thresholds
        if (score < 1.45) {
            setInterpretation("Low risk for advanced fibrosis (F0-F2)");
        } else if (score > 3.25) {
            setInterpretation("High risk for advanced fibrosis (F3-F4)");
        } else {
            setInterpretation("Indeterminate risk, further evaluation may be needed");
        }
    };

    const copyToClipboard = () => {
        if (fib4Score === null) return;
        navigator.clipboard.writeText(`FIB-4 Score: ${fib4Score} - ${interpretation}`);
    };

    return (
        <div className="p-4">
            <div className="space-y-3">
                <div className="flex flex-col md:flex-row md:items-end md:space-x-2 space-y-3 md:space-y-0">
                    <div className="w-24">
                        <label className="block text-gray-300 text-sm mb-1">Age (years)</label>
                        <input
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>
                    <div className="w-24">
                        <label className="block text-gray-300 text-sm mb-1">AST (U/L)</label>
                        <input
                            value={ast}
                            onChange={(e) => setAst(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>
                    <div className="w-24">
                        <label className="block text-gray-300 text-sm mb-1">ALT (U/L)</label>
                        <input
                            value={alt}
                            onChange={(e) => setAlt(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Platelets (10⁹/L)</label>
                        <input
                            value={platelets}
                            onChange={(e) => setPlatelets(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>

                    <button
                        onClick={calculateFIB4}
                        className="px-6 py-2 primary-button text-white rounded-md flash-button w-full md:w-auto"
                    >
                        Calculate
                    </button>
                </div>

                {fib4Score !== null && (
                    <div className="mt-8 p-3 base-surface rounded-md">
                        <div className="flex items-center justify-between">
                            <div>
                                <div>
                                    <span className="text-gray-400 text-sm">FIB-4 Score:</span>
                                    <span className="text-gray-100 ml-2">{fib4Score}</span>
                                </div>
                                <div>
                                    <span className="text-gray-400 text-sm">Interpretation:</span>
                                    <span className="text-gray-100 ml-2">{interpretation}</span>
                                </div>
                            </div>
                            <button
                                onClick={copyToClipboard}
                                className="px-3 py-1 base-button flash-button rounded-md text-sm"
                            >
                                <span className="material-symbols-outlined text-white text-sm mr-1">
                                    file_copy
                                </span>
                                Copy
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FIB4Calculator; 