import { BaseApiService } from './base';

export class CardsService extends BaseApiService {
    async fetchCards() {
        const response = await this.axiosInstance.get('/api/cards');
        return response.data;
    }

    async createCard(card) {
        const response = await this.axiosInstance.post('/api/cards', card);
        return response.data;
    }

    async updateCard(cardId, data) {
        const response = await this.axiosInstance.put(`/api/cards/${cardId}`, data);
        return response.data;
    }

    async deleteCard(cardId) {
        const response = await this.axiosInstance.delete(`/api/cards/${cardId}`);
        return response.data;
    }

    async deleteAllCards() {
        const response = await this.axiosInstance.delete('/api/cards');
        return response.data;
    }

    async reorderCard(cardId, order) {
        const response = await this.axiosInstance.put(`/api/cards/reorder/${cardId}`, { order });
        return response.data;
    }
} 