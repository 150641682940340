import React, { useMemo } from 'react';

/**
 * PatientNameFormatting - A read-only component that displays text with highlighted numbers and date patterns
 * 
 * @param {Object} props - Component props
 * @param {string} props.text - The text to display
 * @param {string} props.className - Additional CSS classes for the container
 * @param {object} props.style - Additional inline styles for the container
 */
const PatientNameFormatting = ({ 
    text = '',
    className = '',
    style = {}
}) => {
    // Parse the text to find patterns to highlight
    const textParts = useMemo(() => {
        const parts = [];
        
        // Match both plain numbers and date-like patterns
        const numberPattern = /\b\d+(?:\/\d+)*\b/g;
        let match;
        let lastIndex = 0;

        while ((match = numberPattern.exec(text)) !== null) {
            // Add text before the number
            if (match.index > lastIndex) {
                parts.push({
                    type: 'text',
                    content: text.slice(lastIndex, match.index)
                });
            }
            // Add the number
            parts.push({
                type: 'number',
                content: match[0]
            });
            lastIndex = match.index + match[0].length;
        }
        
        // Add remaining text
        if (lastIndex < text.length) {
            parts.push({
                type: 'text',
                content: text.slice(lastIndex)
            });
        }

        return parts;
    }, [text]);

    return (
        <div className={className} style={style}>
            {textParts.map((part, index) => (
                part.type === 'text' ? (
                    <span key={`text-${index}`} className="whitespace-pre-wrap break-words">
                        {part.content}
                    </span>
                ) : (
                    <span
                        key={`number-${index}-${part.content}`}
                        className="inline-flex items-center px-1.5 py-0.5 rounded-md text-xs bg-blue-500 bg-opacity-20 text-blue-300"
                        style={{ 
                            wordBreak: 'break-all',
                            maxWidth: '200px',
                            overflow: 'hidden'
                        }}
                    >
                        {part.content}
                    </span>
                )
            ))}
        </div>
    );
};

export default PatientNameFormatting; 