import React, { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { renderHighlightedLine } from '../utils/noteHighlightUtils';

const BUTTON_SPACE = 'calc(16px + 0.25rem + 0.75rem)';

const sharedStyles = {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    tabSize: 4,
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    padding: '0.5rem 0.75rem',
    width: '100%',
    boxSizing: 'border-box',
};

const HighlightedTextArea = React.forwardRef(({
    value,
    onChange,
    onKeyDown,
    onSelect,
    onFocus,
    onBlur,
    id,
    isRecording,
    section,
    fixedHeight,
    borderRounding = 'rounded-md'
}, ref) => {
    const textareaRef = useRef(null);
    const overlayRef = useRef(null);

    // Synchronize scroll positions between textarea and overlay
    const syncScrollPosition = useCallback(() => {
        if (textareaRef.current && overlayRef.current) {
            overlayRef.current.scrollTop = textareaRef.current.scrollTop;
            overlayRef.current.scrollLeft = textareaRef.current.scrollLeft;
        }
    }, []);

    const autoResize = useCallback(() => {
        if (textareaRef.current) {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            
            // Don't auto-resize if we have a fixed height
            if (fixedHeight) {
                // Set to exactly the fixed height
                textareaRef.current.style.height = `${fixedHeight}px`;
            } else {
                // Apply normal auto-resize logic
                textareaRef.current.style.height = 'auto';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
            
            window.scrollTo(0, scrollTop);
            syncScrollPosition();
        }
    }, [fixedHeight, syncScrollPosition]);

    const debouncedAutoResize = useCallback(() => {
        const debouncedFn = debounce(() => autoResize(), 16);
        debouncedFn();
        return debouncedFn.cancel;
    }, [autoResize]);

    const handleChange = useCallback((e) => {
        onChange(e);
        // Only auto-resize if not using a fixed height
        if (!fixedHeight) {
            debouncedAutoResize();
        } else {
            // If using fixed height, just sync scrolling
            syncScrollPosition();
        }
    }, [onChange, debouncedAutoResize, fixedHeight, syncScrollPosition]);

    // Listen for scroll events on the textarea
    useEffect(() => {
        if (!fixedHeight) return; // No need for scroll syncing when not scrollable
        
        const handleScroll = () => {
            requestAnimationFrame(syncScrollPosition);
        };
        
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.addEventListener('scroll', handleScroll);
            return () => textarea.removeEventListener('scroll', handleScroll);
        }
    }, [fixedHeight, syncScrollPosition]);

    useEffect(() => {
        // Don't auto-resize on value change if we have a fixed height
        if (fixedHeight) {
            // Just sync scroll position without resizing
            syncScrollPosition();
            return;
        }
        
        autoResize();
        const handleResize = debounce(() => autoResize(), 100);
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel();
        };
    }, [value, autoResize, fixedHeight, syncScrollPosition]);

    // Set fixed height on initial mount if specified
    useEffect(() => {
        if (textareaRef.current && fixedHeight) {
            textareaRef.current.style.height = `${fixedHeight}px`;
            syncScrollPosition();
        }
    }, [fixedHeight, syncScrollPosition]);

    return (
        <div className="relative w-full group">
            <div 
                className="relative"
                style={{
                    height: fixedHeight ? `${fixedHeight}px` : 'auto'
                }}
            >
                <textarea
                    ref={(element) => {
                        textareaRef.current = element;
                        if (typeof ref === 'function') {
                            ref(element);
                        } else if (ref) {
                            ref.current = element;
                        }
                    }}
                    id={id}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    onSelect={onSelect}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={`input-base block w-full ${
                        borderRounding
                    } transition duration-150 ease-in-out px-3 py-2 whitespace-pre-wrap ${
                        isRecording ? 'shadow-[0_0_15px_rgba(239,68,68,0.7)]' : 'shadow-none'
                    } ${
                        fixedHeight ? 'overflow-y-auto' : ''
                    }`}
                    rows="3"
                    style={{ 
                        ...sharedStyles,
                        resize: 'none', 
                        overflow: fixedHeight ? 'auto' : 'hidden',
                        caretColor: 'white',
                        color: 'transparent', // Always transparent to show overlay
                        background: '#242424',
                        paddingRight: BUTTON_SPACE,
                        height: fixedHeight ? `${fixedHeight}px` : undefined,
                        maxHeight: fixedHeight ? `${fixedHeight}px` : undefined,
                    }}
                    onInput={fixedHeight ? undefined : autoResize}
                />
                <div 
                    ref={overlayRef}
                    className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none"
                    style={{
                        ...sharedStyles,
                        color: 'white',
                        overflow: fixedHeight ? 'auto' : 'hidden',
                        border: '1px solid transparent',
                        paddingRight: BUTTON_SPACE,
                        height: fixedHeight ? `${fixedHeight}px` : undefined,
                        maxHeight: fixedHeight ? `${fixedHeight}px` : undefined,
                    }}
                >
                    {value.split('\n').map((line, index) => (
                        renderHighlightedLine(line, index, value, section)
                    ))}
                </div>
            </div>
        </div>
    );
});

HighlightedTextArea.displayName = 'HighlightedTextArea';

export default HighlightedTextArea; 