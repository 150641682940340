export const POST_PROCESS_SUBSTITUTIONS = {
    //Literal substitutions

      //Reformatting common terms
      'DM2': 'DM II',
      'E coli': 'E. coli',
      'gram stain': 'Gram stain',
      'gram positive': 'Gram-positive',
      'gram-positive': 'Gram-positive',
      'gram negative': 'Gram-negative',
      'gram-negative': 'Gram-negative',
      'No Growth To Date': 'NGTD',
      'Nonreactive': 'Non-reactive',
      'status post': 's/p',
      'S/P': 's/p',
      'S/p': 's/p',

      'infectious disease clinic': 'Infectious Disease clinic',
      'infectious disease consultation': 'Infectious Disease consultation',

      //Ignore (must come before gender substitutions)
      'REGEX:(?<![\\w-])(fe)?male partner(s)?(?![\\w-])': '$1male partner$2',
      
      //Gender (using regex to ensure proper word boundaries and precedence)
      'REGEX:(?<![\\w-])male(?![\\w-]|\\spartner)': 'man',
      'REGEX:(?<![\\w-])female(?![\\w-]|\\spartner)': 'woman',
      'REGEX:(?<![\\w-])males(?![\\w-]|\\spartner)': 'men',
      'REGEX:(?<![\\w-])females(?![\\w-]|\\spartner)': 'women',

      //Titles with excess line breaks
      'History of Present Illness:\n\n': 'History of Present Illness:\n',
      'Background:\n\n': 'Background:\n',

    // Additional Regex patterns (must start with 'REGEX:')
      //Pain Scale
      'REGEX:(\\d+)\\s+out\\s+of\\s+(\\d+)': '$1/$2',
  };