import React from 'react';
import BaseModal from './BaseModal';

function DeleteAllConfirmation({ onConfirm, onCancel, itemType = 'notes' }) {
    const capitalizedItemType = itemType.charAt(0).toUpperCase() + itemType.slice(1).toLowerCase();
    
    return (
        <BaseModal
            isOpen={true}
            onClose={onCancel}
            title={`Delete All ${capitalizedItemType}`}
            maxWidth="lg"
        >
            <p className="mb-6 text-[#e0e0e0]">
                Are you sure you want to delete all {itemType.toLowerCase()}? This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-4">
                <button
                    onClick={onCancel}
                    className="px-4 py-2 primary-button rounded transition duration-150 ease-in-out text-white flash-button"
                >
                    Cancel
                </button>
                <button
                    onClick={onConfirm}
                    className="px-4 py-2 alert-button rounded transition duration-150 ease-in-out text-white flash-button"
                >
                    Delete All
                </button>
            </div>
        </BaseModal>
    );
}

export default DeleteAllConfirmation; 