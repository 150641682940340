import React, { useRef, useEffect, useCallback, useState } from 'react';
import { renderHighlightedLine } from '../utils/noteHighlightUtils';
import BaseModal from '../common/BaseModal';
import DotPhraseTextArea from './DotPhraseTextArea';
import debounce from 'lodash/debounce';

const AIFormatComparison = ({ 
    originalText, 
    formattedText, 
    isVisible, 
    onClose,
    onComplete, 
    onRevert,
    sectionName,
    onTextChange,
    onCompleteAndNext,
    hasMoreComparisons
}) => {
    const textareaRef = useRef(null);
    const [localText, setLocalText] = useState(formattedText);
    const [panelHeight, setPanelHeight] = useState(400);
    const containerRef = useRef(null);

    // Calculate panel height when component is visible
    useEffect(() => {
        if (isVisible && containerRef.current) {
            const updatePanelHeight = () => {
                const containerHeight = containerRef.current.clientHeight;
                const calculatedHeight = Math.max(containerHeight - 60, 300);
                setPanelHeight(calculatedHeight);
            };
            
            updatePanelHeight();
            window.addEventListener('resize', updatePanelHeight);
            return () => window.removeEventListener('resize', updatePanelHeight);
        }
    }, [isVisible]);

    // Update local text when formattedText changes from parent
    useEffect(() => {
        if (formattedText !== localText) {
            setLocalText(formattedText);
        }
        //Need to avoid adding formattedText to the dependency array because it will cause an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattedText]);

    // Create a debounced version of onTextChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnTextChange = useCallback(
        debounce((text) => {
            onTextChange(text);
        }, 100),
        [onTextChange]
    );

    const handleChange = (e) => {
        const newText = e.target.value;
        setLocalText(newText);
        debouncedOnTextChange(newText);
    };

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedOnTextChange.cancel();
        };
    }, [debouncedOnTextChange]);

    const HighlightedDisplay = ({ text }) => (
        <div style={{
            color: 'white',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            width: '100%',
            overflow: 'visible',
            lineHeight: '1.5',
            padding: '0.5rem',
            maxHeight: '100%'
        }}>
            {text.split('\n').map((line, index) => (
                renderHighlightedLine(line, index, text, sectionName, false)
            ))}
        </div>
    );

    return (
        <BaseModal
            isOpen={isVisible}
            onClose={onClose}
            maxWidth="7xl"
            className="!p-0 h-[90vh] flex flex-col"
            showCloseButton={false}
        >
            <div className="flex items-center justify-between p-3 sm:p-4 relative">
                <div className="absolute inset-x-0 flex justify-center">
                    <h2 className="text-base sm:text-lg font-semibold text-white truncate px-4">
                        {sectionName}
                    </h2>
                </div>
                <div className="z-10">
                    {/* Empty div to maintain space */}
                </div>
                <div className="flex items-center gap-2 z-10">
                    <button
                        onClick={onRevert}
                        className="flex items-center px-2 sm:px-3 py-1.5 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
                        title="Revert to Original"
                    >
                        <span className="material-icons text-white text-sm sm:text-base mr-1">restore</span>
                        <span className="text-xs sm:text-sm text-white">Revert</span>
                    </button>
                    <button
                        onClick={onClose}
                        className="p-1 text-[#ababab] hover:text-white hover:bg-[#323232] rounded-md transition-colors duration-200"
                        title="Close"
                    >
                        <span className="material-icons">close</span>
                    </button>
                </div>
            </div>

            {/* Content */}
            <div className="flex-1 overflow-hidden p-2 sm:p-4 pb-0">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 h-full" ref={containerRef}>
                    {/* Original Text */}
                    <div className="flex flex-col h-full min-h-[200px]">
                        <div className="mb-2 text-xs sm:text-sm font-medium text-[#90caf9] text-center">Original Text</div>
                        <div className="flex-1 input-base rounded-md" style={{ height: `${panelHeight}px`, overflow: 'auto', maxHeight: `${panelHeight}px` }}>
                            <HighlightedDisplay text={originalText} />
                        </div>
                    </div>

                    {/* Formatted Text */}
                    <div className="flex flex-col h-full min-h-[200px]">
                        <div className="mb-2 text-xs sm:text-sm font-medium text-[#90caf9] text-center">Current Text</div>
                        <DotPhraseTextArea
                            ref={textareaRef}
                            value={localText}
                            onChange={handleChange}
                            fixedHeight={panelHeight}
                            section={sectionName}
                            id={`ai-comparison-${sectionName}`}
                        />
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className="p-4 flex justify-end gap-3">
                <button
                    onClick={onComplete}
                    title="Mark Reviewed"
                    className="flex items-center px-4 py-2 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white flash-button"
                >
                    <span className="material-symbols-outlined text-base mr-1">check_box</span>
                    <span className="text-sm">Reviewed</span>
                </button>
                {hasMoreComparisons && (
                    <button
                        onClick={onCompleteAndNext}
                        title="Mark Reviewed and Go to Next"
                        className="flex items-center px-4 py-2 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white flash-button bg-[#1976d2] bg-opacity-20 hover:bg-opacity-30"
                    >
                        <span className="material-symbols-outlined text-base mr-1">navigate_next</span>
                        <span className="text-sm">Reviewed & Next</span>
                    </button>
                )}
            </div>
        </BaseModal>
    );
};

export default AIFormatComparison; 