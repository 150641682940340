const NoteEditorSectionButtons = ({ 
    section, 
    handleCopySection,
    handleSummarize,
    isLoading,
    isSummarizing,
    actionSuccess,
    preFormatText,
    setShowComparison
}) => (
    <div className="flex flex-wrap items-center gap-2">
        <button
            type="button"
            onClick={() => handleCopySection(section)}
            className={`flex items-center justify-center px-2 py-1 rounded-md ${
                actionSuccess[`${section}-copy`] 
                    ? 'bg-[#1976d2] bg-opacity-20 border-[#1976d2]' 
                    : 'base-button'
            } border focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
            title="Copy"
        >
            <span className="material-symbols-outlined text-white text-base">
                {actionSuccess[`${section}-copy`] ? 'check_circle' : 'content_copy'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                Copy
            </span>
        </button>
        <div className="flex items-center gap-1">
            <button
                type="button"
                onClick={() => handleSummarize(section)}
                disabled={isLoading[section]}
                className={`flex items-center justify-center px-2 py-1 rounded-md ${
                    isLoading[section] 
                        ? 'bg-[#1976d2] bg-opacity-20 border-[#1976d2]' 
                        : 'base-button'
                } border focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
                title="Format with AI"
            >
                {isLoading[section] ? (
                    <span className="material-icons text-white text-base animate-spin">
                        hourglass_empty
                    </span>
                ) : (
                    <span className="material-icons text-white text-base">
                        auto_fix_high
                    </span>
                )}
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                    Format
                </span>
            </button>
            {typeof preFormatText === 'string' && !isLoading[section] && !isSummarizing && (
                <button
                    type="button"
                    onClick={() => setShowComparison(prev => ({
                        ...prev,
                        [section]: true
                    }))}
                    className="flex items-center justify-center px-2 py-1 rounded-md bg-[#BF360C] border border-[#A84300] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
                    title="Review Comparison"
                >
                    <span className="material-icons text-white text-base">
                        compare
                    </span>
                    <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                        Compare
                    </span>
                </button>
            )}
        </div>
    </div>
);

export default NoteEditorSectionButtons;