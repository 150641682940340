import React from 'react';
import DraggableList from '../DraggableList';

function NotesList({
    notes,
    selectedNote,
    handleNoteSelect,
    handleDeleteNote,
    draggedNote,
    setDraggedNote,
    draggedOverNote,
    setDraggedOverNote,
    handleDrop,
    deleteConfirmation,
    setDeleteConfirmation,
    isPanelCollapsed
}) {
    const renderNoteContent = (note) => (
        <div className="flex items-center min-w-0 flex-grow">
            <span className="mr-2 text-xs font-semibold bg-[#2a2a2a] text-white px-1 rounded flex-shrink-0">
                {note.preset === 'Outpatient' ? 'OP' :
                    note.preset === 'ID Consult' ? 'NEW' :
                        note.preset === 'ID Follow Up' ? 'F/U' : ''}
            </span>
            <span 
                className="truncate overflow-hidden text-ellipsis flex-grow text-[#e0e0e0]"
                title={(note.title || 'Untitled Note').replace(/[\d/]+/g, '')}
            >
                {(note.title || 'Untitled Note').replace(/[\d/]+/g, '')}
            </span>
        </div>
    );

    return (
        <DraggableList
            items={notes}
            selectedItem={selectedNote}
            onItemSelect={handleNoteSelect}
            onItemDelete={handleDeleteNote}
            draggedItem={draggedNote}
            setDraggedItem={setDraggedNote}
            draggedOverItem={draggedOverNote}
            setDraggedOverItem={setDraggedOverNote}
            onDrop={handleDrop}
            deleteConfirmation={deleteConfirmation}
            setDeleteConfirmation={setDeleteConfirmation}
            isPanelCollapsed={isPanelCollapsed}
            renderItemContent={renderNoteContent}
        />
    );
}

export default NotesList; 