import React, { useState } from 'react';
import CalculatorList from './CalculatorList';
import { calculatorRegistry } from './calculatorRegistry';
import BaseModal from '../common/BaseModal';

function CalculatorDialog({ isOpen, setIsOpen }) {
    const [selectedCalculator, setSelectedCalculator] = useState(null);

    const handleCalculatorSelect = (calculator) => {
        setSelectedCalculator(calculator);
    };

    const handleBack = () => {
        setSelectedCalculator(null);
    };

    const modalContent = (
        <>
            <div className="flex items-center mb-4 sm:mb-6">
                {selectedCalculator && (
                    <button
                        onClick={handleBack}
                        className="mr-3 text-[#ababab] hover:text-white hover:bg-[#323232] rounded-md p-1.5 focus:outline-none"
                    >
                        <span className="material-icons">arrow_back</span>
                    </button>
                )}
            </div>

            <div className="mb-4 overflow-y-auto flex-1">
                {selectedCalculator ? (
                    <selectedCalculator.component />
                ) : (
                    <CalculatorList 
                        calculators={calculatorRegistry}
                        onSelect={handleCalculatorSelect}
                    />
                )}
            </div>
        </>
    );

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={selectedCalculator ? selectedCalculator.name : 'Calculators and Tools'}
            maxWidth="3xl"
            className="max-h-[90vh] flex flex-col"
        >
            {modalContent}
        </BaseModal>
    );
}

export default CalculatorDialog; 