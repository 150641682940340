import { BaseApiService } from './base';

export class AuthService extends BaseApiService {
    async login(username, password, deviceToken = null) {
        const response = await this.axiosInstance.post('/api/login', {
            username,
            password,
            deviceToken
        });
        return response.data;
    }

    async verifyOTP(username, otp) {
        const response = await this.axiosInstance.post('/api/verify-otp', {
            username,
            otp
        });
        return response.data;
    }

    async logout() {
        const response = await this.axiosInstance.post('/api/logout');
        return response.data;
    }
} 