import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import AttestationsView from './AttestationsView';
import useCards from '../../hooks/useCards';
import { exportAttestations } from '../utils/docxExportUtils';

const CardsEditorContent = () => {
    const { 
        cards, 
        isLoading, 
        createCard, 
        deleteCard, 
        deleteAllCards, 
        updateCardWithAPI,
        fetchCards,
        handleAttestationTranscriptionUpdate,
        handleTranscriptionStart,
        handleTranscriptionEnd,
        handleAttestationFocus,
        handleAttestationBlur,
        handleAttestationCursorChange,
        activeAttestationId,
        transcribingCardId,
        reorderCards
    } = useCards();
    const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [, setActionSuccess] = useState({});

    // Fetch cards on component mount
    useEffect(() => {
        const fetchInitialCards = async () => {
            await fetchCards(false); // Use cache when available instead of forcing refresh
        };
        fetchInitialCards();
        
        // Run this effect only once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateNewCard = async () => {
        try {
            await createCard();
        } catch (error) {
            console.error('Failed to create new card:', error);
        }
    };

    const handleUpdateCard = async (updatedCard) => {
        try {
            await updateCardWithAPI(updatedCard.id, updatedCard);
        } catch (error) {
            console.error('Failed to update card:', error);
        }
    };

    const handleExportAll = async () => {
        setIsExporting(true);
        try {
            await exportAttestations(cards);
            setActionSuccess(prev => ({ ...prev, export: true }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, export: false }));
            }, 2000);
        } catch (error) {
            console.error('Failed to export attestations:', error);
            setActionSuccess(prev => ({ ...prev, export: 'error' }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, export: null }));
            }, 2000);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div className="p-4 ml-14 sm:ml-16">
            <div className="mb-6">
                <h2 className="text-xl font-semibold text-white">Patient Index Cards</h2>
            </div>

            <div className="relative mb-2">
                <div className="absolute right-0 top-0 z-10 flex gap-2 flex-wrap justify-end">
                    <button
                        onClick={handleCreateNewCard}
                        className="px-2 py-1 primary-button rounded-md flash-button flex items-center whitespace-nowrap text-sm"
                        title="Create a new card"
                    >
                        <span className="material-icons text-white text-sm mr-1">
                            add_card
                        </span>
                        <span className="hidden sm:inline">New Card</span>
                    </button>
                    <button
                        onClick={handleExportAll}
                        disabled={isExporting}
                        className="px-2 py-1 bg-[#2e7d32] hover:bg-[#388e3c] border border-[#4caf50] rounded-md flash-button flex items-center whitespace-nowrap text-sm"
                        title="Export all attestations"
                    >
                        <span className="material-icons text-white text-sm mr-1">
                            {isExporting ? 'hourglass_empty' : 'upload_file'}
                        </span>
                        <span className="hidden sm:inline">Export All</span>
                    </button>
                </div>
                {/* Divider Line and Component Title */}
                <div className="mb-4">
                    <div className="relative border-b border-[#2a2a2a]">
                        <div className="flex">
                            <div className="py-2 px-4 text-sm font-medium text-[#ababab]">
                                Cards
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {isLoading ? (
                <div className="col-span-2 text-center py-12 text-white app-bg">
                    <p className="mb-4 text-xl sm:text-2xl font-bold overflow-visible">
                        Loading cards
                        <span className="animate-ellipsis inline-block overflow-visible">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </p>
                    <p className="text-sm text-[#ababab]">This may take a few moments</p>
                </div>
            ) : (
                <>
                    <Sidebar 
                        mode="cards"
                        cards={cards}
                        isPanelCollapsed={isPanelCollapsed}
                        setIsPanelCollapsed={setIsPanelCollapsed}
                        handleDeleteCard={deleteCard}
                        handleCardSelect={setSelectedCard}
                        selectedCard={selectedCard}
                        reorderCards={reorderCards}
                        deleteAllCards={deleteAllCards}
                    />
                    <AttestationsView 
                        cards={cards}
                        onUpdateCard={handleUpdateCard}
                        onDelete={deleteCard}
                        onTranscriptionUpdate={handleAttestationTranscriptionUpdate}
                        onTranscriptionStart={handleTranscriptionStart}
                        onTranscriptionEnd={handleTranscriptionEnd}
                        onAttestationFocus={handleAttestationFocus}
                        onAttestationBlur={handleAttestationBlur}
                        onAttestationCursorChange={handleAttestationCursorChange}
                        activeAttestationId={activeAttestationId}
                        transcribingCardId={transcribingCardId}
                        selectedCard={selectedCard}
                    />
                </>
            )}
        </div>
    );
};

export default CardsEditorContent; 