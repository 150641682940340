import { BaseApiService } from './base';

export class SettingsService extends BaseApiService {
    async fetchSettings() {
        const response = await this.axiosInstance.get('/api/settings');
        return response.data;
    }

    async updateSettings(settings) {
        const response = await this.axiosInstance.post('/api/settings', settings);
        return response.data;
    }

    async getPresetFilter() {
        const settings = await this.fetchSettings();
        return settings.presetFilter || 'all';
    }

    async savePresetFilter(filter) {
        return await this.updateSettings({ presetFilter: filter });
    }
} 