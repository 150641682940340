import React from 'react';
import DraggableList from '../DraggableList';

function CardsList({
    cards,
    selectedCard,
    handleCardSelect,
    handleDeleteCard,
    draggedCard,
    setDraggedCard,
    draggedOverCard,
    setDraggedOverCard,
    handleDrop,
    deleteConfirmation,
    setDeleteConfirmation,
    isPanelCollapsed
}) {
    const renderCardContent = (card) => (
        <div className="flex items-center min-w-0 flex-grow">
            <span className="mr-2 text-xs font-semibold bg-[#2a2a2a] text-white px-1 rounded flex-shrink-0">
                {card.preset === 'Outpatient' ? 'OP' :
                    card.preset === 'ID Consult' ? 'NEW' :
                        card.preset === 'ID Follow Up' ? 'F/U' : ''}
            </span>
            <span 
                className="truncate overflow-hidden text-ellipsis flex-grow text-[#e0e0e0]"
                title={(card.title || 'Untitled Card').replace(/[\d/]+/g, '')}
            >
                {(card.title || 'Untitled Card').replace(/[\d/]+/g, '')}
            </span>
        </div>
    );

    return (
        <DraggableList
            items={cards}
            selectedItem={selectedCard}
            onItemSelect={handleCardSelect}
            onItemDelete={handleDeleteCard}
            draggedItem={draggedCard}
            setDraggedItem={setDraggedCard}
            draggedOverItem={draggedOverCard}
            setDraggedOverItem={setDraggedOverCard}
            onDrop={handleDrop}
            deleteConfirmation={deleteConfirmation}
            setDeleteConfirmation={setDeleteConfirmation}
            isPanelCollapsed={isPanelCollapsed}
            renderItemContent={renderCardContent}
        />
    );
}

export default CardsList; 