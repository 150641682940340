import React, { useState } from 'react';
import BaseModal from '../common/BaseModal';

const OPATNoteModal = ({ isOpen, onClose, onSubmit }) => {
    const [opatNote, setOpatNote] = useState('');

    const parseOPATNote = (note) => {
        // Basic extraction using simple string search
        const extractValue = (pattern, defaultValue = '') => {
            const match = note.match(pattern);
            return match ? match[1].trim() : defaultValue;
        };

        // Extract patient name and MRN
        const name = extractValue(/Patient Name:\s*([^\n\r]+)/i);
        const mrn = extractValue(/MRN:\s*([^\n\r]+)/i);
        const title = name && mrn ? `${name} ${mrn}` : '';

        // Extract diagnosis
        const consultInfo = extractValue(/Diagnosis\/Diagnoses:\s*([^\n\r]+)/i);
        //Clean consultInfo to remove all ICD-codes and other text, as well as extra spaces after these deletions
        const cleanedConsultInfo = consultInfo.replace(/[()[\]][^()[\]]*[()[\]]/g, '')
            .replace(/\s+,/g, ',')
            .replace(/\s+/g, ' ')
            .trim();

        // Extract synopsis
        const background = extractValue(/Synopsis of Patient’s Clinical Course:([\s\S]*)/); //This is a working Regex. Do not change it.

        // Extract all medications
        const antimicrobials = [];
        const recommendations = [];

        // Find all medication patterns in the text
        const medRegex = /Medication #\d+:\s*([^\n]+)[\s\S]*?Dose:\s*([^\n]+)[\s\S]*?Frequency:\s*([^\n]+)[\s\S]*?Start Date:\s*([^\n]+)[\s\S]*?Planned Duration:\s*(\d+)/g;
        
        let match;
        while ((match = medRegex.exec(note)) !== null) {
            const [_, name, dose, freq, startDate, duration] = match;
            
            // Format antimicrobial string
            const startDateObj = new Date(startDate.trim());
            const medString = `${name.trim()} ${dose.trim()} ${freq.trim()} (${startDateObj.toLocaleDateString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric' })}-)`;
            antimicrobials.push(medString);
            
            // Calculate end date
            try {
                const startDateObj = new Date(startDate.trim());
                if (!isNaN(startDateObj.getTime())) {
                    const durationWeeks = parseInt(duration);
                    const endDate = new Date(startDateObj);
                    endDate.setDate(endDate.getDate() + (durationWeeks * 7));
                    const endDateStr = endDate.toLocaleDateString('en-US', { year: '2-digit', month: 'numeric', day: 'numeric' });
                    recommendations.push(`${name.trim()}: ${durationWeeks} weeks: EOT: ${endDateStr}`);
                } else {
                    recommendations.push(`${name.trim()}: ${duration} weeks`);
                }
            } catch (e) {
                // Fallback if date calculation fails
                recommendations.push(`${name.trim()}: ${duration} weeks`);
            }
        }

        const result = {
            title,
            consultInfo: cleanedConsultInfo,
            background,
            antimicrobials: antimicrobials.join('\n'),
            recommendations: 'Antimicrobial Plan:\n' + recommendations.join('\n')
        };

        console.log('Found medications:', antimicrobials);
        console.log('Parsed result:', result);
        return result;
    };

    const handleSubmit = () => {
        const parsedData = parseOPATNote(opatNote);
        onSubmit(parsedData);
        onClose();
    };

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            title="Parse OPAT Note"
            maxWidth="4xl"
        >
            <div className="space-y-4">
                <div>
                    <textarea
                        value={opatNote}
                        onChange={(e) => setOpatNote(e.target.value)}
                        className="w-full h-96 p-3 rounded-md input-base"
                    />
                </div>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border rounded-md base-button"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 border rounded-md primary-button"
                    >
                        Parse & Apply
                    </button>
                </div>
            </div>
        </BaseModal>
    );
};

export default OPATNoteModal; 