import React, { useState } from 'react';

const InsulinFillsCalculator = () => {
    const [strength, setStrength] = useState('100');
    const [volume, setVolume] = useState('3');
    const [dailyUnits, setDailyUnits] = useState('');
    const [results, setResults] = useState(null);

    const roundUpToVolumeUnit = (ml, volumePerUnit) => {
        const units = Math.ceil(ml / volumePerUnit);
        return units * volumePerUnit;
    };

    const calculateFills = () => {
        if (!dailyUnits || !strength || !volume) return;

        const strengthNum = parseFloat(strength);
        const volumeNum = parseFloat(volume);
        const dailyUnitsNum = parseFloat(dailyUnits);

        if (strengthNum <= 0 || volumeNum <= 0 || dailyUnitsNum <= 0) {
            alert("All values must be greater than zero");
            return;
        }

        const dailyML = (dailyUnitsNum * volumeNum) / strengthNum;
        const thirtyDayML = dailyML * 30;
        const ninetyDayML = dailyML * 90;

        // Round up to nearest volume unit
        const roundedThirtyDay = roundUpToVolumeUnit(thirtyDayML, volumeNum);
        const roundedNinetyDay = roundUpToVolumeUnit(ninetyDayML, volumeNum);

        setResults({
            thirtyDay: roundedThirtyDay.toFixed(2),
            ninetyDay: roundedNinetyDay.toFixed(2)
        });
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <div className="p-4">
            <div className="space-y-3">
                <div className="flex flex-col md:flex-row md:items-end md:space-x-2 space-y-3 md:space-y-0">
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Strength (units)</label>
                        <input
                            value={strength}
                            onChange={(e) => setStrength(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="100"
                        />
                    </div>
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Volume (mL)</label>
                        <input
                            value={volume}
                            onChange={(e) => setVolume(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="3"
                        />
                    </div>
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Daily Units</label>
                        <input
                            value={dailyUnits}
                            onChange={(e) => setDailyUnits(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>

                    <button
                        onClick={calculateFills}
                        className="px-6 py-2 primary-button text-white rounded-md flash-button w-full md:w-auto"
                    >
                        Calculate
                    </button>
                </div>

                {results && (
                    <div className="mt-8 p-3 base-surface rounded-md">
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-gray-400 text-sm">30-day supply:</span>
                                    <span className="text-gray-100 ml-2">{results.thirtyDay} mL</span>
                                </div>
                                <button
                                    onClick={() => copyToClipboard(results.thirtyDay)}
                                    className="px-3 py-1 base-button flash-button rounded-md text-sm"
                                >
                                    <span className="material-symbols-outlined text-white text-sm mr-1">
                                        file_copy
                                    </span>
                                    Copy
                                </button>
                            </div>
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-gray-400 text-sm">90-day supply:</span>
                                    <span className="text-gray-100 ml-2">{results.ninetyDay} mL</span>
                                </div>
                                <button
                                    onClick={() => copyToClipboard(results.ninetyDay)}
                                    className="px-3 py-1 base-button flash-button rounded-md text-sm"
                                >
                                    <span className="material-symbols-outlined text-white text-sm mr-1">
                                        file_copy
                                    </span>
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InsulinFillsCalculator; 