import { useState, useEffect, useCallback, useRef } from 'react';
import apiService from '../services/api';
import { debounce } from 'lodash';

// Cache for documents data
let documentsCache = null;
let lastFetchTimestamp = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const useDocuments = () => {
    const [documents, setDocuments] = useState(documentsCache || []);
    const [currentDocument, setCurrentDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(!documentsCache);
    // eslint-disable-next-line no-unused-vars
    const [lastViewedDocumentId, setLastViewedDocumentId] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [actionSuccess, setActionSuccess] = useState({ save: null });
    const initialFetchDone = useRef(false);
    const [changedFields, setChangedFields] = useState({});

    const invalidateCache = useCallback(() => {
        documentsCache = null;
        lastFetchTimestamp = null;
        initialFetchDone.current = false;
    }, []);

    const shouldRefetch = useCallback(() => {
        return !documentsCache || !lastFetchTimestamp || (Date.now() - lastFetchTimestamp > CACHE_DURATION);
    }, []);

    // Helper function to select the appropriate document
    const selectAppropriateDocument = useCallback((docs, lastViewedId) => {
        if (!docs || docs.length === 0) {
            setCurrentDocument(null);
            return;
        }

        // Convert IDs to strings for consistent comparison
        const targetId = lastViewedId?.toString();
        const documentToSelect = targetId 
            ? docs.find(doc => doc.id?.toString() === targetId)
            : null;

        // If we found the last viewed document, use it; otherwise use the first document
        setCurrentDocument(documentToSelect || docs[0]);
    }, []);

    const fetchDocuments = useCallback(async (force = false) => {
        if (!force && (initialFetchDone.current || !shouldRefetch())) {
            // If using cached data, ensure we have the correct document selected
            if (documentsCache) {
                selectAppropriateDocument(documentsCache, lastViewedDocumentId);
            }
            return;
        }

        try {
            setIsLoading(true);
            const fetchedDocuments = await apiService.fetchDocuments();
            documentsCache = fetchedDocuments;
            lastFetchTimestamp = Date.now();
            setDocuments(fetchedDocuments);

            // Fetch last viewed document ID
            const { lastViewedDocumentId: fetchedLastViewedId } = await apiService.fetchLastViewedDocument();
            setLastViewedDocumentId(fetchedLastViewedId);

            // Select appropriate document
            selectAppropriateDocument(fetchedDocuments, fetchedLastViewedId);
            initialFetchDone.current = true;
        } catch (error) {
            console.error('Error fetching documents:', error);
            invalidateCache();
        } finally {
            setIsLoading(false);
        }
    }, [shouldRefetch, invalidateCache, selectAppropriateDocument, lastViewedDocumentId]);


    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    const selectDocument = useCallback(async (document) => {
        if (!document) return;
        
        try {
            setCurrentDocument(document);
            const documentId = document.id?.toString();
            await apiService.updateLastViewedDocument(document.id);
            setLastViewedDocumentId(documentId);
        } catch (error) {
            console.error('Error updating last viewed document:', error);
        }
    }, []);

    const createDocument = useCallback(async () => {
        const newDocument = {
            title: 'Untitled Document',
            content: '',
            preset: 'Letter',
        };

        // For the first document, ensure the cache is initialized
        if (!documentsCache) {
            documentsCache = [];
            lastFetchTimestamp = Date.now();
        }

        // Create optimistic document with temporary ID
        const tempDocument = {
            ...newDocument,
            id: Date.now(), // Use timestamp as temporary ID for consistency
            isOptimistic: true
        };
        
        // Optimistically update local state
        setDocuments(prevDocuments => [...prevDocuments, tempDocument]);
        setCurrentDocument(tempDocument);
        
        try {
            const createdDocument = await apiService.createDocument(newDocument);
            
            // Update both state and cache with the server-generated ID
            setDocuments(prevDocuments => {
                const updatedDocuments = prevDocuments.map(doc =>
                    doc.id === tempDocument.id ? createdDocument : doc
                );
                documentsCache = updatedDocuments; // Update cache with the new state
                return updatedDocuments;
            });
            setCurrentDocument(createdDocument);
            
            // Update last viewed document
            await apiService.updateLastViewedDocument(createdDocument.id);
            setLastViewedDocumentId(createdDocument.id.toString());

            return createdDocument;
        } catch (error) {
            console.error('Error creating document:', error);
            // Revert the optimistic update
            setDocuments(prevDocuments => 
                prevDocuments.filter(doc => doc.id !== tempDocument.id)
            );
            setCurrentDocument(null);
            throw error;
        }
    }, []);

    const deleteDocument = useCallback(async (documentId) => {
        // Ensure consistent ID format for comparison
        const currentId = currentDocument?.id?.toString();
        const targetId = documentId?.toString();
        const wasCurrentDocument = currentId === targetId;

        // Store previous state for rollback
        const previousDocuments = [...documents];
        const previousCache = documentsCache ? [...documentsCache] : null;
        const previousLastViewed = lastViewedDocumentId;

        // Optimistically update state and cache
        const updatedDocuments = documents.filter(doc => doc.id?.toString() !== targetId);
        setDocuments(updatedDocuments);
        documentsCache = updatedDocuments;
        lastFetchTimestamp = Date.now();

        // Handle current document selection optimistically
        if (wasCurrentDocument && updatedDocuments.length > 0) {
            const deletedIndex = previousDocuments.findIndex(doc => doc.id?.toString() === targetId);
            const nextDoc = updatedDocuments[deletedIndex] || // Same position
                          updatedDocuments[deletedIndex - 1] || // Previous
                          updatedDocuments[0]; // First as fallback
            
            setCurrentDocument(nextDoc);
            setLastViewedDocumentId(nextDoc.id.toString());
        } else if (updatedDocuments.length === 0) {
            setCurrentDocument(null);
            setLastViewedDocumentId(null);
        }

        try {
            await apiService.deleteDocument(documentId);
        } catch (error) {
            // Restore previous state on error
            console.error('Error deleting document:', error);
            setDocuments(previousDocuments);
            documentsCache = previousCache;
            if (wasCurrentDocument) {
                const originalDoc = previousDocuments.find(doc => doc.id?.toString() === targetId);
                if (originalDoc) {
                    setCurrentDocument(originalDoc);
                }
            }
            setLastViewedDocumentId(previousLastViewed);
            throw error;
        }
    }, [currentDocument?.id, documents, lastViewedDocumentId]);

    const deleteAllDocuments = useCallback(async () => {
        // Store previous state for rollback
        const previousDocuments = [...documents];
        const previousCache = documentsCache ? [...documentsCache] : null;
        const previousCurrentDocument = currentDocument;
        const previousLastViewed = lastViewedDocumentId;

        // Optimistically update state and cache
        setDocuments([]);
        setCurrentDocument(null);
        setLastViewedDocumentId(null);
        documentsCache = [];
        lastFetchTimestamp = Date.now();

        try {
            await apiService.deleteAllDocuments();
        } catch (error) {
            console.error('Error deleting all documents:', error);
            // Restore previous state on error
            setDocuments(previousDocuments);
            documentsCache = previousCache;
            setCurrentDocument(previousCurrentDocument);
            setLastViewedDocumentId(previousLastViewed);
            throw error;
        }
    }, [documents, currentDocument, lastViewedDocumentId]);

    const handleSave = useCallback(async () => {
        if (!currentDocument || !hasUnsavedChanges) return;

        setActionSuccess(prev => ({ ...prev, save: 'saving' }));
        try {
            // Only save the fields that have changed
            const changedData = Object.keys(changedFields).reduce((acc, field) => {
                acc[field] = currentDocument[field];
                return acc;
            }, {});

            // Only make API call if there are changes
            if (Object.keys(changedData).length > 0) {
                const savedDocument = await apiService.updateDocument(currentDocument.id, changedData);
                
                // Update documents list
                setDocuments(prevDocs => {
                    const updatedDocs = prevDocs.map(doc => 
                        doc.id === savedDocument.id ? { ...doc, ...savedDocument } : doc
                    );
                    documentsCache = updatedDocs;
                    return updatedDocs;
                });
            }
            
            setChangedFields({});
            setHasUnsavedChanges(false);
            setActionSuccess(prev => ({ ...prev, save: true }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, save: null }));
            }, 2000);
        } catch (error) {
            console.error('Error updating document:', error);
            setActionSuccess(prev => ({ ...prev, save: 'error' }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, save: null }));
            }, 2000);
            throw error;
        }
    }, [currentDocument, hasUnsavedChanges, changedFields]);

    const debouncedSave = useCallback(() => {
        const saveIfNeeded = debounce(() => {
            if (hasUnsavedChanges) {
                handleSave();
            }
        }, 10000);

        saveIfNeeded();
        return () => saveIfNeeded.cancel();
    }, [hasUnsavedChanges, handleSave]);

    useEffect(() => {
        let cleanup;
        if (hasUnsavedChanges) {
            cleanup = debouncedSave();
        }
        return () => {
            if (cleanup) cleanup();
        };
    }, [hasUnsavedChanges, debouncedSave]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (hasUnsavedChanges) {
                    handleSave();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hasUnsavedChanges, handleSave]);

    const updateDocument = useCallback((fieldOrUpdater) => {
        setCurrentDocument(prevDoc => {
            if (!prevDoc) return null;
            if (typeof fieldOrUpdater === 'function') {
                return fieldOrUpdater(prevDoc);
            }
            if (typeof fieldOrUpdater === 'object') {
                return { ...prevDoc, ...fieldOrUpdater };
            }
            return prevDoc;
        });
        
        setChangedFields(prev => {
            if (typeof fieldOrUpdater === 'object') {
                return { ...prev, ...Object.fromEntries(Object.keys(fieldOrUpdater).map(key => [key, true])) };
            }
            return prev;
        });
        setHasUnsavedChanges(true);
    }, []);

    const reorderDocuments = async (newDocuments) => {
        try {
            await Promise.all(newDocuments.map((doc, index) => 
                apiService.reorderDocument(doc.id, index)
            ));
            setDocuments(newDocuments);
        } catch (error) {
            console.error('Error reordering documents:', error);
            throw error;
        }
    };

    return {
        documents,
        currentDocument,
        isLoading,
        createDocument,
        selectDocument,
        deleteDocument,
        deleteAllDocuments,
        updateDocument,
        reorderDocuments,
        hasUnsavedChanges,
        actionSuccess,
        handleSave,
        invalidateCache,
        fetchDocuments
    };
};

export default useDocuments; 