import React, { useState, useRef, useMemo, useEffect } from 'react';
import BaseSidebar from '../BaseSidebar';
import SidebarHeader from './SidebarHeader';
import NotesList from './NotesList';
import SidebarButtons from './SidebarButtons';
import apiService from '../../../services/api';

function NotesSidebar({
    notes,
    selectedNote,
    handleNewNote,
    handleNoteSelect,
    handleDeleteNote,
    setShowDeleteAllConfirmation,
    reorderNotes,
    isPanelCollapsed,
    setIsPanelCollapsed,
    handleSignout,
    handleBulkNoteCreation
}) {
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [draggedNote, setDraggedNote] = useState(null);
    const [draggedOverNote, setDraggedOverNote] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [presetFilter, setPresetFilter] = useState('all');
    
    const notesListRef = useRef(null);

    useEffect(() => {
        const loadPresetFilter = async () => {
            try {
                const filter = await apiService.getPresetFilter();
                setPresetFilter(filter);
            } catch (error) {
                console.error('Error loading preset filter:', error);
            }
        };
        loadPresetFilter();
    }, []);

    const handleDrop = (e, targetNote) => {
        e.preventDefault();
        const draggedNoteId = e.dataTransfer.getData('text');
        const draggedIndex = notes.findIndex(note => note.id.toString() === draggedNoteId);
        const targetIndex = notes.findIndex(note => note.id === targetNote.id);

        if (draggedIndex !== targetIndex) {
            const newNotes = Array.from(notes);
            const [reorderedItem] = newNotes.splice(draggedIndex, 1);
            newNotes.splice(targetIndex, 0, reorderedItem);
            reorderNotes(newNotes);
        }
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handlePresetFilterChange = async (filter) => {
        const previousFilter = presetFilter;
        setPresetFilter(filter);

        try {
            await apiService.savePresetFilter(filter);
        } catch (error) {
            console.error('Error saving preset filter:', error);
            setPresetFilter(previousFilter);
        }
    };

    const filteredNotes = notes.filter(note =>
        note.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (presetFilter === 'all' || 
         (presetFilter === 'outpatient' && note.preset === 'Outpatient') ||
         (presetFilter === 'inpatient' && note.preset !== 'Outpatient'))
    );

    const presetCounts = useMemo(() => ({
        all: notes.length,
        outpatient: notes.filter(note => note.preset === 'Outpatient').length,
        inpatient: notes.filter(note => note.preset !== 'Outpatient').length
    }), [notes]);

    return (
        <BaseSidebar
            isPanelCollapsed={isPanelCollapsed}
            setIsPanelCollapsed={setIsPanelCollapsed}
            isSearchFocused={isSearchFocused}
            title="Saved Notes"
        >
            <SidebarHeader
                isPanelCollapsed={isPanelCollapsed}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                isSearchFocused={isSearchFocused}
                setIsSearchFocused={setIsSearchFocused}
                handleClearSearch={handleClearSearch}
                presetFilter={presetFilter}
                presetCounts={presetCounts}
                handlePresetFilterChange={handlePresetFilterChange}
            />

            <SidebarButtons
                isPanelCollapsed={isPanelCollapsed}
                handleNewNote={handleNewNote}
                handleSignout={handleSignout}
                notesExist={notes.length > 0}
                handleBulkNoteCreation={handleBulkNoteCreation}
            />

            <div ref={notesListRef} className="flex-1 overflow-y-auto -mx-4 mt-4">
                <NotesList
                    notes={filteredNotes}
                    selectedNote={selectedNote}
                    handleNoteSelect={handleNoteSelect}
                    handleDeleteNote={handleDeleteNote}
                    draggedNote={draggedNote}
                    setDraggedNote={setDraggedNote}
                    draggedOverNote={draggedOverNote}
                    setDraggedOverNote={setDraggedOverNote}
                    handleDrop={handleDrop}
                    deleteConfirmation={deleteConfirmation}
                    setDeleteConfirmation={setDeleteConfirmation}
                    isPanelCollapsed={isPanelCollapsed}
                />
            </div>

            {!isPanelCollapsed && (
                <div className="-mx-4 px-4 mt-auto pt-2">
                    <button
                        onClick={() => setShowDeleteAllConfirmation(true)}
                        className="w-full flex items-center justify-center p-2 alert-button rounded transition duration-150 ease-in-out shadow-md text-white flash-button"
                    >
                        <span className="material-icons mr-2">delete_forever</span>
                        Delete All
                    </button>
                </div>
            )}
        </BaseSidebar>
    );
}

export default NotesSidebar; 