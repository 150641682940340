import React from 'react';
import CardsEditorContent from '../CardsEditor/CardsEditorContent';

function CardsMode() {
    return (
        <div className="cards-mode">
            <div className="transition-all duration-300">
                <main className="p-4">
                    <div className="min-h-screen app-bg pt-24 sm:pt-28">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
                            <CardsEditorContent />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default CardsMode; 