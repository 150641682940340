import { isTomorrow } from 'date-fns';

export const createNoteSignature = (notePreset, settings) => {
    let signature = '';

    if (notePreset === "ID Consult") {
        signature = "\nDiscussed recommendations with Primary Team.\n\n";
    }

    //Timed naming of person you are signing out to.
    if (settings.signoutDate && settings.signoutPerson) {
        if (isTomorrow(settings.signoutDate)) {
            signature += `Dr. ${settings.signoutPerson} will assume care of service tomorrow.\n\n`;
        }
    }

    if (notePreset === "ID Consult" || notePreset === "ID Follow Up") {
    signature += `Thank you for involving us in the care of your patient. Please reach out to the Infectious Diseases consultation team for any questions or concerns.

Terry Marryshow, MD
Infectious Disease Attending`;
    }

    return signature;
};


export const createDocumentSignature = () => {
    let signature =  "Terry Marryshow, MD\nAttending Physician\nDivision of Geographic Medicine and Infectious Diseases\nTufts Medical Center\n800 Washington St\nBoston, MA 02111";

    return signature;
};
