import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../../theme/muiDarkTheme';
import { addDays, addWeeks, addMonths, format } from 'date-fns';

const EOTCalculator = () => {
    const [startDate, setStartDate] = useState(null);
    const [duration, setDuration] = useState('');
    const [unit, setUnit] = useState('weeks');
    const [endDate, setEndDate] = useState(null);

    const calculateEndDate = () => {
        if (!startDate || !duration) return;

        const durationNum = parseInt(duration);
        let calculatedDate;

        switch (unit) {
            case 'days':
                calculatedDate = addDays(startDate, durationNum);
                break;
            case 'weeks':
                calculatedDate = addWeeks(startDate, durationNum);
                break;
            case 'months':
                calculatedDate = addMonths(startDate, durationNum);
                break;
            default:
                return;
        }

        setEndDate(calculatedDate);
    };

    const copyToClipboard = () => {
        if (!endDate) return;
        navigator.clipboard.writeText(format(endDate, 'MM/dd/yy'));
    };

    return (
        <div className="p-4">
            <div className="space-y-3">
                <div className="flex flex-col md:flex-row md:items-end md:space-x-2 space-y-3 md:space-y-0">
                    <div>
                        <label className="block text-gray-300 text-sm mb-1">Start Date</label>
                        <ThemeProvider theme={darkTheme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    size="small"
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </div>

                    <div className="w-24">
                        <label className="block text-gray-300 text-sm mb-1">Duration</label>
                        <input
                            type="text"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Unit</label>
                        <select
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                        >
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                            <option value="months">Months</option>
                        </select>
                    </div>

                    <button
                        onClick={calculateEndDate}
                        className="px-6 py-2 primary-button text-white rounded-md flash-button w-full md:w-auto"
                    >
                        Calculate
                    </button>
                </div>

                {endDate && (
                    <div className="mt-8 p-3 base-surface rounded-md">
                        <div className="flex items-center justify-between">
                            <div>
                                <span className="text-gray-400 text-sm">Estimated End of Therapy:</span>
                                <span className="text-gray-100 ml-2">{format(endDate, 'MM/dd/yy')}</span>
                            </div>
                            <button
                                onClick={copyToClipboard}
                                className="px-3 py-1 base-button flash-button rounded-md text-sm"
                            >
                                <span className="material-symbols-outlined text-white text-sm mr-1">
                                    file_copy
                                </span>
                                Copy
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EOTCalculator; 