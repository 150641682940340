import React, { useState, useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import CardItem from './CardItem';
import AttestationsSaveButton from './buttons/AttestationsSaveButton';
import CardItemActionButtons from './buttons/CardItemActionButtons';
import AudioTranscriptionButton from '../NoteEditor/buttons/AudioTranscriptionButton';
import DotPhraseTextArea from '../NoteEditor/DotPhraseTextArea';
import OPATNoteModal from './OPATNoteModal';
import { useNotes } from '../../hooks/useNotes';
import { createAttestationHandlers } from './handlers/attestationHandlers';

const SUPERVISEE_ROLES = [
    'Nurse Practitioner',
    'Medical Student',
    'Resident',
    'Fellow'
];

const MODALITY_ICONS = {
    'in-person': 'person',
    'video': 'videocam',
    'telephone': 'phone'
};

const MODALITY_CYCLE = ['in-person', 'video', 'telephone'];

const AttestationsView = ({ 
    cards, 
    onUpdateCard, 
    onDelete,
    onTranscriptionUpdate,
    onTranscriptionStart,
    onTranscriptionEnd,
    onAttestationFocus,
    onAttestationBlur,
    activeAttestationId,
    transcribingCardId,
    selectedCard
}) => {
    const { createNewNote } = useNotes();
    const [pendingChanges, setPendingChanges] = useState({});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [actionSuccess, setActionSuccess] = useState({ save: null });
    const [deletingCardId, setDeletingCardId] = useState(null);
    const [pendingCursorUpdate, setPendingCursorUpdate] = useState(null);
    const [editingCardId, setEditingCardId] = useState(null);
    const [isSavingCard, setIsSavingCard] = useState(false);
    const [showOPATModal, setShowOPATModal] = useState(false);
    const [currentOPATCardId, setCurrentOPATCardId] = useState(null);
    const [isCreatingNote, setIsCreatingNote] = useState(null);
    
    // Create a ref object to store refs for each card's textarea
    const attestationRefs = useRef({});
    // Add cardRefs to track card elements
    const cardRefs = useRef({});

    // Initialize refs for new cards
    useEffect(() => {
        cards.forEach(card => {
            if (!attestationRefs.current[card.id]) {
                attestationRefs.current[card.id] = React.createRef();
            }
        });
    }, [cards]);

    // Initialize refs for cards and scroll to selected card when it changes
    useEffect(() => {
        cards.forEach(card => {
            if (!cardRefs.current[card.id]) {
                cardRefs.current[card.id] = React.createRef();
            }
        });

        if (selectedCard && cardRefs.current[selectedCard.id]?.current) {
            const headerHeight = document.querySelector('header')?.offsetHeight || 96; // 80px + 16px or 48px + 8px
            const cardElement = cardRefs.current[selectedCard.id].current;
            const cardPosition = cardElement.getBoundingClientRect().top;
            const offset = cardPosition - headerHeight - 7; // Added 5px buffer

            window.scrollBy({
                top: offset,
                behavior: 'smooth'
            });
        }
    }, [cards, selectedCard]);

    // Update pendingChanges when cards change
    useEffect(() => {
        setPendingChanges(prev => {
            const newPendingChanges = { ...prev };
            cards.forEach(card => {
                if (prev[card.id]) {
                    // Keep only the fields that are different from the card
                    const changes = {};
                    Object.keys(prev[card.id]).forEach(key => {
                        if (prev[card.id][key] !== card[key]) {
                            changes[key] = prev[card.id][key];
                        }
                    });
                    if (Object.keys(changes).length > 0) {
                        newPendingChanges[card.id] = changes;
                    } else {
                        delete newPendingChanges[card.id];
                    }
                }
            });
            return newPendingChanges;
        });

        // Apply any pending cursor update after the state has been updated
        if (pendingCursorUpdate) {
            const { cardId, position } = pendingCursorUpdate;
            const textareaRef = attestationRefs.current[cardId]?.current;
            if (textareaRef) {
                textareaRef.focus();
                textareaRef.setSelectionRange(position, position);
            }
            setPendingCursorUpdate(null);
        }
    }, [cards, pendingCursorUpdate]);

    const handlers = createAttestationHandlers({
        cards,
        pendingChanges,
        setPendingChanges,
        setHasUnsavedChanges,
        setActionSuccess,
        setPendingCursorUpdate,
        onUpdateCard,
        onTranscriptionUpdate,
        createNewNote,
        setShowOPATModal,
        setCurrentOPATCardId,
        setEditingCardId,
        setIsSavingCard,
        setDeletingCardId,
        setIsCreatingNote,
        currentOPATCardId,
        isCreatingNote
    });

    //Auto-save every 10 seconds
    const debouncedSave = useCallback(() => {
        const saveIfNeeded = debounce(() => {
            if (hasUnsavedChanges) {
                handlers.handleSave();
            }
        }, 10000);

        saveIfNeeded();
        return () => saveIfNeeded.cancel();
    }, [hasUnsavedChanges, handlers]);

    // UseEffect for auto-save cleanup
    useEffect(() => {
        let cleanup;
        if (hasUnsavedChanges) {
            cleanup = debouncedSave();
        }
        return () => {
            if (cleanup) cleanup();
        };
    }, [hasUnsavedChanges, debouncedSave]);

    // Add keyboard shortcut for saving
    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (hasUnsavedChanges) {
                    handlers.handleSave();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hasUnsavedChanges, handlers]);

    const handleDeleteConfirm = async (cardId) => {
        try {
            await onDelete(cardId);
        } finally {
            setDeletingCardId(null);
        }
    };

    const handleDeleteCancel = () => {
        setDeletingCardId(null);
    };

    return (
        <>
            <div className="space-y-6">
                {cards.map(card => (
                    <div 
                        key={card.id} 
                        ref={cardRefs.current[card.id]}
                        className="surface-bg surface-border rounded-lg overflow-hidden"
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 h-full">
                            {/* Card View (Left Column) */}
                            <div className="w-full h-full p-4 border-b md:border-b-0 md:border-r border-[#2a2a2a] relative">
                                <CardItem
                                    card={card}
                                    onUpdate={handlers.handleCardSave}
                                    isCurrentlyEditable={editingCardId === card.id}
                                    readOnly={!editingCardId}
                                    hideActions={true}
                                    onDelete={() => handlers.handleDeleteClick(card.id)}
                                    onEditingStateChange={(isEditing) => isEditing ? handlers.handleCardEditStart(card.id) : handlers.handleCardEditCancel()}
                                    isEditing={editingCardId === card.id}
                                    isSaving={isSavingCard}
                                    className="h-full"
                                    isAttestationView={true}
                                    onParseOPAT={true}
                                />
                                <CardItemActionButtons
                                    onEdit={() => handlers.handleCardEditStart(card.id)}
                                    onSave={() => handlers.handleCardSave(card)}
                                    onCancel={handlers.handleCardEditCancel}
                                    isEditing={editingCardId === card.id}
                                    isSaving={isSavingCard}
                                    onParseOPAT={() => handlers.handleParseOPAT(card.id)}
                                    onDelete={() => handlers.handleDeleteClick(card.id)}
                                    onDeleteConfirm={() => handleDeleteConfirm(card.id)}
                                    onDeleteCancel={handleDeleteCancel}
                                    isDeleting={deletingCardId === card.id}
                                    onCreateNote={() => handlers.handleCreateNote(card)}
                                    isCreatingNote={isCreatingNote === card.id}
                                />
                            </div>

                            {/* Attestation (Right Column) */}
                            <div className="w-full p-4">
                                <div className="flex flex-col space-y-4">
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <label 
                                                htmlFor={`supervisee-${card.id}`}
                                                className="text-sm text-[#ababab] whitespace-nowrap"
                                            >
                                                Supervising:
                                            </label>
                                            <select
                                                id={`supervisee-${card.id}`}
                                                className={`w-48 py-0.25 px-2 rounded-md input-base text-sm ${
                                                    !(pendingChanges[card.id]?.supervisee ?? card.supervisee) ? '[&:not(:focus)]:bg-red-800/30 [&:not(:focus)]:border-red-700/30' : ''
                                                }`}
                                                value={(pendingChanges[card.id]?.supervisee ?? card.supervisee) || ''}
                                                onChange={(e) => handlers.handleSuperviseeChange(card.id, e.target.value)}
                                            >
                                                <option value="">Select role...</option>
                                                {SUPERVISEE_ROLES.map(role => (
                                                    <option key={role} value={role}>{role}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="flex items-center gap-2 mt-2">
                                            <button
                                                className={`base-button p-2 w-9 h-9 rounded flex items-center justify-center ${
                                                    !(pendingChanges[card.id]?.seen ?? card.seen) ? 'alert-button' : ''
                                                }`}
                                                onClick={() => {
                                                    const newSeen = !(pendingChanges[card.id]?.seen ?? card.seen);
                                                    handlers.handleSeenChange(card.id, newSeen);
                                                }}
                                                title={((pendingChanges[card.id]?.seen ?? card.seen) ? "Seen" : "Unseen")}
                                            >
                                                <span className="material-symbols-outlined text-xl">
                                                    {(pendingChanges[card.id]?.seen ?? card.seen) ? 'visibility' : 'visibility_off'}
                                                </span>
                                            </button>
                                            <button
                                                className="base-button p-2 w-9 h-9 rounded flex items-center justify-center"
                                                onClick={() => {
                                                    const currentModality = pendingChanges[card.id]?.modality ?? card.modality ?? 'in-person';
                                                    const currentIndex = MODALITY_CYCLE.indexOf(currentModality);
                                                    const nextModality = MODALITY_CYCLE[(currentIndex + 1) % MODALITY_CYCLE.length];
                                                    handlers.handleModalityChange(card.id, nextModality);
                                                }}
                                                title={`Modality: ${pendingChanges[card.id]?.modality ?? card.modality ?? 'in-person'}`}
                                            >
                                                <span className="material-symbols-outlined text-xl">
                                                    {MODALITY_ICONS[pendingChanges[card.id]?.modality ?? card.modality ?? 'in-person']}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <label 
                                            htmlFor={`attestation-${card.id}`}
                                            className="text-sm text-[#ababab] mb-2 block"
                                        >
                                            Attestation:
                                        </label>
                                        <DotPhraseTextArea
                                            id={`attestation-${card.id}`}
                                            ref={attestationRefs.current[card.id]}
                                            value={(pendingChanges[card.id]?.attestation ?? card.attestation) || ''}
                                            onChange={(e) => handlers.handleAttestationChange(card.id, e.target.value)}
                                            onFocus={(e) => onAttestationFocus(card.id, e.target.selectionStart)}
                                            onBlur={onAttestationBlur}
                                            isRecording={transcribingCardId === card.id}
                                        />
                                        <div className="flex justify-end mt-2">
                                            <button
                                                className={`flex items-center justify-center px-2 py-1 rounded-md ${
                                                    actionSuccess[`copy-${card.id}`] 
                                                        ? 'bg-[#1976d2] bg-opacity-20 border-[#1976d2]' 
                                                        : 'base-button'
                                                } border focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
                                                onClick={() => handlers.handleExportAttestation(card.id, pendingChanges[card.id]?.attestation ?? card.attestation, pendingChanges[card.id]?.seen ?? card.seen, pendingChanges[card.id]?.supervisee ?? card.supervisee)}
                                                title="Export attestation"
                                            >
                                                <span className="material-symbols-outlined text-base">
                                                    {actionSuccess[`copy-${card.id}`] ? 'check_circle' : 'content_copy'}
                                                </span>
                                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1">
                                                    Export
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {cards.length === 0 && (
                    <div className="text-center py-12 text-gray-400">
                        <p>No cards available. Create a new card in the Cards view.</p>
                    </div>
                )}
                <AttestationsSaveButton
                    handleSave={handlers.handleSave}
                    actionSuccess={actionSuccess}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            </div>

            <AudioTranscriptionButton
                onTranscriptionUpdate={(text, shouldAppend) => 
                    handlers.handleTranscriptionUpdateWrapper(activeAttestationId, text, shouldAppend)
                }
                onRecordingStart={() => onTranscriptionStart(activeAttestationId)}
                onRecordingEnd={onTranscriptionEnd}
                isActiveSection={activeAttestationId !== null}
            />
            
            <OPATNoteModal
                isOpen={showOPATModal}
                onClose={() => setShowOPATModal(false)}
                onSubmit={handlers.handleOPATSubmit}
            />
        </>
    );
};

export default AttestationsView; 