const smartLists = {

    interpreterneeded: [
        'The patient is English-speaking, and therefore no interpreter was needed.',
        'An interpreter provided assistance with {languages} translation, as this was the patient\'s identified language. Translation was provided using {visittype} interpretation. Interpreter name: *** (ID #***).',
    ],

    languages: [
        'Cantonese',
        'Mandarin',
        'Vietnamese',
        'Haitian Creole',
        'Portuguese Creole',
        'Portuguese',
        'French',
        '***',
    ],

    ltbitreatmentoptions: [
        'Rifampin for 4 months',
        'Rifampin and Isoniazid + B6 (pyridoxine) for 3 months',
        'Rifapentine and Isoniazid + B6 (pyridoxine) for 3 months',
        'Isoniazid + B6 (pyridoxine) for 6-9*** months',
    ],

    ltbisideeffects: [
        'With respect to rifampin, we discussed hepatotoxicity as a potential side effect, and the need for monitoring of transaminases.\nWe discussed the potential side effect of rifampin of causing discoloration of body fluids such as urine and sweat, but reassured this is temporary and resolves after completion of treatment.\nWe reviewed current medications for interactions with rifampin.\n***',
    ],

    tbtesting: [
        'QuantiFERON',
        'PPD',
    ],

    visittype: [
        'telephonic',
        'video',
        'in-person',
    ],
};

export default smartLists; 