/**
 * Common document templates that can be used as quick chat prompts
 * These are organized by categories for easier filtering
 * 
 * ID naming convention:
 * - IDs should be unique across all documents
 * - Format: category-subcategory-number (e.g., letter-referral-1)
 * - Use kebab-case (lowercase with hyphens)
 * - Avoid special characters other than hyphens
 */

const commonDocuments = [
    {
        id: 'education-plan-1',
        category: 'education',
        label: 'Plan Summary',
        text: 'Please create a detailed educational document about the current assessment and plan for the patient.'
    },
    {
        id: 'letter-referral-1',
        category: 'letter',
        label: 'Referral Response',
        text: 'Please create a referral letter to the provider who referred the patient to me, summarizing my consultation and recommendations.'
    },
    {
        id: 'communications-mychart-reply-1',
        category: 'communications',
        label: 'MyChart Reply',
        text: 'The patient sent me the below MyChart message:\n***\n\nI would like you to respond professionally and compassionately. Help me reword the following draft reply to their message:\n***.'
    },
];

export default commonDocuments; 
