// Organisms
const organismTerms = [
    // Gram-negative bacteria
    'A. baumannii', 'Acinetobacter baumannii',
    'Bartonella',
    'Campylobacter',
    'E. cloacae', 'Enterobacter cloacae', 
    'E. coli', 'Escherichia coli',
    'Gram-negative',
    'H. influenzae', 'Haemophilus influenzae',
    'K. pneumoniae', 'Klebsiella pneumoniae',
    'K. oxytoca', 'Klebsiella oxytoca',
    'Legionella',
    'M. catarrhalis', 'Moraxella catarrhalis',
    'Moraxella',
    'N. gonorrhoeae', 'Neisseria gonorrhoeae',
    'N. meningitidis', 'Neisseria meningitidis',
    'Pasteurella',
    'P. aeruginosa', 'Pseudomonas aeruginosa',
    'Salmonella',
    'S. marcescens', 'Serratia marcescens',
    'Serratia',
    'S. maltophilia', 'Stenotrophomonas maltophilia',
    'Stenotrophomonas',

    // Gram-positive bacteria
    'C. difficile', 'Clostridioides difficile',
    'Cutibacterium acnes', 'C. acnes',
    'E. faecalis', 'Enterococcus faecalis',
    'E. faecium', 'Enterococcus faecium',
    'Eggerthella',
    'Gram-positive',
    'Group B Streptococcus',
    'L. monocytogenes', 'Listeria monocytogenes',
    'MRSA', 'Methicillin-resistant Staphylococcus aureus',
    'MSSA', 'Methicillin-sensitive Staphylococcus aureus',
    'Propionibacterium',
    'S. agalactiae', 'Streptococcus agalactiae',
    'S. aureus', 'Staphylococcus aureus',
    'S. epidermidis', 'Staphylococcus epidermidis',
    'S. haemolyticus', 'Staphylococcus haemolyticus',
    'S. hominis', 'Staphylococcus hominis',
    'S. saprophyticus', 'Staphylococcus saprophyticus',
    'S. pneumoniae', 'Streptococcus pneumoniae',
    'S. pyogenes', 'Streptococcus pyogenes',
    'Viridans group Streptococci',
    'VRE', 'Vancomycin-resistant Enterococcus',

    // Miscellaneou Bacteria
    'Chlamydia', 'CT',
    'Gonorrhea', 'GC',
    'Syphilis',
    'Treponema pallidum',
    'T. pallidum',


    // Fungi
    'A. fumigatus', 'Aspergillus fumigatus',
    'C. albicans', 'Candida albicans',
    'C. auris', 'Candida auris',
    'C. glabrata', 'Candida glabrata',
    'C. neoformans', 'Cryptococcus neoformans',

    // Viruses
    'CMV', 'Cytomegalovirus',
    'COVID-19',
    'EBV', 'Epstein-Barr Virus',
    'HSV', 'Herpes Simplex Virus',
    'Hepatitis A',
    'Hepatitis B',
    'Hepatitis C',
    'HIV',
    'Influenza',
    'RSV', 'Respiratory Syncytial Virus',
    'SARS-CoV-2',
    'VZV', 'Varicella Zoster',

    // Parasites
    'Anaplasma',
    'Babesia',
    'Ehrlichia',
    'Toxoplasma',
];

// Antimicrobials
const antimicrobialTerms = [
    'Acyclovir',
    'Amikacin',
    'Amoxicillin',
    'Amoxicillin-clavulanate',
    'Amphotericin',
    'Amphotericin B',
    'Ampicillin',
    'Ampicillin-sulbactam',
    'Atovaquone',
    'Azithromycin',
    'Caspofungin',
    'Cefadroxil',
    'Cefazolin',
    'Cefepime',
    'Cefiderocol',
    'Cefixime',
    'Cefoxitin',
    'Ceftaroline',
    'Ceftazidime',
    'Ceftazidime-avibactam',
    'Ceftolozane',
    'Ceftolozane-tazobactam',
    'Ceftriaxone',
    'Cefuroxime',
    'Cephalexin',
    'Ciprofloxacin',
    'Clarithromycin',
    'Clindamycin',
    'Cloxacillin',
    'Dalbavancin',
    'Daptomycin',
    'Dicloxacillin',
    'Doxycycline',
    'Ertapenem',
    'Erythromycin',
    'Ethambutol',
    'Fidaxomicin',
    'Fluconazole',
    'Flucytosine',
    'Foscarnet',
    'Fosfomycin',
    'Ganciclovir',
    'Gentamicin',
    'Imipenem',
    'Isavuconazole',
    'Isoconazole',
    'Isoniazid',
    'Itraconazole',
    'Levofloxacin',
    'Linezolid',
    'Meropenem',
    'Metronidazole',
    'Micafungin',
    'Minocycline',
    'Moxifloxacin',
    'Mupirocin',
    'Nafcillin',
    'Nitrofurantoin',
    'Oxacillin',
    'Penicillin',
    'Piperacillin',
    'Piperacillin-tazobactam',
    'Posaconazole',
    'Pyrazinamide',
    'Rezafungin',
    'Rifampin',
    'Sulbactam-durlobactam',
    'Tedizolid',
    'Tetracycline',
    'Tigecycline',
    'Tinidazole',
    'TMP-SMX',
    'Tobramycin',
    'Trimethoprim-sulfamethoxazole',
    'Valacyclovir',
    'Valganciclovir',
    'Vancomycin',
    'Voriconazole'
];

// Antiretroviral Therapy terms
const artTerms = [
    '3TC', 'lamivudine',
    'ABC', 'abacavir',
    'ATV', 'atazanavir',
    'BIC', 'bictegravir',
    'CAB', 'cabotegravir',
    'cobicistat',
    'd4T', 'stavudine',
    'ddI', 'didanosine',
    'DOR', 'doravirine',
    'DRV', 'darunavir',
    'DTG', 'dolutegravir',
    'EFV', 'efavirenz',
    'ETR', 'etravirine',
    'EVG', 'elvitegravir',
    'FPV', 'fosamprenavir',
    'FTC', 'emtricitabine',
    'FTR', 'fostemsavir',
    'IBA', 'ibalizumab',
    'IDV', 'indinavir',
    'LEN', 'lenacapavir',
    'LPV', 'lopinavir',
    'MVC', 'maraviroc',
    'NFV', 'nelfinavir',
    'NVP', 'nevirapine',
    'RAL', 'raltegravir',
    'RPV', 'rilpivirine',
    'ritonavir',
    'SQV', 'saquinavir',
    'T-20', 'enfuvirtide',
    'TAF', 'tenofovir alafenamide',
    'TDF', 'tenofovir disoproxil fumarate',
    'TFV', 'tenofovir',
    'TMR', 'temsavir',
    'TPV', 'tipranavir',
    'ZDV', 'zidovudine',
];

// Helper function to create highlight terms
const createHighlightTerms = (terms, color) => {
    return terms.reduce((acc, term) => {
        acc[term.toLowerCase()] = {
            color: color,
            // No sections specified means it applies to all sections
        };
        return acc;
    }, {});
};

// Base highlight terms
const baseHighlightTerms = {
    'blood culture': {
        color: '#ff9800',  // Orange
        sections: ['labs']
    },

    'positive': {
        color: '#ef5350',  // Red
        sections: ['labs']
    },

    'reactive': {
        color: '#ef5350',  // Red
        sections: ['labs']
    },

    'negative': {
        color: '#9e9e9e',  // Light grey
        sections: ['labs']
    },
    
    'non-reactive': {
        color: '#9e9e9e',  // Light grey
        sections: ['labs']
    },

    '***': {
        color: '#ff69b4', //Pink
    }
};

// Combine all highlight terms
const highlightTerms = {
    ...baseHighlightTerms,
    ...createHighlightTerms(organismTerms, '#e040fb'),
    ...createHighlightTerms(antimicrobialTerms, '#81c784'),
    ...createHighlightTerms(artTerms, '#64b5f6')
};

export default highlightTerms;