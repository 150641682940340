import React, { useState, useRef, useMemo, useEffect } from 'react';
import BaseSidebar from '../BaseSidebar';
import SidebarHeader from './SidebarHeader';
import CardsList from './CardsList';
import apiService from '../../../services/api';
import DeleteAllConfirmation from '../../common/DeleteAllConfirmation';

function CardsSidebar({
    cards,
    selectedCard,
    handleCardSelect,
    handleDeleteCard,
    deleteAllCards,
    reorderCards,
    isPanelCollapsed,
    setIsPanelCollapsed,
}) {
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [draggedCard, setDraggedCard] = useState(null);
    const [draggedOverCard, setDraggedOverCard] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [presetFilter, setPresetFilter] = useState('all');
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);
    
    const cardsListRef = useRef(null);

    useEffect(() => {
        const loadPresetFilter = async () => {
            try {
                const filter = await apiService.getPresetFilter();
                setPresetFilter(filter);
            } catch (error) {
                console.error('Error loading preset filter:', error);
            }
        };
        loadPresetFilter();
    }, []);

    const handleDrop = (e, targetCard) => {
        e.preventDefault();
        const draggedCardId = e.dataTransfer.getData('text');
        const draggedIndex = cards.findIndex(card => card.id.toString() === draggedCardId);
        const targetIndex = cards.findIndex(card => card.id === targetCard.id);

        if (draggedIndex !== targetIndex) {
            const newCards = Array.from(cards);
            const [reorderedItem] = newCards.splice(draggedIndex, 1);
            newCards.splice(targetIndex, 0, reorderedItem);
            reorderCards(newCards);
        }
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handlePresetFilterChange = async (filter) => {
        const previousFilter = presetFilter;
        setPresetFilter(filter);

        try {
            await apiService.savePresetFilter(filter);
        } catch (error) {
            console.error('Error saving preset filter:', error);
            setPresetFilter(previousFilter);
        }
    };

    const filteredCards = cards.filter(card =>
        card.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (presetFilter === 'all' || 
         (presetFilter === 'outpatient' && card.preset === 'Outpatient') ||
         (presetFilter === 'inpatient' && card.preset !== 'Outpatient'))
    );

    const presetCounts = useMemo(() => ({
        all: cards.length,
        outpatient: cards.filter(card => card.preset === 'Outpatient').length,
        inpatient: cards.filter(card => card.preset !== 'Outpatient').length
    }), [cards]);

    const handleDeleteAll = () => {
        setShowDeleteAllConfirmation(true);
    };

    const confirmDeleteAll = async () => {
        try {
            await deleteAllCards();
            setShowDeleteAllConfirmation(false);
        } catch (error) {
            console.error('Failed to delete all cards:', error);
        }
    };

    const cancelDeleteAll = () => {
        setShowDeleteAllConfirmation(false);
    };

    return (
        <>
            <BaseSidebar
                isPanelCollapsed={isPanelCollapsed}
                setIsPanelCollapsed={setIsPanelCollapsed}
                isSearchFocused={isSearchFocused}
                title="Saved Cards"
            >
                <SidebarHeader
                    isPanelCollapsed={isPanelCollapsed}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    isSearchFocused={isSearchFocused}
                    setIsSearchFocused={setIsSearchFocused}
                    handleClearSearch={handleClearSearch}
                    presetFilter={presetFilter}
                    presetCounts={presetCounts}
                    handlePresetFilterChange={handlePresetFilterChange}
                />

                <div ref={cardsListRef} className="flex-1 overflow-y-auto -mx-4 mt-4">
                    <CardsList
                        cards={filteredCards}
                        selectedCard={selectedCard}
                        handleCardSelect={handleCardSelect}
                        handleDeleteCard={handleDeleteCard}
                        draggedCard={draggedCard}
                        setDraggedCard={setDraggedCard}
                        draggedOverCard={draggedOverCard}
                        setDraggedOverCard={setDraggedOverCard}
                        handleDrop={handleDrop}
                        deleteConfirmation={deleteConfirmation}
                        setDeleteConfirmation={setDeleteConfirmation}
                        isPanelCollapsed={isPanelCollapsed}
                    />
                </div>

                {!isPanelCollapsed && (
                    <div className="-mx-4 px-4 mt-auto pt-2">
                        <button
                            onClick={handleDeleteAll}
                            className="w-full flex items-center justify-center p-2 alert-button rounded transition duration-150 ease-in-out shadow-md text-white flash-button"
                        >
                            <span className="material-icons mr-2">delete_forever</span>
                            Delete All
                        </button>
                    </div>
                )}
            </BaseSidebar>

            {showDeleteAllConfirmation && (
                <DeleteAllConfirmation
                    onConfirm={confirmDeleteAll}
                    onCancel={cancelDeleteAll}
                    itemType="cards"
                />
            )}
        </>
    );
}

export default CardsSidebar; 