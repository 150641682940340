import React, { useState } from 'react';

const CalculatorList = ({ calculators, onSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredCalculators = calculators.filter(calc => {
        const searchTerms = searchTerm.toLowerCase().split(/\s+/).filter(term => term.length > 0);
        const calcName = calc.name.toLowerCase();
        const calcDescription = calc.description.toLowerCase();
        
        return searchTerms.every(term => {
            // Match word starts
            const nameWords = calcName.split(/\s+/);
            const descWords = calcDescription.split(/\s+/);
            const startsWithMatch = nameWords.some(word => word.startsWith(term)) || 
                                  descWords.some(word => word.startsWith(term));
            
            // Match terms in brackets and parentheses
            const getBracketedContent = (text) => {
                const squareBrackets = text.match(/\[([^\]]+)\]/g) || [];
                const parentheses = text.match(/\(([^)]+)\)/g) || [];
                return [...squareBrackets, ...parentheses];
            };

            const nameBrackets = getBracketedContent(calcName);
            const descBrackets = getBracketedContent(calcDescription);
            const bracketMatch = [...nameBrackets, ...descBrackets].some(bracket => 
                bracket.toLowerCase().includes(term)
            );
            
            return startsWithMatch || bracketMatch;
        });
    });

    return (
        <div className="w-full">
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Search calculators and tools..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 rounded-md input-base"
                />
            </div>
            <div className="grid gap-3">
                {filteredCalculators.map((calculator) => (
                    <button
                        key={calculator.id}
                        onClick={() => onSelect(calculator)}
                        className="text-left p-4 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#404040] transition-colors"
                    >
                        <h3 className="text-lg font-semibold text-gray-100 mb-1">{calculator.name}</h3>
                        <p className="text-gray-400 text-sm">{calculator.description}</p>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default CalculatorList; 