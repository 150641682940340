import EOTCalculator from './ClinicalCalculators/EOTCalculator';
import DateDiffCalculator from './ClinicalCalculators/DateDiffCalculator';
import FIB4Calculator from './ClinicalCalculators/FIB4Calculator';
import ANCCalculator from './ClinicalCalculators/ANCCalculator';
import InsulinFillsCalculator from './ClinicalCalculators/InsulinFillsCalculator';

export const calculatorRegistry = [
    {
        id: 'eot',
        name: 'End of Therapy Calculator',
        description: 'Calculate EOT (end of therapy) date based on starting date and treatment duration.',
        component: EOTCalculator
    },
    {
        id: 'datediff',
        name: 'Date Difference Calculator',
        description: 'Calculate the difference between two dates in days, weeks, and months.',
        component: DateDiffCalculator
    },
    {
        id: 'fib4',
        name: 'Fibrosis-4 (FIB-4) Index for Liver Fibrosis',
        description: 'Calculate the FIB-4 score to estimate the risk of advanced fibrosis.',
        component: FIB4Calculator
    },
    {
        id: 'anc',
        name: 'Absolute Neutrophil Count (ANC) Calculator',
        description: 'Calculate the Absolute Neutrophil Count.',
        component: ANCCalculator
    },
    {
        id: 'insulin-fills',
        name: 'Insulin Fills Calculator',
        description: 'Calculate insulin volume needed for 30-day and 90-day supplies based on strength and daily units.',
        component: InsulinFillsCalculator
    }
];

export const getCalculatorById = (id) => {
    return calculatorRegistry.find(calc => calc.id === id);
}; 