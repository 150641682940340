import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../../theme/muiDarkTheme';
import { differenceInDays, differenceInWeeks, differenceInMonths } from 'date-fns';

const DateDiffCalculator = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [result, setResult] = useState(null);

    const calculateDifference = () => {
        if (!startDate || !endDate) return;

        const diffDays = differenceInDays(endDate, startDate);
        const diffWeeks = differenceInWeeks(endDate, startDate);
        const diffMonths = differenceInMonths(endDate, startDate);

        setResult({
            days: diffDays,
            weeks: diffWeeks,
            months: diffMonths
        });
    };

    const copyToClipboard = (value) => {
        if (!value) return;
        navigator.clipboard.writeText(value.toString());
    };

    return (
        <div className="p-4">
            <div className="space-y-3">
                <div className="flex flex-col md:flex-row md:items-end md:space-x-2 space-y-3 md:space-y-0">
                    <div>
                        <label className="block text-gray-300 text-sm mb-1">Start Date</label>
                        <ThemeProvider theme={darkTheme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    size="small"
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </div>

                    <div>
                        <label className="block text-gray-300 text-sm mb-1">End Date</label>
                        <ThemeProvider theme={darkTheme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    size="small"
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </div>

                    <button
                        onClick={calculateDifference}
                        className="px-6 py-2 primary-button text-white rounded-md flash-button w-full md:w-auto"
                    >
                        Calculate
                    </button>
                </div>

                {result && (
                    <div className="mt-8 space-y-2">
                        <div className="p-3 base-surface rounded-md">
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-gray-400 text-sm">Days:</span>
                                    <span className="text-gray-100 ml-2">{result.days}</span>
                                </div>
                                <button
                                    onClick={() => copyToClipboard(result.days)}
                                    className="px-3 py-1 base-button flash-button rounded-md text-sm"
                                >
                                    <span className="material-symbols-outlined text-white text-sm mr-1">
                                        file_copy
                                    </span>
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="p-3 base-surface rounded-md">
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-gray-400 text-sm">Weeks:</span>
                                    <span className="text-gray-100 ml-2">{result.weeks}</span>
                                </div>
                                <button
                                    onClick={() => copyToClipboard(result.weeks)}
                                    className="px-3 py-1 base-button flash-button rounded-md text-sm"
                                >
                                    <span className="material-symbols-outlined text-white text-sm mr-1">
                                        file_copy
                                    </span>
                                    Copy
                                </button>
                            </div>
                        </div>
                        <div className="p-3 base-surface rounded-md">
                            <div className="flex items-center justify-between">
                                <div>
                                    <span className="text-gray-400 text-sm">Months:</span>
                                    <span className="text-gray-100 ml-2">{result.months}</span>
                                </div>
                                <button
                                    onClick={() => copyToClipboard(result.months)}
                                    className="px-3 py-1 base-button flash-button rounded-md text-sm"
                                >
                                    <span className="material-symbols-outlined text-white text-sm mr-1">
                                        file_copy
                                    </span>
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DateDiffCalculator; 