const dotPhrases = {

    //Physical examination Dotphrases
    ".nexabdomen": "Abdomen: Bowel sounds present. Soft, non-tender, non-distended.",
    ".nexcvs": "CVS: Normal S1 and S2. No murmurs, rubs or gallops appreciated on auscultation.",
    ".nexchest": "Chest: Lungs clear to auscultation bilaterally.",
    ".nexextr": "Extremities: No edema noted to lower extremities.",
    ".nexgen": "General: Appears their stated age, in no acute distress.",
    ".nexheent": "HEENT: No conjunctival injection. No pharyngeal erythema.",
    ".nexlymph": "Lymphatic: No cervical lymphadenopathy. No axillary lymphadenopathy. No inguinal lymphadenopathy.***",
    ".nexneuro": "Neurologic: CN II-XII grossly intact. 5/5 strength to upper and lower extremities bilaterally. No dysmetria. No dysdiadochokinesia.",
    ".nextele": "Physical examination unable to be performed due to the nature of this visit being a televideo visit. However, over video, the patient does not appear to be in any acute distress, and is alert and communicative.",

    //Medication name Dotphrases
    ".cosentyx": "Secukinumab",
    ".enbrel": "Etanercept",
    ".humira": "Adalimumab",
    ".keytruda": "Pembrolizumab",
    ".paxlovid": "Nirmatrelvir-Ritonavir",
    ".remicade": "Infliximab",
    ".rinvoq": "Upadacitinib",
    ".simponi": "Golimumab",
    ".stelara": "Ustekinumab",
    ".skyrizi": "Risankizumab",
    ".taltz": "Ixekizumab",
    ".tremfya": "Guselkumab",
    ".xeljanz": "Tofacitinib",
    ".xolair": "Omalizumab",

    //General Dotphrases
    ".signoff": "Infectious Diseases to sign off at this time, however, please do not hesitate to reach out for any questions, concerns or changes in clinical status.",

    //Assessment and Plan Dotphrases
    ".appep": "Post-Exposure Testing Plan:\n[ ] 2 weeks post-exposure: Safety Labs: CBC, Hepatic Function Panel, BUN/Cr if on medications\n[ ] 4-6 weeks: HIV ag/ab, HCV ab. Syphilis testing if exposure was sexual. If high risk for HCV, can add ALT and HCV RNA.\n[ ] 3-4 months: HIV ag/ab, HCV ab. Syphilis testing if exposure was sexual. If high risk for HCV, can add ALT and HCV RNA.\n[ ] 6 months: HCV testing can be extended if high risk exposure. If high risk hepatitis B exposure, can add HBV surface antigen, core antibody.\n[ ] 12 months: HIV testing if patient had been found to contract HCV",

    ".apdoxypep": "Patient expressed interest in Doxy-PEP (doxycycline post-exposure prophylaxis) as a preventive measure against bacterial sexually transmitted infections.\nWe discussed the appropriate use of this medication as a post-exposure intervention rather than a daily preventive regimen.\nI counseled the patient in detail about the dosing of Doxy-PEP, specifically 200 mg of doxycycline to be taken as a single dose after sexual exposure. I emphasized that the medication should be taken as soon as possible, ideally within 24 hours, but can be pushed to 72 hours of sexual contact to maximize effectiveness.\n\nWe thoroughly reviewed important dietary restrictions and medication interactions.\nI cautioned the patient to avoid taking doxycycline with dairy products, calcium-fortified juices, calcium supplements, antacids, iron products, or multivitamins containing minerals, as these can significantly reduce the medication's effectiveness.\nI advised spacing these products at least 2 hours before or after taking doxycycline.\nI instructed the patient to take the medication with a full glass of water and to remain upright for at least 30 minutes afterward.\n\nCommon side effects were reviewed, including photosensitivity reactions and gastrointestinal disturbances. I recommended sun protection while taking this medication.\n\nI emphasized to the patient that Doxy-PEP does not protect against HIV, viral hepatitis, or other viral STIs, and advised continued use of condoms and other safe sex practices. Discussed that Doxy-PEP should be considered an additional tool in STI prevention rather than a replacement for other preventive measures.",

    ".apltbi": "Patient tested positive on {tbtesting} testing on ***.\nFollow up chest X-ray was performed *** which was negative for findings concerning for active pulmonary tuberculosis. Symptom screen was also not consistent with active tuberculosis.\nBased on these findings the patient has been diagnosed with latent tuberculosis.\nWe spent time discussing the diagnosis of latent tuberculosis, including how it varies from active tuberculosis.\nWe discussed that though latent tuberculosis is non-transmissible, there is a 5-10% conversion rate over one's lifetime to active tuberculosis (which decreases over time from time of initial infection).\nWe discussed common signs and symptoms of active tuberculosis.\n\nWe discussed potential treatment options and discussed potential side effects. After some discussion, we mutually identified {ltbitreatmentoptions} as optimal treatment option.\n{ltbisideeffects}",

    ".apondemandprep": "Patient expressed interest in on-demand PrEP (pre-exposure prophylaxis) as a strategy for HIV prevention. We discussed the '2-1-1' dosing regimen as an alternative to daily PrEP for men who have sex with men (MSM) who may benefit from an event-driven approach.\n\nI counseled the patient in detail about the specific dosing schedule for on-demand PrEP, which consists of taking two tablets of TDF-FTC (Truvada) 2-24 hours before anticipated sexual activity, followed by one tablet 24 hours after the first dose, and one final tablet 24 hours after the second dose. I emphasized the importance of adhering to this precise timing for maximum protection.\n\nI cautioned the patient about taking on-demand PrEP with nephrotoxic medications and advised informing all healthcare providers about this medication. We discussed the importance of not missing any doses in the 2-1-1 regimen, as adherence is critical for effectiveness.\n\nThe patient was advised that on-demand PrEP offers protection specifically against HIV and not other sexually transmitted infections. I emphasized the importance of continued condom use and regular STI testing every 3-6 months while using on-demand PrEP.\n\nI instructed the patient to follow up every 3 months for HIV testing and medication review, and to have kidney function and other routine labs checked every 6 months.",

    ".apopat": "#Long term use of antimicrobials\nThis condition requires comprehensive monitoring across multiple domains in the setting of long-term intravenous antimicrobial therapy. Close surveillance is needed for potential antimicrobial toxicity. Additionally, monitoring is essential for any complications that may arise from the indwelling catheter. Furthermore, careful attention must be paid to potential complications associated with the underlying infectious disease, as it carries a significant risk of morbidity.",
};


//Combined physical examination Dotphrase
dotPhrases[".nexphysical"] = [
    dotPhrases[".nexgen"],
    dotPhrases[".nexheent"],
    dotPhrases[".nexcvs"],
    dotPhrases[".nexchest"],
    dotPhrases[".nexabdomen"],
    dotPhrases[".nexextr"],
].join("\n");

export default dotPhrases; 