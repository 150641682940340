import React from 'react';
import NoteEditor from '../NoteEditor';
import Sidebar from '../Sidebar';
import DeleteAllConfirmation from '../common/DeleteAllConfirmation';
import { useNotes } from '../../hooks/useNotes';
import { exportSignout } from '../utils/docxExportUtils';

export const presetSections = {
    'ID Consult': ['consultInfo', 'hpi', 'medicalHistory', 'socialHistory', 'antimicrobials', 'physicalExam', 'labs', 'imaging', 'recommendations'],
    'ID Follow Up': ['hpi', 'antimicrobials', 'physicalExam', 'labs', 'imaging', 'recommendations'],
    'Outpatient': ['hpi', 'physicalExam', 'recommendations']
};

const NotesMode = ({ collapseHeader, authToken }) => {
    const [isPanelCollapsed, setIsPanelCollapsed] = React.useState(true);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = React.useState(false);

    const {
        notes,
        currentNote,
        isLoading,
        isEditing,
        error,
        fetchNotes,
        selectNote,
        createNewNote,
        newNoteCreated,
        updateCurrentNoteLocally,
        saveCurrentNote,
        changedFields,
        setChangedFields,
        deleteNote,
        deleteAllNotes,
        summarizeNote,
        reorderNotes,

    } = useNotes();

    const handleCreateNewNote = async () => {
        const newNote = await createNewNote();
        if (newNote) {
            selectNote(newNote);
        }
    };

    const handleUpdateNote = (field, value) => {
        updateCurrentNoteLocally(field, value);
    };

    const handleSaveNote = async () => {
        const success = await saveCurrentNote();
        return success;
    };

    const handleDeleteNote = async (noteId) => {
        await deleteNote(noteId);
    };

    const handleDeleteAll = async () => {
        await deleteAllNotes();
        setShowDeleteAllConfirmation(false);
    };

    const handleSignout = () => {
        exportSignout(notes);
    };

    const handleBulkNoteCreation = async (noteNames) => {
        for (const title of noteNames) {
            await createNewNote(title);
        }
    };

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-screen app-bg text-white">
                <p className="text-red-500 mb-4">{error}</p>
                <button
                    onClick={fetchNotes}
                    className="primary-button text-white px-4 py-2 rounded flash-button"
                >
                    Retry
                </button>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen app-bg text-white font-sans">
                <p className="mb-4 text-xl sm:text-2xl font-bold overflow-visible">
                    Loading notes
                    <span className="animate-ellipsis inline-block overflow-visible">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </p>
                <p className="text-sm text-[#ababab]">This may take a few moments</p>
            </div>
        );
    }

    return (
        <div className="notes-mode">
            <Sidebar
                isPanelCollapsed={isPanelCollapsed}
                setIsPanelCollapsed={setIsPanelCollapsed}
                notes={notes}
                selectedNote={currentNote}
                handleNewNote={handleCreateNewNote}
                handleNoteSelect={selectNote}
                handleDeleteNote={handleDeleteNote}
                setShowDeleteAllConfirmation={setShowDeleteAllConfirmation}
                reorderNotes={reorderNotes}
                newNoteCreated={newNoteCreated}
                handleSignout={handleSignout}
                handleBulkNoteCreation={handleBulkNoteCreation}
            />
            <div className="transition-all duration-300 ml-14 sm:ml-16">
                <main className="p-4">
                    {currentNote ? (
                        <NoteEditor
                            note={currentNote}
                            updateNote={handleUpdateNote}
                            saveNote={handleSaveNote}
                            isEditing={isEditing}
                            handleNewNote={handleCreateNewNote}
                            summarizeNote={summarizeNote}
                            error={error}
                            changedFields={changedFields}
                            setChangedFields={setChangedFields}
                            authToken={authToken}
                            currentPreset={currentNote.preset || 'ID Consult'}
                            presetSections={presetSections}
                            collapseHeader={collapseHeader}
                            updateCurrentNoteLocally={updateCurrentNoteLocally}
                        />
                    ) : (
                        <div className="flex items-center justify-center h-[calc(100vh-8rem)] pt-16">
                            <p className="text-[#ababab]">Create a new note from the sidebar.</p>
                        </div>
                    )}
                </main>
            </div>
            {showDeleteAllConfirmation && (
                <DeleteAllConfirmation
                    onConfirm={handleDeleteAll}
                    onCancel={() => setShowDeleteAllConfirmation(false)}
                />
            )}
        </div>
    );
};

export default NotesMode; 