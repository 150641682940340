import { useCallback } from 'react';

export const useTabNavigation = (section, presetSections, currentPreset, allRefs, collapsedSections = {}, pinnedSection) => {
    return useCallback((e) => {
        // Early return if section is null (in other words, not currently in the note editor component)
        if (!section) {
            return;
        }
        
        if (e.key === 'Tab') {
            e.preventDefault();
            
            let nextRefKey;
            
            if (section === 'title') {
                // Find the first non-collapsed section
                const currentSections = presetSections[currentPreset];
                let nextSectionIndex = 0;
                let nextSection = null;
                
                // Keep looking for a non-collapsed section until we find one or reach the end
                while (nextSectionIndex < currentSections.length) {
                    const candidateSection = currentSections[nextSectionIndex];
                    // Use optional chaining to safely check collapsedSections
                    if (!collapsedSections?.[candidateSection] || pinnedSection === candidateSection) {
                        nextSection = candidateSection;
                        break;
                    }
                    nextSectionIndex++;
                }

                // If we found a non-collapsed section, use it. Otherwise stay on title
                nextRefKey = nextSection ? `${nextSection}Ref` : 'titleRef';
            } else {
                // For regular sections
                const currentSections = presetSections[currentPreset];
                const currentIndex = currentSections.indexOf(section);
                
                // Find the next non-collapsed section by checking each section after the current one
                let nextSectionIndex = currentIndex + 1;
                let nextSection = null;
                
                // Keep looking for a non-collapsed section until we find one or reach the end
                while (nextSectionIndex < currentSections.length) {
                    const candidateSection = currentSections[nextSectionIndex];
                    // Use optional chaining to safely check collapsedSections
                    if (!collapsedSections?.[candidateSection] || pinnedSection === candidateSection) {
                        nextSection = candidateSection;
                        break;
                    }
                    nextSectionIndex++;
                }

                // If we found a next non-collapsed section, use it. Otherwise go to title
                nextRefKey = nextSection ? `${nextSection}Ref` : 'titleRef';
            }
            
            // Focus the next section's textarea if ref exists
            if (allRefs && allRefs[nextRefKey]?.current) {
                allRefs[nextRefKey].current.focus();
                // Also scroll the element into view if needed
                allRefs[nextRefKey].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [section, presetSections, currentPreset, allRefs, collapsedSections, pinnedSection]);
}; 