import { formatAttestation } from '../../utils/attestationFormatUtils';
import { snackbarMessage, snackbarError } from '../../common/Snackbar';
import { handleOPATSubmit as handleCardOPATSubmit } from '../CardItem';

export const createAttestationHandlers = ({
    cards,
    pendingChanges,
    setPendingChanges,
    setHasUnsavedChanges,
    setActionSuccess,
    setPendingCursorUpdate,
    onUpdateCard,
    onTranscriptionUpdate,
    createNewNote,
    setShowOPATModal,
    setCurrentOPATCardId,
    setEditingCardId,
    setIsSavingCard,
    setDeletingCardId,
    setIsCreatingNote,
    currentOPATCardId,
    isCreatingNote
}) => {
    const handleTranscriptionUpdateWrapper = async (cardId, text, shouldAppend) => {
        const result = await onTranscriptionUpdate(cardId, text, shouldAppend);
        if (result) {
            setPendingChanges(prev => ({
                ...prev,
                [cardId]: {
                    ...prev[cardId],
                    attestation: result.newAttestation
                }
            }));
            setHasUnsavedChanges(true);
            
            setPendingCursorUpdate({
                cardId,
                position: result.newCursorPosition
            });
        }
    };

    const handleAttestationChange = (cardId, attestation) => {
        setPendingChanges(prev => ({
            ...prev,
            [cardId]: {
                ...prev[cardId],
                attestation
            }
        }));
        setHasUnsavedChanges(true);
    };

    const handleSuperviseeChange = (cardId, supervisee) => {
        setPendingChanges(prev => ({
            ...prev,
            [cardId]: {
                ...prev[cardId],
                supervisee
            }
        }));
        setHasUnsavedChanges(true);
    };

    const handleSeenChange = (cardId, seen) => {
        setPendingChanges(prev => ({
            ...prev,
            [cardId]: {
                ...prev[cardId],
                seen
            }
        }));
        setHasUnsavedChanges(true);
    };

    const handleModalityChange = (cardId, modality) => {
        setPendingChanges(prev => ({
            ...prev,
            [cardId]: {
                ...prev[cardId],
                modality
            }
        }));
        setHasUnsavedChanges(true);
    };

    const handleExportAttestation = (cardId, attestation, seen, supervisee) => {
        const formattedAttestation = formatAttestation(attestation, seen, supervisee);
        navigator.clipboard.writeText(formattedAttestation || '');
        setActionSuccess(prev => ({ ...prev, [`copy-${cardId}`]: true }));
        setTimeout(() => {
            setActionSuccess(prev => ({ ...prev, [`copy-${cardId}`]: false }));
        }, 2000);
    };

    const handleSave = async () => {
        if (!Object.keys(pendingChanges).length) return;

        setActionSuccess(prev => ({ ...prev, save: 'saving' }));
        try {
            const updatePromises = Object.entries(pendingChanges).map(([cardId, changes]) => {
                const card = cards.find(c => c.id === parseInt(cardId));
                if (card) {
                    const updatedCard = {
                        ...card,
                        ...changes
                    };
                    return onUpdateCard(updatedCard);
                }
                return null;
            }).filter(Boolean);

            await Promise.all(updatePromises);
            
            setPendingChanges({});
            setHasUnsavedChanges(false);
            setActionSuccess(prev => ({ ...prev, save: true }));
            
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, save: null }));
            }, 2000);
        } catch (error) {
            console.error('Error saving attestations:', error);
            setActionSuccess(prev => ({ ...prev, save: 'error' }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, save: null }));
            }, 2000);
        }
    };

    const handleDeleteClick = (cardId) => {
        setDeletingCardId(cardId);
    };

    const handleCardEditStart = (cardId) => {
        setEditingCardId(cardId);
    };

    const handleCardEditCancel = () => {
        setEditingCardId(null);
    };

    const handleCardSave = async (updatedCard) => {
        setIsSavingCard(true);
        try {
            await onUpdateCard(updatedCard);
            setEditingCardId(null);
        } catch (error) {
            console.error('Error saving card:', error);
        } finally {
            setIsSavingCard(false);
        }
    };

    const handleParseOPAT = (cardId) => {
        setCurrentOPATCardId(cardId);
        setShowOPATModal(true);
    };
    
    const handleOPATSubmit = (parsedData) => {
        if (!currentOPATCardId) return;
        
        const card = cards.find(c => c.id === currentOPATCardId);
        if (!card) return;
        
        handleCardOPATSubmit(card, parsedData, onUpdateCard);
        
        setShowOPATModal(false);
        setCurrentOPATCardId(null);
    };

    const handleCreateNote = async (card) => {
        if (isCreatingNote) return;
        
        setIsCreatingNote(card.id);
        try {
            const noteData = {
                title: card.title || 'Untitled Note',
                consultInfo: card.consultInfo || '',
                background: card.background || '',
                labs: card.labs || '',
                imaging: card.imaging || '',
                antimicrobials: card.antimicrobials || '',
                recommendations: card.recommendations || '',
            };
            
            const newNote = await createNewNote(noteData.title, noteData);
            
            if (newNote) {
                snackbarMessage('Note created successfully from card');
            } else {
                snackbarError('Failed to create note from card');
            }
        } catch (error) {
            console.error('Failed to create note from card:', error);
            snackbarError('Failed to create note: ' + (error.message || 'Unknown error'));
        } finally {
            setIsCreatingNote(null);
        }
    };

    return {
        handleTranscriptionUpdateWrapper,
        handleAttestationChange,
        handleSuperviseeChange,
        handleSeenChange,
        handleModalityChange,
        handleExportAttestation,
        handleSave,
        handleDeleteClick,
        handleCardEditStart,
        handleCardEditCancel,
        handleCardSave,
        handleParseOPAT,
        handleOPATSubmit,
        handleCreateNote
    };
}; 