import React from 'react';

const CardItemActionButtons = ({ 
    isEditing, 
    isDeleting, 
    isSaving,
    isCreatingNote,
    onEdit, 
    onSave, 
    onCancel, 
    onDelete, 
    onDeleteConfirm, 
    onDeleteCancel,
    onCreateNote,
    onParseOPAT,
}) => {
    // Delete confirmation mode
    if (isDeleting) {
        return (
            <div className="absolute top-2 right-2 flex space-x-3">
                <button
                    onClick={onDeleteConfirm}
                    className="text-white hover:text-red-300 transition duration-150 ease-in-out"
                    title="Confirm delete"
                >
                    <span className="material-icons text-base">check</span>
                </button>
                <button
                    onClick={onDeleteCancel}
                    className="text-white hover:text-[#e0e0e0] transition duration-150 ease-in-out"
                    title="Cancel delete"
                >
                    <span className="material-icons text-base">close</span>
                </button>
            </div>
        );
    }

    // Edit mode
    if (isEditing) {
        return (
            <div className="absolute top-2 right-2 flex space-x-3">
                <button
                    onClick={onSave}
                    disabled={isSaving}
                    className={`save-button text-white hover:text-green-300 transition duration-150 ease-in-out ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
                    title="Save changes"
                >
                    <span className="material-icons text-base">{isSaving ? 'hourglass_empty' : 'save'}</span>
                </button>
                <button
                    onClick={onCancel}
                    className="text-white hover:text-red-300 transition duration-150 ease-in-out"
                    title="Cancel editing"
                    disabled={isSaving}
                >
                    <span className="material-icons text-base">close</span>
                </button>
            </div>
        );
    }

    // View mode (default)
    return (
        <div className="absolute top-2 right-2 flex space-x-3">
            <button
                onClick={onParseOPAT}
                className="text-white hover:text-blue-300 transition duration-150 ease-in-out"
                title="Parse OPAT note"
            >
                <span className="material-symbols-outlined text-base">auto_fix_high</span>
            </button>
                <button
                    onClick={onCreateNote}
                    className={`text-white hover:text-green-300 transition duration-150 ease-in-out ${isCreatingNote ? 'opacity-50' : ''}`}
                    title="Create note from card"
                    disabled={isCreatingNote}
                >
                    <span className="material-symbols-outlined text-base">
                        {isCreatingNote ? 'hourglass_empty' : 'convert_to_text'}
                    </span>
                </button>
            <button
                onClick={onEdit}
                className="text-white hover:text-blue-300 transition duration-150 ease-in-out"
                title="Edit card"
            >
                <span className="material-icons text-base">edit</span>
            </button>
            <button 
                onClick={onDelete}
                className="text-white hover:text-red-300 transition duration-150 ease-in-out"
                title="Delete card"
            >
                <span className="material-symbols-outlined text-base">delete</span>
            </button>
        </div>
    );
};

export default CardItemActionButtons; 