import { Document, Packer, Paragraph, TextRun } from 'docx';
import { formatNoteForExport } from './noteFormatUtils';
import { formatAttestation } from './attestationFormatUtils';

const extractNameAndMRN = (title) => {
    const parts = title.split(/\s+/);
    let name = [];
    let mrn = null;

    for (const part of parts) {
        if (/^\d+$/.test(part) && !mrn) {
            mrn = part;
        } else {
            name.push(part);
        }
    }

    return {
        name: name.join(' '),
        mrn: mrn
    };
};

const extractAntimicrobials = (antimicrobialText) => {
    const lines = antimicrobialText.split('\n');
    let currentAntimicrobials = [];
    let priorAntimicrobials = [];
    let isPrior = false;

    for (const line of lines) {
        if (line.toLowerCase().includes('prior:')) {
            isPrior = true;
            continue;
        }

        const trimmedLine = line.trim();
        if (trimmedLine && !trimmedLine.startsWith('Antimicrobials:')) {
            if (isPrior) {
                priorAntimicrobials.push(trimmedLine);
            } else {
                currentAntimicrobials.push(trimmedLine);
            }
        }
    }

    return {
        current: currentAntimicrobials,
        prior: priorAntimicrobials
    };
};

const extractPatientInfo = (recommendationsText, antimicrobialsText) => {
    const lines = recommendationsText.split('\n');
    let summary = '';
    let foundSummary = false;

    for (const line of lines) {
        const ageMatch = line.match(/\d+-year-old/);
        if (ageMatch) {
            foundSummary = true;
            const startIndex = line.indexOf(ageMatch[0]);
            summary += line.slice(startIndex).trim() + ' ';
        } else if (foundSummary && !line.trim().startsWith('#') && !line.toLowerCase().includes("recommendations:")) {
            summary += line.trim() + ' ';
        } else if (foundSummary && (line.trim().startsWith('#') || line.toLowerCase().includes("recommendations:"))) {
            break;
        }
    }

    const antimicrobials = extractAntimicrobials(antimicrobialsText);

    return {
        summary: summary.trim() || "No summary available",
        antimicrobials
    };
};

const formatTodos = (todos) => {
    if (!todos || todos.length === 0) {
        return [];
    }

    return [
        new Paragraph({
            children: [new TextRun({ text: "Todos:", bold: true })],
        }),
        ...todos.map(todo => new Paragraph({
            children: [new TextRun({ text: `[  ] ${todo.text}` })],
        })),
    ];
};

export const createDocxFromNote = (note, currentPreset, presetSections, settings) => {
    const formattedSections = formatNoteForExport(note, currentPreset, presetSections, 'docx', settings);
    const { name, mrn } = extractNameAndMRN(note.title || "Untitled Note");

    const doc = new Document({
        styles: {
            default: {
                document: {
                    run: {
                        font: 'Calibri',
                        size: 22, // 11 points * 2
                    },
                },
            },
        },
        sections: [{
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun({ text: "Name:", bold: true, underline: true }),
                        new TextRun({ text: " " + name + "   " }),
                        new TextRun({ text: "MRN:", bold: true, underline: true }),
                        new TextRun({ text: " " + mrn || "N/A" }),
                    ],
                }),
                new Paragraph({}),  // Empty paragraph for spacing
                ...formattedSections.flatMap(({ section, content }) => [
                    ...content.map(line => {
                        if (typeof line === 'string') {
                            return new Paragraph({
                                children: [new TextRun({ text: line })]
                            });
                        } else if (typeof line === 'object' && 'text' in line) {
                            if (line.text.trim() === '') {
                                return new Paragraph({}); // Empty paragraph for blank lines
                            }
                            if (section === 'heading') {
                                return new Paragraph({
                                    children: [new TextRun({ text: line.text, bold: true, underline: true })]
                                });
                            }
                            return new Paragraph({
                                children: [
                                    new TextRun({ text: line.heading || '', bold: true }),
                                    new TextRun({ text: line.text.slice((line.heading || '').length), bold: line.bold || false })
                                ]
                            });
                        } else {
                            console.warn('Unexpected line format:', line);
                            return new Paragraph({}); // Empty paragraph for unexpected formats
                        }
                    }),
                    new Paragraph({}),  // Empty paragraph for spacing between sections
                ]),
            ],
        }],
    });

    return Packer.toBlob(doc);
};

export const downloadDocx = async (blob, fileName) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

//For creating signouts to your colleagues
export const exportSignout = async (notes) => {
    try {
        const relevantNotes = notes.filter(note =>
            note.preset === 'ID Follow Up' || note.preset === 'ID Consult'
        );

        const patientCount = relevantNotes.length;

        const doc = new Document({
            styles: {
                default: {
                    document: {
                        run: {
                            font: 'Calibri',
                            size: 22, // 11 points * 2
                        },
                    },
                },
            },
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({ text: 'Number of Patients Being Signed Out:', bold: true }),
                            new TextRun({ text: " " + patientCount }),
                        ],
                    }),
                    new Paragraph({}), // Empty paragraph for spacing
                    ...(await Promise.all(relevantNotes.map(async note => {
                        const { name, mrn } = extractNameAndMRN(note.title || "Untitled Note");
                        const { summary, antimicrobials } = extractPatientInfo(note.recommendations || "", note.antimicrobials || "None");
                        const todos = note.todos

                        return [
                            new Paragraph({
                                children: [
                                    new TextRun({ text: "Name:", bold: true, underline: true }),
                                    new TextRun({ text: " " + name + "      " }),
                                    new TextRun({ text: "MRN:", bold: true, underline: true }),
                                    new TextRun({ text: " " + (mrn || "N/A") }),
                                ],
                            }),
                            new Paragraph({
                                children: [new TextRun({ text: summary })],
                            }),
                            new Paragraph({
                                children: [new TextRun({ text: "Antimicrobial History:", bold: true })],
                            }),
                            ...antimicrobials.current.map(ab => new Paragraph({
                                children: [new TextRun({ text: ab })]
                            })),
                            ...antimicrobials.prior.map(ab => new Paragraph({
                                children: [new TextRun({ text: ab, italics: true })]
                            })),
                            ...formatTodos(todos),
                            new Paragraph({
                                children: [new TextRun({ text: "Please see:", bold: true })],
                            }),
                            new Paragraph({}),
                            new Paragraph({}), // Empty paragraphs for spacing
                        ];
                    }))).flat(),
                ],
            }],
        });

        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
        }).replace(/\//g, '_');

        const blob = await Packer.toBlob(doc);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `signout_${formattedDate}.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error generating signout document:", error);
    }
};

export const createDocxFromDocument = async (documentData) => {
    const doc = new Document({
        styles: {
            default: {
                document: {
                    run: {
                        font: 'Calibri',
                        size: 22, // 11 points * 2
                    },
                },
            },
        },
        sections: [{
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun({ 
                            text: documentData.title || 'Untitled Document',
                            bold: true,
                            size: 28 // 14 points * 2
                        })
                    ]
                }),
                new Paragraph({}), // Empty paragraph for spacing
                new Paragraph({
                    children: [
                        new TextRun({ text: documentData.content || '' })
                    ]
                })
            ]
        }]
    });

    return Packer.toBlob(doc);
};

export const exportDocument = async (documentData, currentPreset) => {
    const blob = await createDocxFromDocument(documentData);
    
    // Create the file name
    const presetName = currentPreset.replace(/\s+/g, '_');
    const documentName = (documentData.title || 'Untitled_Document').replace(/\s+/g, '_');
    const fileName = `${presetName}_${documentName}.docx`;
    
    await downloadDocx(blob, fileName);
};

export const exportAttestations = async (cards) => {
    try {

        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit'
        });

        const doc = new Document({
            styles: {
                default: {
                    document: {
                        run: {
                            font: 'Calibri',
                            size: 22, // 11 points * 2
                        },
                    },
                },
            },
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({ 
                                text: 'Attestations for ' + formattedDate,
                                bold: true,
                                size: 28 // 14 points * 2
                            })
                        ]
                    }),
                    new Paragraph({}), // Empty paragraph for spacing
                    ...cards.flatMap(card => {
                        const { name, mrn } = extractNameAndMRN(card.title || "Untitled Card");
                        const formattedAttestation = formatAttestation(
                            card.attestation || '',
                            card.seen || false,
                            card.supervisee || ''
                        );

                        // Format modality for display
                        const modality = card.modality || 'in-person';
                        const formattedModality = modality.charAt(0).toUpperCase() + modality.slice(1);

                        // Split the attestation into paragraphs
                        const attestationParagraphs = formattedAttestation.split('\n').map(text => 
                            new Paragraph({
                                children: [new TextRun({ text: text.trim() })]
                            })
                        );

                        return [
                            new Paragraph({
                                children: [
                                    new TextRun({ text: "Name:", bold: true, underline: true }),
                                    new TextRun({ text: " " + name + "      " }),
                                    new TextRun({ text: "MRN:", bold: true, underline: true }),
                                    new TextRun({ text: " " + (mrn || "N/A") }),
                                ],
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({ text: "Supervisee:", bold: true, underline: true }),
                                    new TextRun({ text: " " + (card.supervisee || "Not specified") + "      " }),
                                    new TextRun({ text: "Seen:", bold: true, underline: true }),
                                    new TextRun({ text: " " + (card.seen ? "Yes" : "No") + "      " }),
                                    new TextRun({ text: "Modality:", bold: true, underline: true }),
                                    new TextRun({ text: " " + formattedModality }),
                                ],
                            }),
                            new Paragraph({}), // Empty paragraph for spacing
                            ...attestationParagraphs,
                            new Paragraph({}), // Empty paragraph for spacing
                            new Paragraph({}), // Extra spacing between patients
                        ];
                    }),
                ],
            }],
        });



        const blob = await Packer.toBlob(doc);
        await downloadDocx(blob, `attestations_${formattedDate}.docx`);
    } catch (error) {
        console.error('Error exporting attestations:', error);
        throw error;
    }
};