import React from 'react';

function SidebarHeader({
    isPanelCollapsed,
    searchTerm,
    setSearchTerm,
    setIsSearchFocused,
    handleClearSearch
}) {
    if (isPanelCollapsed) return null;

    return (
        <div className="space-y-2 mb-4">
            <div className="relative">
                <input
                    type="text"
                    placeholder="Search cards..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                    className="w-full p-2 pr-8 bg-[#2a2a2a] surface-border bg-opacity-50 text-white rounded border surface-border focus:border-white focus:outline-none"
                />
                {searchTerm && (
                    <button
                        onClick={handleClearSearch}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#ababab] hover:text-white"
                    >
                        <span className="material-icons text-sm">close</span>
                    </button>
                )}
            </div>
        </div>
    );
}

export default SidebarHeader; 