import React, { useState } from 'react';
import apiService from '../../../services/api';
import { useSettings } from '../../../context/SettingsContext';
import { snackbarMessage, snackbarError } from '../../common/Snackbar';
import HighlightedTextArea from '../HighlightedTextArea';

function DifferentialScratchpadView({ note, updateNote }) {
    const [rows, setRows] = useState([]);
    const { settings } = useSettings();
    const [isLoading, setIsLoading] = useState(false);

    const addRow = () => {
        setRows([...rows, {
            id: Date.now(),
            differential: '',
            supporting: '',
            diagnostics: ''
        }]);
    };

    const deleteRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const updateRow = (id, field, value) => {
        setRows(rows.map(row => 
            row.id === id ? { ...row, [field]: value } : row
        ));
    };

    const generateDifferential = async () => {
        setIsLoading(true);
        try {
            const { background, hpi, medicalHistory, socialHistory, physicalExam, labs, imaging } = note || {};
            const response = await apiService.generateDifferential(
                { background, hpi, medicalHistory, socialHistory, physicalExam, labs, imaging },
                settings.aiProvider
            );
            
            if (response.differentials) {
                const newRows = response.differentials.map(diff => ({
                    id: Date.now() + Math.random(),
                    ...diff
                }));
                setRows([...rows, ...newRows]);
            }
        } catch (error) {
            console.error('Error generating differential:', error);
            snackbarError('Failed to generate differential. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const deleteAllRows = () => {
        setRows([]);
    };

    const pushToNote = () => {
        if (!rows.length) return;
        
        const formattedDifferential = "Ddx:\n" + rows.map(row => {
            const parts = [];
            if (row.differential?.trim() && row.differential !== null) parts.push(row.differential.trim());
            if (row.supporting?.trim() && row.supporting !== null) parts.push(row.supporting.trim());
            if (row.diagnostics?.trim() && row.diagnostics !== null) parts.push(row.diagnostics.trim());
            return parts.join(' -> ');
        }).join('\n\n');

        const currentRecommendations = note.recommendations || '';
        const updatedRecommendations = currentRecommendations + 
            (currentRecommendations ? '\n\n' : '') + 
            formattedDifferential;
        
        updateNote('recommendations', updatedRecommendations);
        deleteAllRows();
        snackbarMessage('Differentials pushed to note');
    };

    return (
        <div className="space-y-4 sm:space-y-6">
            <hr className="surface-divider-top" />
            <h2 className="text-l font-semibold text-gray-100 px-2 sm:px-0">Differential Scratchpad</h2>
                <div className="overflow-x-auto -mx-2 sm:mx-0">
                    <div className="border border-gray-700 rounded-lg overflow-hidden">
                        <table className="w-full table-fixed">
                            <thead>
                                <tr className="bg-gray-800">
                                    <th className="text-center px-2 sm:px-4 py-3 text-gray-100 font-medium first:rounded-tl-lg border-b border-gray-700 w-1/3">Differential</th>
                                    <th className="text-center px-2 sm:px-4 py-3 text-gray-100 font-medium border-b border-gray-700 w-1/3">Supporting</th>
                                    <th className="text-center px-2 sm:px-4 py-3 text-gray-100 font-medium border-b border-gray-700 w-1/3">Diagnostics</th>
                                    <th className="w-12 sm:w-16 last:rounded-tr-lg border-b border-gray-700"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row, index) => (
                                    <tr key={row.id} className={`hover:bg-gray-800/50 transition-colors duration-150 ${index % 2 === 0 ? 'bg-gray-900/30' : 'bg-gray-700/30'}`}>
                                        <td className="px-2 sm:px-4 py-3">
                                            <HighlightedTextArea
                                                value={row.differential || ''}
                                                onChange={(e) => updateRow(row.id, 'differential', e.target.value)}
                                                placeholder="Enter diagnosis..."
                                                section="differential"
                                            />
                                        </td>
                                        <td className="px-2 sm:px-4 py-3">
                                            <HighlightedTextArea
                                                value={row.supporting || ''}
                                                onChange={(e) => updateRow(row.id, 'supporting', e.target.value)}
                                                placeholder="Supporting evidence..."
                                                section="differential"
                                            />
                                        </td>
                                        <td className="px-2 sm:px-4 py-3">
                                            <HighlightedTextArea
                                                value={row.diagnostics || ''}
                                                onChange={(e) => updateRow(row.id, 'diagnostics', e.target.value)}
                                                placeholder="Recommended tests..."
                                                section="differential"
                                            />
                                        </td>
                                        <td className="py-3 text-left">
                                            <button
                                                onClick={() => deleteRow(row.id)}
                                                className="text-gray-400 hover:text-red-400 p-0.5 sm:p-1 rounded transition-colors duration-150"
                                                title="Delete Row"
                                            >
                                                <span className="material-symbols-outlined text-xl sm:text-2xl">delete</span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {rows.length === 0 && (
                                    <tr>
                                        <td colSpan="4" className="text-center py-8 text-gray-400 italic">
                                            Click "Add" or "Build" to get started.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                <div className="mt-4 flex flex-col sm:flex-row justify-between gap-2 sm:gap-1.5">
                    <div className="flex-1 flex flex-wrap justify-center gap-2 sm:gap-1.5">
                        <button
                            onClick={pushToNote}
                            className={`flex items-center justify-center px-4 py-2 rounded-md border text-sm ${
                                !rows.length 
                                    ? 'bg-[#323232] border-[#424242]' 
                                    : 'primary-button flash-button'
                            } focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out`}
                            title="Push to Note"
                            disabled={!rows.length}
                        >
                            <span className="material-symbols-outlined text-white text-base mr-2">
                                compare_arrows
                            </span>
                            <span className="text-sm text-white">
                                Push to Note
                            </span>
                        </button>
                        <button
                            onClick={generateDifferential}
                            disabled={isLoading}
                            className={`flex items-center justify-center px-4 py-2 rounded-md border text-sm ${
                                isLoading 
                                    ? 'bg-[#323232] border-[#424242]' 
                                    : 'primary-button'
                            } focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
                            title="Build Differential"
                        >
                            {isLoading ? (
                                <span className="material-icons text-white animate-spin text-base mr-2">
                                    hourglass_empty
                                </span>
                            ) : (
                                <span className="material-symbols-outlined text-white text-base mr-2">
                                    construction
                                </span>
                            )}
                            <span className="text-sm text-white">
                                {isLoading ? 'Generating...' : 'Build'}
                            </span>
                        </button>
                        <button
                            onClick={addRow}
                            className="primary-button px-4 py-2 rounded flash-button flex items-center justify-center"
                            title="Add Row"
                        >
                            <span className="material-symbols-outlined text-white text-base mr-2">add_row_below</span>
                            <span className="text-sm text-white">Add</span>
                        </button>
                    </div>
                    <div className="flex justify-center sm:justify-end">
                        <button
                            onClick={deleteAllRows}
                            className="alert-button px-4 py-2 rounded flash-button flex items-center justify-center"
                            title="Delete All"
                        >
                            <span className="material-symbols-outlined text-white text-base mr-2">delete_sweep</span>
                            <span className="text-sm text-white">Delete All</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(DifferentialScratchpadView); 