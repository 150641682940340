/**
 * Default Section Text
 * 
 * This file contains the default text for each section of a given note, by preset. It is used to provide a starting point for the section text when it is otherwise empty.
 */

const defaultSectionText = {
    consultInfo: {
        'ID Consult': 'Consulting Physician: Dr. ***\nConsulting Service: ***\nReason for Consultation: ***',
        'Outpatient': 'Consulting Physician: Dr. ***\nReason for Consultation: ***\nHistory Obtained from: Patient\nLanguage: {interpreterneeded} ',
    },

    hpi: {
        'ID Consult': 'History of Present Illness:\n',
        'ID Follow Up': 'Interval History:\n'
    },

    medicalHistory: 'Medical History:\n\nSurgical History:\n\nAntimicrobial Allergies:',

    socialHistory: 'Social History:\n\nSmoking History: ***\nAlcohol Consumption: ***\nIllicit Drug Use: ***',

    background: 'Background:\n',

    antimicrobials: {
        'ID Consult': 'Antimicrobials:\n***',
        'Outpatient': 'Medications:\n***\n\nAntimicrobials:\n***',
    },

    physicalExam: {
        'Outpatient': 'Physical Examination:\n***\nLines: ***',
    },
    
    labs: 'Labs:\n\nMicrobiology:\n',

    imaging: 'Radiology:\n\nCardiology:\n',

    recommendations: {
        'ID Consult': 'Assessment and Recommendations:\n',
        'Outpatient': 'Assessment and Plan:\n',
    }
};

/**
 * Get the default text for a section based on the preset
 * @param {string} section - The section name
 * @param {string} preset - The preset name (e.g., 'ID Consult', 'Outpatient')
 * @returns {string} The default text for the section
 */

const getDefaultSectionText = (section, preset = 'ID Consult') => {
    const sectionDefaults = defaultSectionText[section];
    
    // If the section has preset-specific defaults
    if (sectionDefaults && typeof sectionDefaults === 'object') {
        // Return the preset-specific default or fall back to 'ID Consult' or the first available preset
        return sectionDefaults[preset] || 
               sectionDefaults['ID Consult'] || 
               Object.values(sectionDefaults)[0] || 
               '';
    }
    
    // If the section has a single default for all presets
    return sectionDefaults || '';
};

export default getDefaultSectionText;