import { AuthService } from './auth';
import { NotesService } from './notes';
import { DocumentsService } from './documents';
import { SettingsService } from './settings';
import { CardsService } from './cards';

class ApiService {
    constructor() {
        // Initialize services
        this.auth = new AuthService();
        this.notes = new NotesService();
        this.documents = new DocumentsService();
        this.settings = new SettingsService();
        this.cards = new CardsService();

        // Initialize token from sessionStorage if it exists
        const token = sessionStorage.getItem('token');
        if (token) {
            this.setAuthToken(token);
        }
    }

    // Auth token handling
    get authToken() {
        return this.auth.authToken;
    }

    // Propagate token to all services
    setAuthToken(token) {
        this.auth.setAuthToken(token);
        this.notes.setAuthToken(token);
        this.documents.setAuthToken(token);
        this.settings.setAuthToken(token);
        this.cards.setAuthToken(token);
    }

    // Auth methods
    login = (...args) => this.auth.login(...args);
    verifyOTP = (...args) => this.auth.verifyOTP(...args);
    logout = (...args) => this.auth.logout(...args);

    // Notes methods
    fetchNotes = (...args) => this.notes.fetchNotes(...args);
    fetchLastViewedNote = (...args) => this.notes.fetchLastViewedNote(...args);
    createNote = (...args) => this.notes.createNote(...args);
    updateNote = (...args) => this.notes.updateNote(...args);
    deleteNote = (...args) => this.notes.deleteNote(...args);
    deleteAllNotes = (...args) => this.notes.deleteAllNotes(...args);
    reorderNotes = (...args) => this.notes.reorderNotes(...args);
    updateLastViewedNote = (...args) => this.notes.updateLastViewedNote(...args);
    createBulkNotes = (...args) => this.notes.createBulkNotes(...args);
    summarizeNote = (...args) => this.notes.summarizeNote(...args);
    generateMessage = (...args) => this.notes.generateMessage(...args);
    generateDifferential = (...args) => this.notes.generateDifferential(...args);

    // Documents methods
    fetchDocuments = (...args) => this.documents.fetchDocuments(...args);
    fetchLastViewedDocument = (...args) => this.documents.fetchLastViewedDocument(...args);
    updateLastViewedDocument = (...args) => this.documents.updateLastViewedDocument(...args);
    createDocument = (...args) => this.documents.createDocument(...args);
    updateDocument = (...args) => this.documents.updateDocument(...args);
    deleteDocument = (...args) => this.documents.deleteDocument(...args);
    deleteAllDocuments = (...args) => this.documents.deleteAllDocuments(...args);
    reorderDocument = (...args) => this.documents.reorderDocument(...args);
    chatWithAI = (...args) => this.documents.chatWithAI(...args);

    // Settings methods
    fetchSettings = (...args) => this.settings.fetchSettings(...args);
    updateSettings = (...args) => this.settings.updateSettings(...args);
    getPresetFilter = (...args) => this.settings.getPresetFilter(...args);
    savePresetFilter = (...args) => this.settings.savePresetFilter(...args);

    // Cards methods
    fetchCards = (...args) => this.cards.fetchCards(...args);
    createCard = (...args) => this.cards.createCard(...args);
    updateCard = (...args) => this.cards.updateCard(...args);
    deleteCard = (...args) => this.cards.deleteCard(...args);
    deleteAllCards = (...args) => this.cards.deleteAllCards(...args);
    reorderCard = (...args) => this.cards.reorderCard(...args);
}

// Create and export a singleton instance
const apiService = new ApiService();
export default apiService; 