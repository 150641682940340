import React, { useRef, useEffect, useState } from 'react';

const PresetSelector = ({ presets, currentPreset, onPresetChange }) => {
    const [sliderStyle, setSliderStyle] = useState({ width: 0, left: 0 });
    const [isVisible, setIsVisible] = useState(false);
    const buttonsRef = useRef([]);

    useEffect(() => {
        const updateSliderPosition = () => {
            const currentIndex = presets.findIndex(p => p.full === currentPreset);
            const currentButton = buttonsRef.current[currentIndex];
            
            if (currentButton) {
                setSliderStyle({
                    width: currentButton.offsetWidth,
                    left: currentButton.offsetLeft
                });
                
                // Make slider visible after initial position is set
                if (!isVisible) {
                    setTimeout(() => setIsVisible(true), 50);
                }
            }
        };

        updateSliderPosition();
        window.addEventListener('resize', updateSliderPosition);
        return () => window.removeEventListener('resize', updateSliderPosition);
    }, [currentPreset, presets, isVisible]);

    return (
        <div className="inline-flex rounded-md shadow-sm relative [transform-style:preserve-3d]" role="group">
            <div
                className={`absolute h-full bg-[#1976d2] ${isVisible ? 'transition-all duration-200' : ''} ease-in-out`}
                style={{
                    ...sliderStyle,
                    transform: 'translateZ(0)',
                    backfaceVisibility: 'hidden',
                    width: `calc(${sliderStyle.width}px - 1px)`,
                    left: `${sliderStyle.left}px`,
                    visibility: isVisible ? 'visible' : 'hidden',
                    borderRadius: (() => {
                        const currentIndex = presets.findIndex(p => p.full === currentPreset);
                        return `${currentIndex === 0 ? '8px' : '0'} ${currentIndex === presets.length - 1 ? '8px' : '0'} ${currentIndex === presets.length - 1 ? '8px' : '0'} ${currentIndex === 0 ? '8px' : '0'}`;
                    })()
                }}
            />
            
            {presets.map((preset, index) => (
                <button
                    key={preset.full}
                    ref={el => buttonsRef.current[index] = el}
                    type="button"
                    disabled={currentPreset === preset.full}
                    onClick={() => onPresetChange(preset.full)}
                    className={`
                        px-4 py-2 text-sm font-medium relative z-10
                        ${index === 0 ? 'rounded-l-lg' : ''}
                        ${index === presets.length - 1 ? 'rounded-r-lg' : ''}
                        ${currentPreset === preset.full
                            ? 'text-white'
                            : 'text-[#e0e0e0] hover:bg-[#323232] hover:bg-opacity-40 hover:text-white'
                        }
                        focus:z-10 focus:ring-1 focus:ring-white focus:text-white
                        transition-colors duration-200 ease-in-out

                        border-t border-b ${index === 0 ? 'border-l' : ''} ${index === presets.length - 1 ? 'border-r' : ''}
                        border-[#404040]
                    `}
                >
                    <span className="hidden sm:inline">{preset.full}</span>
                    <span className="sm:hidden">{preset.short}</span>
                </button>
            ))}
        </div>
    );
};

export default PresetSelector; 