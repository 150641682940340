import React from 'react';
import useCards from '../../../hooks/useCards';
import { snackbarMessage, snackbarError } from '../../common/Snackbar';

const ConvertNoteToCardButton = ({ note }) => {
    const { convertNoteToCard } = useCards();

    const handleConvertToCard = async () => {
        try {
            await convertNoteToCard(note);
            snackbarMessage('Note converted to card successfully');
        } catch (error) {
            console.error('Failed to convert note to card:', error);
            snackbarError('Failed to convert note to card');
        }
    };

    return (
        <button
            onClick={handleConvertToCard}
            className="flex items-center justify-center px-2 py-1 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
            title="Convert to Card"
        >
            <span className="material-icons text-white text-base">
                credit_card
            </span>
        </button>
    );
};

export default ConvertNoteToCardButton; 