import React, { createContext, useState, useContext, useEffect } from 'react';
import { addDays } from 'date-fns';
import apiService from '../services/api';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        defaultPreset: 'ID Consult',
        signoutDate: addDays(new Date(), 1),
        signoutPerson: '',
        aiProvider: 'claude',
        team: 'None',
    });

    const loadSettings = async () => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                return;
            }

            const loadedSettings = await apiService.fetchSettings();
            setSettings(prevSettings => ({
                ...prevSettings,
                ...loadedSettings,
                signoutDate: loadedSettings.signoutDate ? new Date(loadedSettings.signoutDate) : null,
            }));
        } catch (error) {
            console.error('Error loading settings:', error);
        }
    };

    useEffect(() => {
        loadSettings();
    }, []);

    const updateSettings = async (newSettings) => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const updatedSettings = {
                ...settings,
                ...newSettings,
                signoutDate: newSettings.signoutDate ? new Date(newSettings.signoutDate) : null,
            };

            const formattedSettings = Object.fromEntries(
                Object.entries(updatedSettings).map(([key, value]) => [
                    key,
                    value instanceof Date ? value.toISOString() : value
                ])
            );

            await apiService.updateSettings(formattedSettings);
            setSettings(updatedSettings);
        } catch (error) {
            console.error('Error saving settings:', error);
            throw error;
        }
    };

    const updateSetting = async (key, value) => {
        await updateSettings({ [key]: value });
    };

    return (
        <SettingsContext.Provider value={{ settings, updateSettings, loadSettings, updateSetting }}>
            {children}
        </SettingsContext.Provider>
    );
};