import React from 'react';
import DotPhraseTextArea from '../NoteEditor/DotPhraseTextArea';
import PatientNameField from '../common/PatientNameField';

const CardItemEditView = ({ card, fieldConfig, isSaving, onChange }) => {
    return (
        <div className="p-4">
            {/* Render form fields based on configuration */}
            <div className="flex flex-wrap">
                {fieldConfig.map((field) => {
                    const value = card[field.id] || '';
                    const containerClass = field.fullWidth 
                        ? "mb-3 w-full" 
                        : "flex-1 mb-3 md:mb-0";
                    
                    // Special handling for the title field - use PatientNameField
                    if (field.id === 'title') {
                        return (
                            <div key={field.id} className={containerClass}>
                                <label className="block text-sm font-medium text-gray-400 mb-1">{field.label}</label>
                                <PatientNameField
                                    value={value}
                                    onChange={(e) => onChange(field.id, e.target.value)}
                                    id={`card-${field.id}`}
                                    placeholder="Enter patient name"
                                    disabled={isSaving}
                                    inputStyle={{
                                        borderTopLeftRadius: '0.25rem',
                                    }}
                                />
                            </div>
                        );
                    }
                    
                    // Special handling for the row with Labs/Micro and Antimicrobials
                    if (field.id === 'labs' || field.id === 'antimicrobials') {
                        if (field.id === 'labs') {
                            // Only create the row container for the first field in the row
                            return (
                                <div key={field.id} className="flex flex-col md:flex-row md:gap-4 mb-3 w-full">
                                    <div className={containerClass}>
                                        <label className="block text-sm font-medium text-gray-400 mb-1">{field.label}</label>
                                        <DotPhraseTextArea
                                            value={value}
                                            onChange={(e) => onChange(field.id, e.target.value)}
                                            id={`card-${field.id}`}
                                            disabled={isSaving}
                                        />
                                    </div>
                                    
                                    {/* Render antimicrobials field inline */}
                                    <div className="flex-1">
                                        <label className="block text-sm font-medium text-gray-400 mb-1">{fieldConfig.find(f => f.id === 'antimicrobials').label}</label>
                                        <DotPhraseTextArea
                                            value={card.antimicrobials || ''}
                                            onChange={(e) => onChange('antimicrobials', e.target.value)}
                                            id="card-antimicrobials"
                                            disabled={isSaving}
                                        />
                                    </div>
                                </div>
                            );
                        } else {
                            // Skip antimicrobials as it's already rendered with labs
                            return null;
                        }
                    }
                    
                    return (
                        <div key={field.id} className={containerClass}>
                            <label className="block text-sm font-medium text-gray-400 mb-1">{field.label}</label>
                            {field.type === 'input' ? (
                                <input
                                    type="text"
                                    className="block w-full rounded-md input-base transition duration-150 ease-in-out shadow-sm px-3 py-2"
                                    value={value}
                                    onChange={(e) => onChange(field.id, e.target.value)}
                                    disabled={isSaving}
                                />
                            ) : (
                                <DotPhraseTextArea
                                    value={value}
                                    onChange={(e) => onChange(field.id, e.target.value)}
                                    id={`card-${field.id}`}
                                    disabled={isSaving}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CardItemEditView; 