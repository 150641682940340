import { BaseApiService } from './base';

export class DocumentsService extends BaseApiService {
    async fetchDocuments() {
        const response = await this.axiosInstance.get('/api/documents');
        return response.data;
    }

    async fetchLastViewedDocument() {
        const response = await this.axiosInstance.get('/api/documents/last-viewed');
        return response.data;
    }

    async updateLastViewedDocument(documentId) {
        const response = await this.axiosInstance.post('/api/documents/last-viewed', { documentId });
        return response.data;
    }

    async createDocument(document) {
        const response = await this.axiosInstance.post('/api/documents', document);
        return response.data;
    }

    async updateDocument(documentId, data) {
        const response = await this.axiosInstance.put(`/api/documents/${documentId}`, data);
        return response.data;
    }

    async deleteDocument(documentId) {
        const response = await this.axiosInstance.delete(`/api/documents/${documentId}`);
        return response.data;
    }

    async deleteAllDocuments() {
        const documents = await this.fetchDocuments();
        await Promise.all(documents.map(doc => this.deleteDocument(doc.id)));
        return { message: 'All documents deleted successfully' };
    }

    async reorderDocument(documentId, order) {
        const response = await this.axiosInstance.put(`/api/documents/reorder/${documentId}`, { order });
        return response.data;
    }

    async chatWithAI({ message, documentContent, documentTitle, documentPreset, aiProvider, noteContext }) {
        const response = await this.axiosInstance.post('/api/documents/chat', {
            message,
            documentContent,
            documentTitle,
            documentPreset,
            aiProvider,
            noteContext
        });
        return response.data;
    }
} 