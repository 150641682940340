import React, { useState, useEffect, useMemo } from 'react';
import { useVisualViewport } from '../../hooks/useVisualViewport';
import { debounce } from 'lodash';

const truncatedSectionTitles =
{
    consultInfo: 'Consult Info',
    background: 'Background',
    hpi: 'HPI',
    medicalHistory: 'Medical History',
    antimicrobials: 'Medications',
    physicalExam: 'Physical Exam',
    labs: 'Labs',
    imaging: 'Imaging',
    recommendations: 'Plan',
}

const Minimap = ({ sections, onSectionClick, collapseHeader }) => {
    const bottomOffset = useVisualViewport();
    const [visibleSection, setVisibleSection] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleScroll = useMemo(
        () => debounce(() => {
            const headerHeight = collapseHeader ? 64 : 112;
            const viewportTop = window.scrollY + headerHeight + 40;
            
            // Find which section is closest to the top of the viewport
            let closestSection = null;
            let minDistance = Infinity;

            sections.forEach(section => {
                const element = document.getElementById(section.id);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    const absoluteTop = rect.top + window.scrollY;
                    const distance = Math.abs(absoluteTop - viewportTop);
                    
                    if (distance < minDistance) {
                        minDistance = distance;
                        closestSection = section.id;
                    }
                }
            });

            setVisibleSection(closestSection);
        }, 100),
        [sections, collapseHeader]
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check

        return () => {
            handleScroll.cancel(); // Cancel any pending debounced calls
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    if (!sections || sections.length === 0) return null;

    const scrollToSection = (index) => {
        if (index === 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (sections[index]) {
            onSectionClick(sections[index].id);
        }
    };

    return (
        <div 
            className="fixed right-4 z-10 hidden sm:block"
            style={{ 
                bottom: `max(200px, calc(25vh + ${bottomOffset}px))`,
                top: collapseHeader ? '4rem' : '7rem',
                transition: 'all 0.3s'
            }}
        >
            <div 
                className={`bg-[#242424]/80 backdrop-blur-sm rounded-lg p-2 border-1 surface-border shadow-lg transition-all duration-300 ${
                    isExpanded ? 'w-auto' : 'w-8'
                }`}
                onMouseEnter={() => setIsExpanded(true)}
                onMouseLeave={() => setIsExpanded(false)}
            >
                <button
                    onClick={() => scrollToSection(0)}
                    className="w-full flex justify-center p-2 hover:bg-[#343434]/80 rounded-md mb-2"
                >
                    <span className="material-symbols-outlined">
                        keyboard_arrow_up
                    </span>
                </button>

                <div className="flex flex-col gap-1 max-h-[calc(100vh-20rem)] overflow-y-auto custom-scrollbar">
                    {sections.map((section) => (
                        <button
                            key={section.id}
                            onClick={() => onSectionClick(section.id)}
                            className={`flex items-center w-full rounded-md transition-all relative ${
                                !isExpanded ? 'justify-center p-1' : 'px-3 py-2'
                            } ${
                                isExpanded && !section.isCollapsed ? (
                                    visibleSection === section.id
                                        ? 'bg-blue-500/20 text-blue-300'
                                        : 'hover:bg-[#343434]/80 text-gray-300'
                                ) : ''
                            }`}
                        >
                            {!isExpanded && visibleSection === section.id && (
                                <div className="w-4 h-3 rounded-full bg-blue-500 transition-all" />
                            )}
                            {section.isCollapsed && isExpanded && (
                                <span className="material-symbols-outlined text-lg mr-1">
                                    unfold_less
                                </span>
                            )}
                            <span className={`truncate ${isExpanded ? 'max-w-[120px]' : 'w-0 overflow-hidden'}`}>
                                {truncatedSectionTitles[section.id] || section.title}
                            </span>
                        </button>
                    ))}
                </div>

                <button
                    onClick={() => scrollToSection(sections.length - 1)}
                    className="w-full flex justify-center p-2 hover:bg-[#343434]/80 rounded-md mt-2"
                >
                    <span className="material-symbols-outlined">
                        keyboard_arrow_down
                    </span>
                </button>
            </div>
        </div>
    );
};

export default Minimap;