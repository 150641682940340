import React, { useState } from 'react';
import { useSettings } from '../../../context/SettingsContext';
import apiService from '../../../services/api';
import BaseModal from '../../common/BaseModal';

function MessageButton({ note, currentPreset }) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const { settings } = useSettings();

    const handleOpenDialog = () => {
        setIsOpen(true);
        setMessage(''); // Reset the message when opening the dialog
        setError(null); // Also reset any previous errors
    };

    const handleGenerateMessage = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await apiService.generateMessage(note.recommendations, settings.aiProvider);
            setMessage(data.message);
        } catch (error) {
            console.error('Error generating message:', error);
            setError('Failed to generate message. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyMessage = () => {
        navigator.clipboard.writeText(message);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    if (!['ID Follow Up', 'ID Consult'].includes(currentPreset)) {
        return null;
    }

    return (
        <>
            <button
                onClick={handleOpenDialog}
                className="flex items-center justify-center px-3 py-1.5 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
                title="Generate Message"
            >
                <span className="material-icons text-white text-base mr-1">
                    message
                </span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Message</span>
            </button>

            <BaseModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title="Generate Message for Team"
                maxWidth="2xl"
            >
                <div className="relative">
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="input-base w-full h-48 p-3 pr-12 rounded mb-2 text-sm leading-relaxed font-sans"
                    />
                    <button
                        onClick={handleCopyMessage}
                        title="Copy Message"
                        className={`absolute top-2 right-2 p-2 rounded-md transition-colors duration-200 ${
                            copySuccess 
                                ? 'bg-green-600 text-white' 
                                : 'text-[#ababab] hover:text-white'
                        }`}
                    >
                        <span className="material-icons text-base">
                            {copySuccess ? 'check' : 'content_copy'}
                        </span>
                    </button>
                </div>

                <div className="flex justify-center">
                    <button
                        onClick={handleGenerateMessage}
                        disabled={isLoading}
                        className="mb-3 sm:mb-4 px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white border surface-border flash-button"
                    >
                        {isLoading ? (
                            <>
                                <span className="material-icons text-white text-base animate-spin mr-1">hourglass_empty</span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Generating...</span>
                            </>
                        ) : (
                            <>
                                <span className="material-icons text-white text-base mr-1">auto_fix_high</span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Generate Message</span>
                            </>
                        )}
                    </button>
                </div>

                {error && <p className="text-red-500 mb-2">{error}</p>}


            </BaseModal>
        </>
    );
}

export default MessageButton;