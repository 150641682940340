import React, { useState, useEffect } from 'react';
import BaseModal from '../../../components/common/BaseModal';

function BulkNoteCreationModal({ isOpen, onClose, onCreateNotes }) {
    const [noteNames, setNoteNames] = useState('');
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setNoteNames('');
            setError(null);
            setIsSubmitting(false);
        }
    }, [isOpen]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const names = noteNames
            .split('\n')
            .map(name => name.trim())
            .filter(name => name.length > 0);
        
        if (names.length === 0) {
            setError('Please enter at least one note name');
            return;
        }

        try {
            setIsSubmitting(true);
            await onCreateNotes(names);
            setNoteNames('');
            onClose();
        } catch (error) {
            console.error('Error creating notes:', error);
            setError('Failed to create notes. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const noteCount = noteNames
        .split('\n')
        .map(name => name.trim())
        .filter(name => name.length > 0)
        .length;

    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            title="Bulk Create Notes"
            maxWidth="2xl"
            showCloseButton={!isSubmitting}
        >
            <form onSubmit={handleSubmit}>
                <div className="relative">
                    <textarea
                        value={noteNames}
                        onChange={(e) => {
                            setNoteNames(e.target.value);
                            setError(null);
                        }}
                        disabled={isSubmitting}
                        placeholder="Enter note names, one per line"
                        className="input-base w-full h-48 sm:h-64 p-3 rounded mb-2 text-sm leading-relaxed font-sans disabled:opacity-50"
                    />
                </div>
                
                {error && (
                    <p className="text-red-500 text-xs mb-2">{error}</p>
                )}

                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        onClick={onClose}
                        disabled={isSubmitting}
                        className="flex items-center justify-center px-3 py-1.5 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border surface-border focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button disabled:opacity-50"
                    >
                        <span className="material-symbols-outlined text-white text-base mr-1">close</span>
                        <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Cancel</span>
                    </button>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="primary-button flex items-center justify-center px-3 py-1.5 rounded-md focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button disabled:opacity-50"
                    >
                        <span className="material-symbols-outlined text-white text-base mr-1">playlist_add</span>
                        <span className="text-xs text-[#e0e0e0] whitespace-nowrap">
                            {isSubmitting ? 'Creating...' : `Create ${noteCount} Note${noteCount !== 1 ? 's' : ''}`}
                        </span>
                    </button>
                </div>
            </form>
        </BaseModal>
    );
}

export default BulkNoteCreationModal; 