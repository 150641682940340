import React, { useState } from 'react';
import { useNotes } from '../../../hooks/useNotes';
import BaseModal from '../../common/BaseModal';

function NoteContextButton({ document, onUpdateDocument, className = '' }) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { notes } = useNotes();

    const filteredNotes = notes?.filter(note => 
        note.title.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const linkedNote = notes?.find(note => note.id === document.noteId);

    const handleNoteSelect = (note) => {
        onUpdateDocument({ ...document, noteId: note.id });
        setIsOpen(false);
    };

    const handleRemoveNote = () => {
        onUpdateDocument({ ...document, noteId: null });
    };

    return (
        <>
            <div className={`flex items-center gap-1 ${className}`}>
                <span className="text-sm text-[#e0e0e0]">Attached Note:</span>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex items-center gap-1 px-2 py-1 rounded hover:bg-[#323232] transition-colors"
                >
                    <span className={`text-sm ${linkedNote ? 'text-green-500' : 'text-gray-400'}`}>
                        {linkedNote?.title || 'None'}
                    </span>
                    <span className="material-symbols-outlined text-base">
                        {linkedNote ? 'link' : 'link_off'}
                    </span>
                </button>
                {linkedNote && (
                    <button
                        onClick={handleRemoveNote}
                        className="text-gray-400 hover:text-red-500 transition-colors"
                        title="Remove note context"
                    >
                        <span className="material-symbols-outlined text-base">close</span>
                    </button>
                )}
            </div>

            <BaseModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title="Select from Existing Notes"
                maxWidth="md"
            >
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search notes..."
                    className="w-full px-3 py-2 rounded input-base text-sm mb-4"
                />

                <div className="max-h-[60vh] overflow-y-auto">
                    {filteredNotes.length > 0 ? (
                        <div className="space-y-1">
                            {filteredNotes.map(note => (
                                <button
                                    key={note.id}
                                    onClick={() => handleNoteSelect(note)}
                                    className="w-full text-left px-3 py-2 hover:bg-[#323232] rounded text-sm truncate flex items-center gap-2"
                                >
                                    <span className="material-symbols-outlined text-base text-[#ababab]">
                                        description
                                    </span>
                                    {note.title}
                                </button>
                            ))}
                        </div>
                    ) : (
                        <p className="text-[#ababab] text-sm px-3 py-2">
                            {searchQuery ? 'No matching notes found' : 'No notes available'}
                        </p>
                    )}
                </div>
            </BaseModal>
        </>
    );
}

export default NoteContextButton; 