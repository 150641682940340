import React from 'react';

const presetDisplayNames = {
    'all': 'All',
    'Letter': 'Letter',
    'Education': 'Edu',
    'Communications': 'Comm'
};

function DocumentsSidebarHeader({
    isPanelCollapsed,
    searchTerm,
    setSearchTerm,
    setIsSearchFocused,
    handleClearSearch,
    presetFilter,
    presetCounts,
    handlePresetFilterChange
}) {
    if (isPanelCollapsed) return null;

    return (
        <div className="space-y-2 mb-4">
            <div className="relative">
                <input
                    type="text"
                    placeholder="Search documents..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                    className="w-full p-2 pr-8 bg-[#2a2a2a] surface-border bg-opacity-50 text-white rounded border surface-border focus:border-white focus:outline-none"
                />
                {searchTerm && (
                    <button
                        onClick={handleClearSearch}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#ababab] hover:text-white"
                    >
                        <span className="material-icons text-sm">close</span>
                    </button>
                )}
            </div>
            <div className="flex space-x-1 text-xs">
                {['all', 'Letter', 'Education', 'Communications'].map((filter) => (
                    <button
                        key={filter}
                        onClick={() => handlePresetFilterChange(filter)}
                        className={`flex-1 px-2 py-1 rounded transition-colors flash-button ${
                            presetFilter === filter 
                                ? 'primary-button text-white' 
                                : 'bg-[#2a2a2a] bg-opacity-50 text-[#e0e0e0] hover:bg-opacity-75'
                        }`}
                        title={filter.charAt(0).toUpperCase() + filter.slice(1)}
                    >
                        {presetDisplayNames[filter]}
                        {presetCounts[filter] > 0 && ` (${presetCounts[filter]})`}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default DocumentsSidebarHeader; 