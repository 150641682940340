import axios from 'axios';

const SERVER_URL = import.meta.env.VITE_SERVER_URL;

// Create a shared axios instance
const sharedAxiosInstance = axios.create({
    baseURL: SERVER_URL,
    timeout: 120000,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add response interceptor for handling auth errors
sharedAxiosInstance.interceptors.response.use(
    response => response,
    error => {
        // Only handle 401s if we have a token in sessionStorage
        if (error.response?.status === 401 && sessionStorage.getItem('token')) {
            sessionStorage.removeItem('token');
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export class BaseApiService {
    constructor() {
        this._authToken = null;
        this.axiosInstance = sharedAxiosInstance;
    }

    get authToken() {
        return this._authToken;
    }

    setAuthToken(token) {
        this._authToken = token;
        if (token) {
            // Set both header formats that the server accepts
            this.axiosInstance.defaults.headers.common['x-auth-token'] = token;
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete this.axiosInstance.defaults.headers.common['x-auth-token'];
            delete this.axiosInstance.defaults.headers.common['Authorization'];
        }
    }
} 