import React from 'react';
import AntimicrobialView from './AntimicrobialView';
import BetaLactamAllergyView from './BetaLactamAllergyView';
import DifferentialScratchpadView from './DifferentialScratchpadView';
import PatientNameFormatting from '../../common/PatientNameFormatting';

function Insights({ note, updateNote }) {
    return (
        <div className="space-y-6">
            <PatientNameFormatting
                text={`Insights for ${note.title}`}
                className="font-semibold text-xl mb-4"
            />
            <BetaLactamAllergyView allergySection={note.medicalHistory} />
            <AntimicrobialView note={note} />
            <DifferentialScratchpadView note={note} updateNote={updateNote} />
        </div>
    );
}

export default React.memo(Insights); 