import React, { useState, useEffect, useRef, useCallback } from 'react';
import dotPhrases from '../data/dotPhrases';
import HighlightedTextArea from './HighlightedTextArea';
import DropdownList from './DropdownList';
import { useTabNavigation } from '../../hooks/useTabNavigation';
import { useDotPhrase } from '../../hooks/useDotPhrase';
import { useSmartList } from '../../hooks/useSmartList';
import { usePhraseNavigation } from '../../hooks/usePhraseNavigation';

const DotPhraseTextArea = React.forwardRef(({ 
    value, 
    section, 
    onChange, 
    id, 
    isRecording, 
    onFocus, 
    onBlur,
    presetSections,
    currentPreset,
    allRefs,
    fixedHeight,
    borderRounding,
    collapsedSections,
    pinnedSection
}, ref) => {
    const [cursorPosition, setCursorPosition] = useState(0);
    const containerRef = useRef(null);
    const dotPhraseDropdownRef = useRef(null);
    const smartListDropdownRef = useRef(null);
    
    // Custom hooks
    const handleTabNavigation = useTabNavigation(section, presetSections, currentPreset, allRefs, collapsedSections, pinnedSection);
    
    // Dot phrase handling
    const dotPhrase = useDotPhrase(value, cursorPosition, onChange, ref);
    
    // Smart list handling
    const smartList = useSmartList(value, cursorPosition, onChange, ref);
    
    // Keyboard navigation
    const dotPhraseNavigation = usePhraseNavigation(
        value,
        dotPhrase.setIsVisible,
        dotPhrase.selectedIndex,
        dotPhrase.setSelectedIndex,
        dotPhrase.matchingPhrases,
        dotPhrase.handleSelect,
        ref,
        cursorPosition,
        setCursorPosition
    );
    
    const smartListNavigation = usePhraseNavigation(
        value,
        smartList.setIsVisible,
        smartList.selectedIndex,
        smartList.setSelectedIndex,
        smartList.items,
        smartList.handleSelect,
        ref,
        cursorPosition,
        setCursorPosition
    );

    const updateDotPhrasePosition = useCallback(() => {
        if (dotPhrase.isVisible) {
            const position = dotPhrase.calculateDropdownPosition(containerRef);
            dotPhrase.setDropdownPosition(position);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dotPhrase.isVisible, dotPhrase.calculateDropdownPosition, dotPhrase.setDropdownPosition, containerRef]);

    const updateSmartListPosition = useCallback(() => {
        if (smartList.isVisible) {
            const position = smartList.calculateDropdownPosition(containerRef);
            smartList.setDropdownPosition(position);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smartList.isVisible, smartList.calculateDropdownPosition, smartList.setDropdownPosition, containerRef]);

    // Update dropdown positions when their visibility changes
    useEffect(() => {
        updateDotPhrasePosition();
    }, [dotPhrase.isVisible, updateDotPhrasePosition]);

    useEffect(() => {
        updateSmartListPosition();
    }, [smartList.isVisible, updateSmartListPosition]);

    // Update positions on resize
    useEffect(() => {
        const updatePositions = () => {
            updateDotPhrasePosition();
            updateSmartListPosition();
        };
        
        window.addEventListener('resize', updatePositions);
        return () => window.removeEventListener('resize', updatePositions);
    }, [updateDotPhrasePosition, updateSmartListPosition]);

    // Click outside to close dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dotPhraseDropdownRef.current && !dotPhraseDropdownRef.current.contains(event.target)) {
                dotPhrase.setIsVisible(false);
            }
            if (smartListDropdownRef.current && !smartListDropdownRef.current.contains(event.target)) {
                smartList.setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dotPhrase.setIsVisible, smartList.setIsVisible, dotPhrase, smartList]);

    const handleKeyDown = (e) => {
        // Handle Tab key for section navigation
        if (e.key === 'Tab') {
            handleTabNavigation(e);
            return;
        }

        // Handle F2 key for navigating to special markers
        if (e.key === 'F2') {
            dotPhraseNavigation.handleF2Navigation(e);
            return;
        }

        // Handle dot phrase dropdown navigation
        if (dotPhrase.isVisible) {
            if (dotPhraseNavigation.handleDropdownNavigation(e, dotPhrase.isVisible)) {
                return;
            }
        }
        
        // Handle smart list dropdown navigation
        if (smartList.isVisible) {
            if (smartListNavigation.handleDropdownNavigation(e, smartList.isVisible)) {
                return;
            }
        }
    };

    return (
        <div ref={containerRef} className="relative w-full">
            <HighlightedTextArea
                ref={ref}
                value={value}
                onChange={e => {
                    onChange(e);
                    setCursorPosition(e.target.selectionStart);
                }}
                onKeyDown={handleKeyDown}
                onSelect={e => setCursorPosition(e.target.selectionStart)}
                onClick={e => setCursorPosition(e.target.selectionStart)}
                onMouseUp={e => setCursorPosition(e.target.selectionStart)}
                onFocus={e => {
                    if (onFocus) onFocus(e);
                    setCursorPosition(e.target.selectionStart);
                }}
                onBlur={onBlur}
                id={id}
                isRecording={isRecording}
                section={section}
                fixedHeight={fixedHeight}
                borderRounding={borderRounding}
            />
            
            {/* Dot Phrase Dropdown */}
            <DropdownList
                isVisible={dotPhrase.isVisible}
                items={dotPhrase.matchingPhrases}
                selectedIndex={dotPhrase.selectedIndex}
                onItemClick={dotPhrase.handleSelect}
                getItemLabel={item => item}
                getItemDescription={item => dotPhrases[item]?.substring(0, 40) + '...'}
                dropdownRef={dotPhraseDropdownRef}
                position={dotPhrase.dropdownPosition}
            />
            
            {/* Smart List Dropdown */}
            <DropdownList
                isVisible={smartList.isVisible}
                items={smartList.items}
                selectedIndex={smartList.selectedIndex}
                onItemClick={smartList.handleSelect}
                getItemLabel={item => item.length > 70 ? `${item.substring(0, 70)}...` : item}
                dropdownRef={smartListDropdownRef}
                position={smartList.dropdownPosition}
            />
        </div>
    );
});

export default DotPhraseTextArea; 