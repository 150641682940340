import React from 'react';
import PatientNameFormatting from '../common/PatientNameFormatting';
import { renderHighlightedLine } from '../utils/noteHighlightUtils';

const CardItemDisplayView = ({ card, fieldConfig, filledFieldsCount }) => {
    // Function to render text with highlighting
    const renderHighlightedText = (text, fieldId) => {
        if (!text) return '';
        
        // Split the text by newlines and map each line
        return text.split('\n').map((line, index) => 
            renderHighlightedLine(line, index, text, fieldId, false)
        );
    };

    return (
        <div className={`p-4 flex flex-col h-full ${filledFieldsCount <= 2 ? 'min-h-[120px]' : ''}`}>
            {/* Patient Name */}
            <PatientNameFormatting
                text={card.title || 'Untitled Card'} 
                className="font-semibold text-lg mb-2"
            />
            
            {/* Render fields based on configuration */}
            <div className="flex flex-wrap flex-grow">
                {fieldConfig.map((field) => {
                    // Skip title as it's already rendered above
                    if (field.id === 'title') return null;
                    
                    // Get the field value
                    const fieldValue = card[field.id] || '';
                    
                    // Determine if this field should be in a row with another field
                    const containerClass = field.fullWidth 
                        ? `mb-3 w-full ${field.id === 'recommendations' ? 'mt-4' : ''}` 
                        : "flex-1 mb-3 md:mb-0 md:pr-2";
                    
                    // Special handling for the row with Labs/Micro and Antimicrobials
                    if (field.id === 'labs' || field.id === 'antimicrobials') {
                        if (field.id === 'labs') {
                            const labsValue = card.labs || '';
                            const antimicrobialsValue = card.antimicrobials || '';
                            const showLabs = labsValue.trim().length > 0;
                            const showAntimicrobials = antimicrobialsValue.trim().length > 0;
                            
                            // Create the row container if either field has content
                            if (showLabs || showAntimicrobials) {
                                return (
                                    <div key={field.id} className="flex flex-col md:flex-row md:gap-4 mb-3 w-full">
                                        {showLabs && (
                                            <div className={containerClass}>
                                                <h4 className="text-sm font-medium text-gray-400">{field.label}</h4>
                                                <div className="text-white whitespace-pre-line text-sm">
                                                    {renderHighlightedText(labsValue, 'labs')}
                                                </div>
                                            </div>
                                        )}
                                        
                                        {showAntimicrobials && (
                                            <div className="flex-1">
                                                <h4 className="text-sm font-medium text-gray-400">
                                                    {fieldConfig.find(f => f.id === 'antimicrobials').label}
                                                </h4>
                                                <div className="text-white whitespace-pre-line text-sm">
                                                    {renderHighlightedText(antimicrobialsValue, 'antimicrobials')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            return null;
                        } else {
                            // Skip antimicrobials as it's already rendered with labs
                            return null;
                        }
                    }
                    
                    // Skip empty fields in view mode (moved after special handling)
                    if (!fieldValue.trim()) return null;
                    
                    // Show fields with content
                    return (
                        <div key={field.id} className={containerClass}>
                            <h4 className="text-sm font-medium text-gray-400">{field.label}</h4>
                            <div className="text-white whitespace-pre-line text-sm">
                                {renderHighlightedText(fieldValue, field.id)}
                            </div>
                        </div>
                    );
                })}
            </div>
            
            {/* Add placeholder padding for nearly empty cards */}
            {filledFieldsCount <= 1 && (
                <div className="flex-grow"></div>
            )}
        </div>
    );
};

export default CardItemDisplayView; 