import React from 'react';
import SettingsButton from './buttons/SettingsButton';
import CalculatorButton from './buttons/CalculatorButton';
import headerLogo from '../../images/header_mascot.png';

function Header({ onLogout, collapseHeader, currentMode, onModeChange }) {
    return (
        <header className={`fixed top-0 left-0 right-0 z-30 ${collapseHeader ? 'h-12' : 'h-20'} transition-all duration-300`}>
            <div className={`app-bg shadow-md ${collapseHeader ? 'p-1' : 'p-2'} h-full`}>
                <div className="container mx-auto flex items-center justify-between h-full">
                    {/* Left side - Mode switcher */}
                    <div className={`flex items-center space-x-1 
                        ${collapseHeader ? 'ml-16' : 'sm:ml-16 ml-16'}`}>
                        {/* Notes Mode Button */}
                        <button
                            onClick={() => onModeChange('notes')}
                            className={`w-8 h-8 sm:w-10 sm:h-10 rounded flex items-center justify-center
                                ${currentMode === 'notes' 
                                    ? 'primary-button' 
                                    : 'text-gray-400 hover:text-white hover:base-surface'
                                } transform hover:scale-105 flash-button`}
                            title="Note Writing"
                        >
                            <span className="material-symbols-outlined text-lg sm:text-xl">description</span>
                        </button>
                        {/* Documents Mode Button */}
                        <button
                            onClick={() => onModeChange('documents')}
                            className={`w-8 h-8 sm:w-10 sm:h-10 rounded flex items-center justify-center
                                ${currentMode === 'documents' 
                                    ? 'primary-button' 
                                    : 'text-gray-400 hover:text-white hover:base-surface'
                                } transform hover:scale-105 flash-button`}
                            title="Document Writing"
                        >
                            <span className="material-symbols-outlined text-lg sm:text-xl">mail</span>
                        </button>
                        {/* Cards Mode Button */}
                        <button
                            onClick={() => onModeChange('cards')}
                            className={`w-8 h-8 sm:w-10 sm:h-10 rounded flex items-center justify-center
                                ${currentMode === 'cards' 
                                    ? 'primary-button' 
                                    : 'text-gray-400 hover:text-white hover:base-surface'
                                } transform hover:scale-105 flash-button`}
                            title="Cards"
                        >
                            <span className="material-symbols-outlined text-lg sm:text-xl">style</span>
                        </button>
                        {/* Calculator Button */}
                        <CalculatorButton />
                    </div>

                    {/* Center - Logo and title */}
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center pointer-events-none z-10">
                        {!collapseHeader && (
                            <>
                                <img 
                                    src={headerLogo} 
                                    title="Scribbles" 
                                    alt="Scribbles"
                                    className="w-14 h-14 sm:w-16 sm:h-16 md:w-18 md:h-18 lg:w-20 lg:h-20 mr-2 sm:mr-3 object-contain pl-3 sm:pl-4 ml-12 sm:ml-0 pointer-events-none" 
                                />
                                <h1
                                    className="hidden sm:block text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold matemasie-regular text-amber-300 pointer-events-none"
                                    style={{
                                        textShadow: '2px 2px 4px rgba(0,0,0,0.8), 0 0 10px rgba(0,0,0,0.7), 1px 1px 2px rgba(0,0,0,0.9)'
                                    }}
                                >
                                    Honey-Scribe
                                </h1>
                            </>
                        )}
                    </div>

                    {/* Right side - Settings and Logout */}
                    <div className="flex items-center space-x-2">
                        <SettingsButton />
                        <button
                            onClick={onLogout}
                            className={`w-8 h-8 sm:w-10 sm:h-10 rounded
                                alert-button text-white flash-button transition duration-150 ease-in-out flex items-center justify-center`}
                            title="Logout"
                        >
                            <span className="material-symbols-outlined text-lg sm:text-xl">power_settings_new</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`bg-gradient-to-b from-[#121212] to-transparent ${collapseHeader ? 'h-2' : 'h-4'}`}></div>
        </header>
    );
}

export default Header;
