import { BaseApiService } from './base';

export class NotesService extends BaseApiService {
    async fetchNotes() {
        const response = await this.axiosInstance.get('/api/notes');
        return response.data;
    }

    async fetchLastViewedNote() {
        const response = await this.axiosInstance.get('/api/notes/last-viewed');
        return response.data;
    }

    async createNote(note) {
        const response = await this.axiosInstance.post('/api/notes', note);
        return response.data;
    }

    async updateNote(noteId, data) {
        const response = await this.axiosInstance.patch(`/api/notes/${noteId}`, data);
        return response.data;
    }

    async deleteNote(noteId) {
        const response = await this.axiosInstance.delete(`/api/notes/${noteId}`);
        return response.data;
    }

    async deleteAllNotes() {
        const response = await this.axiosInstance.delete('/api/notes');
        return response.data;
    }

    async reorderNotes(noteOrders) {
        const response = await this.axiosInstance.put('/api/notes/reorder', { noteOrders });
        return response.data;
    }

    async updateLastViewedNote(noteId) {
        const response = await this.axiosInstance.post('/api/notes/last-viewed', { noteId });
        return response.data;
    }

    async createBulkNotes(notes) {
        const response = await this.axiosInstance.post('/api/notes/bulk', { notes });
        return response.data;
    }

    async summarizeNote(data) {
        const response = await this.axiosInstance.post('/api/notes/summarize', data);
        return response.data;
    }

    async generateMessage(recommendations, aiProvider) {
        const response = await this.axiosInstance.post('/api/notes/generate-message', {
            recommendations,
            aiProvider
        });
        return response.data;
    }

    async generateDifferential(noteData, aiProvider) {
        const response = await this.axiosInstance.post('/api/notes/generate-differential', {
            ...noteData,
            aiProvider
        });
        return response.data;
    }
} 