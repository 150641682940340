import React, { useState } from 'react';
import CalculatorDialog from '../../Calculator/CalculatorDialog';

function CalculatorButton() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded flex items-center justify-center text-gray-400 hover:text-white hover:base-surface transform hover:scale-105 flash-button"
                title="Calculators and Tools"
            >
                <span className="material-symbols-outlined text-lg sm:text-xl">calculate</span>
            </button>
            {isOpen && <CalculatorDialog isOpen={isOpen} setIsOpen={setIsOpen} />}
        </>
    );
}

export default CalculatorButton; 