import React, { useMemo, useCallback, useState } from 'react';

const parseNote = (note) => {
    const antimicrobialGroups = {};
    const cultures = [];
    const currentYear = new Date().getFullYear();

    // Helper function to parse date strings
    const parseDate = (dateString) => {
        const [month, day, year] = dateString.split('/');
        return new Date(year ? `20${year}` : currentYear, month - 1, day);
    };

    // Parse Antimicrobials section
    const antimicrobialsContent = note.antimicrobials || '';
    const antimicrobialRegex = /([\w-]+)\s+([\d.]+\s*\w+)?\s*(\w+)?\s*\((\d+\/\d+(?:\/\d+)?)(?:-(\d+\/\d+(?:\/\d+)?)?)?\)/g;
    let match;
    while ((match = antimicrobialRegex.exec(antimicrobialsContent)) !== null) {
        const name = match[1].charAt(0).toUpperCase() + match[1].slice(1);
        if (!antimicrobialGroups[name]) {
            antimicrobialGroups[name] = [];
        }
        antimicrobialGroups[name].push({
            dose: match[2],
            frequency: match[3],
            startDate: parseDate(match[4]),
            endDate: match[5] ? parseDate(match[5]) : null,
        });
    }

    // Parse Microbiology section
    const microbiologyContent = note.labs || '';
    const cultureRegex = /(\d+\/\d+\/\d+)\s+blood culture:\s+(.+?)(?::\s*(.+))?$/gm;
    while ((match = cultureRegex.exec(microbiologyContent)) !== null) {
        if (!match[2].includes('In progress') && !match[2].includes('NGTD')) {
            cultures.push({
                date: parseDate(match[1]),
                result: `${match[2]}${match[3] ? `: ${match[3]}` : ''}`,
            });
        }
    }

    return { antimicrobialGroups, cultures };
};

const Tooltip = ({ children, content }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="relative" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
            {children}
            {isVisible && (
                <div className="absolute z-10 p-2 bg-[#242424] text-white text-sm rounded shadow-lg whitespace-pre-wrap">
                    {content}
                </div>
            )}
        </div>
    );
};

// Generate a list of distinct colors
const generateColors = (count) => {
    const hueStep = 360 / count;
    return Array.from({ length: count }, (_, i) => 
        `hsl(${i * hueStep}, 45%, 35%)`
    );
};

function AntimicrobialView({ note }) {
    const { antimicrobialGroups, cultures } = useMemo(() => parseNote(note), [note]);

    // Check if there's data to display
    const hasAntimicrobials = Object.keys(antimicrobialGroups).length > 0;
    const hasCultures = cultures.length > 0;

    // Group cultures by date
    const culturesByDate = useMemo(() => {
        return cultures.reduce((acc, culture) => {
            const dateKey = culture.date.toLocaleDateString();
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(culture);
            return acc;
        }, {});
    }, [cultures]);

    const timelineData = useMemo(() => {
        const allDates = [
            ...Object.values(antimicrobialGroups).flatMap(group =>
                group.flatMap(a => [a.startDate, a.endDate || new Date()])),
            ...cultures.map(c => c.date)
        ];

        const timelineStart = new Date(Math.min(...allDates));
        const timelineEnd = new Date(Math.max(...allDates));
        const totalDays = Math.ceil((timelineEnd - timelineStart) / (1000 * 60 * 60 * 24));

        return { timelineStart, timelineEnd, totalDays };
    }, [antimicrobialGroups, cultures]);

    const getPositionPercentage = useCallback((date) => {
        return ((date - timelineData.timelineStart) / (timelineData.totalDays * 24 * 60 * 60 * 1000)) * 100;
    }, [timelineData]);

    const colors = useMemo(() => {
        const uniqueAntimicrobials = Object.keys(antimicrobialGroups);
        return generateColors(uniqueAntimicrobials.length);
    }, [antimicrobialGroups]);

    const formatTooltipContent = (name, periods) => {
        let content = `${name}\n`;
        periods.forEach((period, index) => {
            const dosageFrequency = [period.dose, period.frequency].filter(Boolean).join(' ');
            if (dosageFrequency) {
                content += `${dosageFrequency}\n`;
            }
            content += `From: ${period.startDate.toLocaleDateString()} - ${period.endDate ? period.endDate.toLocaleDateString() : 'Present'}`;
            if (index < periods.length - 1) {
                content += '\n\n';
            }
        });
        return content;
    };

    // Only render if there's data to display
    if (!hasAntimicrobials && !hasCultures) {
        return null;
    }

    return (
        <div className="space-y-6">
            <hr className="surface-divider-top" />
            <h2 className="text-l font-semibold text-gray-100">Antimicrobial Coverage and Blood Culture Timeline</h2>
            <div className="bg-[#2a2a2a] border surface-border p-4 rounded-lg">
                {hasAntimicrobials && (
                    <>
                        {Object.entries(antimicrobialGroups).map(([name, periods], index) => (
                            <div key={name} className="mb-2">
                                <div className="text-sm text-white font-semibold mb-1">
                                    {name}
                                </div>
                                <Tooltip content={formatTooltipContent(name, periods)}>
                                    <div className="relative h-6 bg-[#323232] rounded">
                                        {periods.map((period, periodIndex) => (
                                            <div
                                                key={periodIndex}
                                                className="absolute h-full rounded"
                                                style={{
                                                    backgroundColor: colors[index],
                                                    left: `${getPositionPercentage(period.startDate)}%`,
                                                    width: `${period.endDate ?
                                                        getPositionPercentage(period.endDate) - getPositionPercentage(period.startDate) :
                                                        100 - getPositionPercentage(period.startDate)}%`,
                                                    opacity: 0.8 + (0.2 / periods.length * (periodIndex + 1)),
                                                }}
                                            ></div>
                                        ))}
                                    </div>
                                </Tooltip>
                            </div>
                        ))}
                    </>
                )}
                {hasCultures && (
                    <>
                        <div className="relative mt-4" style={{ minHeight: '100px' }}>
                            {Object.entries(culturesByDate).map(([date, cultures], index) => (
                                <div key={index} className="absolute" style={{ left: `${getPositionPercentage(new Date(date))}%` }}>
                                    {cultures.map((culture, cultureIndex) => (
                                        <Tooltip key={cultureIndex} content={`${culture.date.toLocaleDateString()}: ${culture.result}`}>
                                            <div
                                                className="w-6 h-6 bg-red-500 rounded-full cursor-pointer hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
                                                style={{
                                                    top: `${cultureIndex * 30}px`,
                                                    transform: 'translateY(-50%)',
                                                }}
                                            >
                                                <span className="material-symbols-outlined text-white">water_do</span>
                                            </div>
                                        </Tooltip>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between mt-2 text-xs text-[#ababab]">
                            <span>{timelineData.timelineStart.toLocaleDateString()}</span>
                            <span>{timelineData.timelineEnd.toLocaleDateString()}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default React.memo(AntimicrobialView); 