import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useSettings } from '../../context/SettingsContext';
import NoteEditorTabs from './NoteEditorTabs';
import NoteEditorContent from './NoteEditorContent';
import NoteSaveButton from './buttons/NoteSaveButton';
import Minimap from './Minimap';
import { debounce } from 'lodash';
import { formatTextWithHeadings, copyToClipboard } from '../utils/noteFormatUtils';
import { useTextPostprocessing } from '../../hooks/useTextPostprocessing';
import { presetSections } from '../data/presetSections';
import { snackbarError } from '../common/Snackbar';
import { sectionLabels } from './NoteEditorSections';

function NoteEditor({ note, updateNote, saveNote, summarizeNote, changedFields, setChangedFields, collapseHeader, updateCurrentNoteLocally }) {
    const { settings } = useSettings();
    const { postProcessText } = useTextPostprocessing();
    const [currentPreset, setCurrentPreset] = useState(note.preset || settings.defaultPreset || 'ID Consult');
    const [activeTab, setActiveTab] = useState('editor');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSummarizing, setIsSummarizing] = useState(false);
    const [actionSuccess, setActionSuccess] = useState({});
    const [isLoading, setIsLoading] = useState({});
    const [currentSessionTranscripts, setCurrentSessionTranscripts] = useState({});
    const [currentTranscript] = useState('');
    const [activeRecordingSection, setActiveRecordingSection] = useState(null);
    const [cursorPositions, setCursorPositions] = useState({});
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [pinnedSection, setPinnedSection] = useState(null);

    const titleRef = useRef(null);
    const consultInfoRef = useRef(null);
    const backgroundRef = useRef(null);
    const hpiRef = useRef(null);
    const medicalHistoryRef = useRef(null);
    const socialHistoryRef = useRef(null);
    const antimicrobialsRef = useRef(null);
    const physicalExamRef = useRef(null);
    const labsRef = useRef(null);
    const imagingRef = useRef(null);
    const recommendationsRef = useRef(null);

    const refs = useMemo(() => ({
        titleRef,
        consultInfoRef,
        backgroundRef,
        hpiRef,
        medicalHistoryRef,
        socialHistoryRef,
        antimicrobialsRef,
        physicalExamRef,
        labsRef,
        imagingRef,
        recommendationsRef
    }), []);

    const handlePresetChange = (preset) => {
        setCurrentPreset(preset);
        handleUpdateNote('preset', preset);
        setHasUnsavedChanges(true);
        setChangedFields(prev => ({ ...prev, preset: true }));
    };

    //Ensures that loading a new note updates from its database entry
    useEffect(() => {
        setCurrentPreset(note.preset || settings.defaultPreset || 'ID Consult');
    }, [note, settings.defaultPreset]);

    const handleSave = useCallback(async () => {
        try {
            setActionSuccess(prev => ({ ...prev, save: 'saving' }));
            const success = await saveNote(changedFields);
            if (success) {
                setActionSuccess(prev => ({ ...prev, save: true }));
                console.log('Note saved successfully');
                setHasUnsavedChanges(false);
                setChangedFields({});
                setTimeout(() => setActionSuccess(prev => ({ ...prev, save: false })), 2000);
            } else {
                console.error('Failed to save note');
                setActionSuccess(prev => ({ ...prev, save: 'error' }));
                setTimeout(() => setActionSuccess(prev => ({ ...prev, save: false })), 2000);
            }
        } catch (err) {
            console.error('Error saving note:', err);
            setActionSuccess(prev => ({ ...prev, save: 'error' }));
            setTimeout(() => setActionSuccess(prev => ({ ...prev, save: false })), 2000);
        }
    }, [saveNote, changedFields, setChangedFields]);

    const debouncedSave = useCallback(() => {
        const saveIfNeeded = debounce(() => {
            if (hasUnsavedChanges && !isSummarizing) {
                handleSave();
            } else if (hasUnsavedChanges && isSummarizing) {
                saveIfNeeded();
            }
        }, 10000);

        saveIfNeeded();
        return () => saveIfNeeded.cancel();
    }, [hasUnsavedChanges, handleSave, isSummarizing]);

    useEffect(() => {
        let cleanup;
        if (hasUnsavedChanges) {
            cleanup = debouncedSave();
        }
        return () => {
            if (cleanup) cleanup();
        };
    }, [hasUnsavedChanges, debouncedSave]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (hasUnsavedChanges) {
                    handleSave();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hasUnsavedChanges, handleSave]);

    const handleUpdateNote = useCallback((section, value) => {
        //console.log(`Updating note section: ${section}, value:`, value);
        
        // Push current state to undo stack before making the update
        setUndoStack(prev => [...prev, { ...note }]);
        setRedoStack([]);

        updateNote(prevNote => {
            const newNote = { ...prevNote, [section]: value };
            return newNote;
        });

        if (!isSummarizing) {
            setHasUnsavedChanges(true);
            setChangedFields(prev => ({ ...prev, [section]: true }));
        }
    }, [updateNote, isSummarizing, setChangedFields, note]);

    const handleUndo = useCallback(() => {
        if (undoStack.length > 0) {
            const prevState = undoStack[undoStack.length - 1];
            setUndoStack(prev => prev.slice(0, -1));
            setRedoStack(prev => [...prev, { ...note }]);
            updateNote(() => ({ ...prevState }));
            setHasUnsavedChanges(true);
            setChangedFields(Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
        }
    }, [undoStack, note, updateNote, setChangedFields]);

    const handleRedo = useCallback(() => {
        if (redoStack.length > 0) {
            const nextState = redoStack[redoStack.length - 1];
            setRedoStack(prev => prev.slice(0, -1));
            setUndoStack(prev => [...prev, { ...note }]);
            updateNote(() => ({ ...nextState }));
            setHasUnsavedChanges(true);
            setChangedFields(Object.keys(nextState).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
        }
    }, [redoStack, note, updateNote, setChangedFields]);

    const handleSummarize = useCallback(async (section) => {
        if (note[section] && note[section].trim() !== '') {
            setIsSummarizing(true);
            setIsLoading(prev => ({ ...prev, [section]: true }));

            setUndoStack(prev => [...prev, { ...note }]);
            setRedoStack([]);

            const summarizedContent = await summarizeNote(section, currentPreset);

            if (summarizedContent !== null) {
                setHasUnsavedChanges(true);
                setChangedFields(prev => ({ ...prev, [section]: true }));
            }

                setIsLoading(prev => ({ ...prev, [section]: false }));
                setIsSummarizing(false);
        } else {
            snackbarError('No content to summarize');
        }
    }, [summarizeNote, currentPreset, setChangedFields, note]);

    const handleSummarizeAll = useCallback(async () => {
        setIsSummarizing(true);
        const sectionsToFormat = presetSections[currentPreset];

        for (const section of sectionsToFormat) {
            if (note[section] && note[section].trim() !== '') {
                setIsLoading(prev => ({ ...prev, [section]: true }));
                await handleSummarize(section);
                setIsLoading(prev => ({ ...prev, [section]: false }));
            }
        }

        setIsSummarizing(false);
    }, [currentPreset, handleSummarize, note]);

    const handleCopySection = useCallback(async (section) => {
        try {
            const sectionContent = note[section] || '';
            const formattedContent = formatTextWithHeadings(sectionContent, section, 'html')
                .map(line => {
                    if (line.trim() === '') return '<br>';
                    return `<p style="font-family: Calibri, sans-serif; font-size: 11pt; margin: 0;">${line}</p>`;
                })
                .join('')
                .replace(/<br><br>/g, '<br>');  // Replace double line breaks with single
            await copyToClipboard(formattedContent, true);
            setActionSuccess(prev => ({ ...prev, [`${section}-copy`]: true }));
            setTimeout(() => setActionSuccess(prev => ({ ...prev, [`${section}-copy`]: false })), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }, [note]);

    const handleTranscriptionUpdate = useCallback((section, transcript, isFinal) => {
        if (isFinal && transcript.trim()) {
            const textArea = document.getElementById(section);
            if (textArea) {
                const cursorPosition = textArea.selectionStart;
                const currentContent = textArea.value || '';
                const trimmedTranscript = postProcessText(transcript.trim());
                
                // Check if transcript starts with punctuation
                const startsWithPunctuation = /^[.,!?;:]/.test(trimmedTranscript);
                const beforeCursor = currentContent.slice(0, cursorPosition);
                const afterCursor = currentContent.slice(cursorPosition);
                
                // Check if transcript ends with punctuation
                const endsWithPunctuation = /[.,!?;:]$/.test(trimmedTranscript);
                
                const needsSpaceBefore = beforeCursor.length > 0 && 
                                       !beforeCursor.endsWith(' ') && 
                                       !beforeCursor.endsWith('\n') &&
                                       !startsWithPunctuation;
                const needsSpaceAfter = afterCursor.length > 0 && 
                                      !afterCursor.startsWith(' ') && 
                                      !afterCursor.startsWith('\n') &&
                                      !endsWithPunctuation;
                
                const newContent = beforeCursor +
                                 (needsSpaceBefore ? ' ' : '') +
                                 trimmedTranscript +
                                 (needsSpaceAfter ? ' ' : '') +
                                 afterCursor;
                
                // Calculate new cursor position before updating state
                const newCursorPosition = cursorPosition + 
                                        (needsSpaceBefore ? 1 : 0) + 
                                        trimmedTranscript.length;

                // First update the textarea value directly to ensure immediate feedback
                textArea.value = newContent;
                textArea.setSelectionRange(newCursorPosition, newCursorPosition);
                textArea.focus();

                // Then update the state using updateCurrentNoteLocally for synchronous update
                updateCurrentNoteLocally(section, newContent);
                setHasUnsavedChanges(true);
            }
        }
    }, [updateCurrentNoteLocally, postProcessText]);

    const startRecordingSession = useCallback((section) => {
        const textArea = document.getElementById(section);
        if (textArea) {
            setCursorPositions(prev => ({
                ...prev,
                [section]: textArea.selectionStart
            }));
        }
        setActiveRecordingSection(section);
    }, []);

    const endRecordingSession = useCallback((section) => {
        const textArea = document.getElementById(section);
        if (textArea) {
            const cursorPosition = cursorPositions[section];
            const currentContent = note[section] || '';
            const transcriptContent = currentSessionTranscripts[section].trim();

            if (transcriptContent) {
                const newContent =
                    currentContent.slice(0, cursorPosition) +
                    (cursorPosition > 0 && !currentContent.slice(cursorPosition - 1, cursorPosition).match(/\s/) ? ' ' : '') +
                    transcriptContent +
                    (cursorPosition < currentContent.length && !currentContent.slice(cursorPosition, cursorPosition + 1).match(/\s/) ? ' ' : '') +
                    currentContent.slice(cursorPosition);

                handleUpdateNote(section, newContent.trim());

                const newCursorPosition = cursorPosition + transcriptContent.length + 1;
                setTimeout(() => {
                    textArea.setSelectionRange(newCursorPosition, newCursorPosition);
                    textArea.focus();
                }, 0);

                setCurrentSessionTranscripts(prev => ({
                    ...prev,
                    [section]: ''
                }));
            }
        }
        setActiveRecordingSection(null);
    }, [handleUpdateNote, currentSessionTranscripts, cursorPositions, note]);

    //Event listener for the undo/redo stack
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'z') {
                event.preventDefault();
                handleUndo();
            } else if (event.ctrlKey && event.key === 'y') {
                event.preventDefault();
                handleRedo();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleUndo, handleRedo]);

    const handleScrollToSection = useCallback((sectionId) => {
        const ref = refs[`${sectionId}Ref`];
        if (ref?.current) {
            const headerHeight = collapseHeader ? 64 : 112; // Increased height to account for title card
            const element = ref.current;
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;
            
            window.scrollTo({
                top: absoluteElementTop - headerHeight - 24, // Increased padding for better spacing
                behavior: 'smooth'
            });
        }
    }, [refs, collapseHeader]);

    return (
        <div className="min-h-screen app-bg pt-24 sm:pt-28">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
                <NoteEditorTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    todoCount={note.todos.length}
                />
                <NoteEditorContent
                    actionSuccess={actionSuccess}
                    activeTab={activeTab}
                    note={note}
                    currentPreset={currentPreset}
                    updateNote={handleUpdateNote}
                    summarizeNote={handleSummarize}
                    isLoading={isLoading}
                    handleCopySection={handleCopySection}
                    handleTranscriptionUpdate={handleTranscriptionUpdate}
                    startRecordingSession={startRecordingSession}
                    endRecordingSession={endRecordingSession}
                    currentSessionTranscripts={currentSessionTranscripts}
                    currentTranscript={currentTranscript}
                    activeRecordingSection={activeRecordingSection}
                    presetSections={presetSections}
                    refs={refs}
                    handlePresetChange={handlePresetChange}
                    handleSummarizeAll={handleSummarizeAll}
                    isSummarizing={isSummarizing}
                    pinnedSection={pinnedSection}
                    setPinnedSection={setPinnedSection}
                    collapseHeader={collapseHeader}
                />
            </div>
            {activeTab === 'editor' && (
                <Minimap
                    sections={presetSections[currentPreset]?.map(section => ({
                        id: section,
                        title: sectionLabels[section],
                        isCollapsed: note.collapsedSections?.[section]
                    }))}
                    onSectionClick={handleScrollToSection}
                    collapseHeader={collapseHeader}
                />
            )}
            <NoteSaveButton
                handleSave={handleSave}
                actionSuccess={actionSuccess}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        </div>
    );
}

export default React.memo(NoteEditor);