import React, { useState, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import BaseModal from '../../common/BaseModal';

const CodeDescription = ({ code }) => {
    const [descriptions, setDescriptions] = useState([]);
    
    useEffect(() => {
        const fetchDescriptions = async () => {
            const codes = code.split(',').map(c => c.trim());
            const results = [];
            
            for (const singleCode of codes) {
                try {
                    const response = await fetch(
                        `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${encodeURIComponent(singleCode)}`
                    );
                    const [, , , data] = await response.json();
                    const description = data.find(([c]) => c.toLowerCase() === singleCode.toLowerCase())?.[1] || '';
                    results.push({
                        code: singleCode,
                        description
                    });
                } catch (error) {
                    console.error('Error fetching ICD-10 description:', error);
                    results.push({
                        code: singleCode,
                        description: ''
                    });
                }
            }
            setDescriptions(results);
        };

        fetchDescriptions();
    }, [code]);

    return descriptions.length ? (
        <div className="text-sm text-[#ababab] italic ml-4 space-y-1">
            {descriptions.map(({ code, description }) => (
                <div key={code}>
                    {code} - {description}
                </div>
            ))}
        </div>
    ) : null;
};

function BillingButton({ assessment, updateNote }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [activeSearch, setActiveSearch] = useState(null);
    const [editingDiagnosis, setEditingDiagnosis] = useState(null);
    const [selectedCodeDetails, setSelectedCodeDetails] = useState({});
    const selectionRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (isModalOpen) {
            setSearchTerm('');
            setSearchResults([]);
            setActiveSearch(null);
            setEditingDiagnosis(null);
            setSelectedCodeDetails({});
        }
    }, [isModalOpen]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSearchResults([]);
            }
        }

        if (searchResults.length > 0) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [searchResults.length]);

    const findDiagnoses = (text) => {
        const regex = /#(.*?)(?:\(([A-Z][0-9.]+[A-Za-z0-9]*(?:\s*,\s*[A-Z][0-9.]+[A-Za-z0-9]*)*)\)|(?=\n|$))/g;
        const matches = [...text.matchAll(regex)];
        return matches.map(match => ({
            diagnosis: match[1].trim(),
            codes: match[2]?.split(',').map(code => code.trim()) || [],
            position: match.index,
            length: match[0].length
        }));
    };

    const searchICD10 = async (term) => {
        if (!term) {
            setSearchResults([]);
            return;
        }
        try {
            const response = await fetch(
                `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${encodeURIComponent(term)}&maxList=200`
            );
            const [, , , data] = await response.json(); //If needed, use total, codes as first two parameters
            const formattedResults = data.map(([code, name]) => ({
                code,
                name
            }));
            setSearchResults(formattedResults);
        } catch (error) {
            console.error('Error fetching ICD-10 codes:', error);
        }
    };

    const toggleICDCode = (code, name) => {
        if (!activeSearch) return;

        if (!activeSearch.codes.includes(code)) {
            setSelectedCodeDetails(prev => ({
                ...prev,
                [code]: name
            }));
        }

        const codes = activeSearch.codes.includes(code)
            ? activeSearch.codes.filter(c => c !== code)
            : [...activeSearch.codes, code];
        
        const beforeText = assessment.substring(0, activeSearch.position);
        const afterText = assessment.substring(activeSearch.position + activeSearch.length);
        const diagnosisWithCode = codes.length > 0 
            ? `#${activeSearch.diagnosis} (${codes.join(', ')})`
            : `#${activeSearch.diagnosis}`;
        
        const updatedText = beforeText + diagnosisWithCode + afterText;
        updateNote("recommendations", updatedText);
        
        // Update activeSearch with new codes
        setActiveSearch({
            ...activeSearch,
            codes,
            length: diagnosisWithCode.length
        });
    };

    const diagnoses = findDiagnoses(assessment);

    const debouncedSearch = useMemo(
        () => debounce((term) => {
            searchICD10(term);
        }, 200),
        []
    );

    return (
        <>
            <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="flex items-center justify-center px-2 py-1 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
                title="Manage ICD-10 Codes"
            >
                <span className="material-icons text-white text-base">receipt_long</span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                    ICD-10
                </span>
            </button>

            <BaseModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title="Manage ICD-10 Codes"
                maxWidth="2xl"
                className="max-h-[90vh] flex flex-col"
            >
                <div className="mb-4 overflow-y-auto flex-1">
                    <div className="space-y-6 relative">
                        {diagnoses.map((diag, index) => (
                            <div key={index} 
                                className="flex flex-col gap-2 p-4 bg-[#242424]/50 rounded-lg border surface-border">
                                <div className="flex flex-wrap items-center gap-2">
                                    <span className="text-white font-medium break-all">{diag.diagnosis}</span>
                                    {editingDiagnosis?.diagnosis !== diag.diagnosis && (
                                        <button
                                            onClick={async () => {
                                                setActiveSearch(diag);
                                                setEditingDiagnosis(diag);
                                                setSearchTerm('');
                                                searchICD10('');
                                                
                                                // Load descriptions for existing codes
                                                if (diag.codes.length > 0) {
                                                    try {
                                                        const codeDetails = {};
                                                        for (const code of diag.codes) {
                                                            const response = await fetch(
                                                                `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${encodeURIComponent(code)}`
                                                            );
                                                            const [, , , data] = await response.json();
                                                            codeDetails[code] = data.find(([c]) => c.toLowerCase() === code.toLowerCase())?.[1] || '';
                                                        }
                                                        setSelectedCodeDetails(prev => ({
                                                            ...prev,
                                                            ...codeDetails
                                                        }));
                                                    } catch (error) {
                                                        console.error('Error fetching ICD-10 descriptions:', error);
                                                    }
                                                }
                                            }}
                                            className="px-2 py-1 text-xs primary-button text-white rounded shrink-0 flash-button"
                                        >
                                            {diag.codes.length > 0 ? 'Change Codes' : 'Add Codes'}
                                        </button>
                                    )}
                                </div>
                                
                                {editingDiagnosis?.diagnosis === diag.diagnosis ? (
                                    <div className="ml-4" ref={selectionRef}>
                                        <div className="flex flex-col gap-2">
                                            {/* Show current selections at the top */}
                                            {activeSearch.codes.length > 0 && (
                                                <div className="p-2 base-surface rounded">
                                                    <div className="text-xs text-[#ababab] mb-1">Current Selections:</div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {activeSearch.codes.map(code => (
                                                            <div 
                                                                key={code} 
                                                                className="flex items-center gap-1 bg-[#323232] px-2 py-1 rounded text-sm"
                                                            >
                                                                <div className="flex flex-col">
                                                                    <span className="text-white">{code}</span>
                                                                    <span className="text-xs text-[#ababab]">{selectedCodeDetails[code]}</span>
                                                                </div>
                                                                <button
                                                                    onClick={() => toggleICDCode(code)}
                                                                    className="text-[#ababab] hover:text-white ml-1"
                                                                >
                                                                    <span className="material-icons text-sm">close</span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            
                                            {/* Search interface */}
                                            <div className="flex items-center gap-2">
                                                <div className="relative flex-1">
                                                    <input
                                                        type="text"
                                                        value={searchTerm}
                                                        onChange={(e) => {
                                                            setSearchTerm(e.target.value);
                                                            debouncedSearch(e.target.value);
                                                        }}
                                                        placeholder="Search ICD-10 codes..."
                                                        className="w-full p-2 base-surface text-white rounded text-sm"
                                                        autoFocus
                                                    />

                                                    {searchResults.length > 0 && (
                                                        <div 
                                                            ref={dropdownRef} 
                                                            className="fixed mt-1 base-surface rounded shadow-lg z-[60] min-w-[300px] max-w-[500px] max-h-[200px] overflow-y-auto" 
                                                            style={{
                                                                width: selectionRef.current?.querySelector('input')?.offsetWidth,
                                                                left: selectionRef.current?.querySelector('input')?.getBoundingClientRect().left,
                                                                top: selectionRef.current?.querySelector('input')?.getBoundingClientRect().bottom,
                                                            }}>
                                                            {searchResults.map((result) => (
                                                                <div
                                                                    key={result.code}
                                                                    className="p-2 hover:bg-[#323232] text-white text-sm cursor-pointer"
                                                                    onClick={() => toggleICDCode(result.code, result.name)}
                                                                >
                                                                    <span>{result.code} - {result.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                <button
                                                    onClick={() => {
                                                        setEditingDiagnosis(null);
                                                        setActiveSearch(null);
                                                        setSearchResults([]);
                                                        setSearchTerm('');
                                                    }}
                                                    className="px-3 py-1.5 text-sm base-surface hover:bg-[#323232] text-white rounded border surface-border flash-button"
                                                >
                                                    Done
                                                </button>

                                                <button
                                                    onClick={() => {
                                                        setEditingDiagnosis(null);
                                                        setActiveSearch(null);
                                                        setSearchResults([]);
                                                        setSearchTerm('');
                                                    }}
                                                    className="p-1.5 text-[#ababab] hover:text-white rounded hover:base-surface"
                                                >
                                                    <span className="material-icons text-sm">close</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : diag.codes.length > 0 && (
                                    <CodeDescription code={diag.codes.join(', ')} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                
                <div className="flex justify-end mt-auto pt-4">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 rounded-md border surface-border base-surface hover:bg-[#323232] transition duration-150 ease-in-out text-[#e0e0e0] flash-button"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Close
                    </button>
                </div>
            </BaseModal>
        </>
    );
}

export default BillingButton;  