import React, { useState, useEffect, useRef, useMemo } from 'react';
import commonDocuments from '../../data/commonDocuments';

function CommonDocumentsSelector({ onSelect, documentPreset }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const selectorRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState('bottom');

    // Get unique categories from the common documents, memoized to prevent re-renders
    const categories = useMemo(() => {
        return ['all', ...new Set(commonDocuments.map(doc => doc.category))];
    }, []);
    
    // Initialize selectedCategory with documentPreset if it's a valid category
    const [selectedCategory, setSelectedCategory] = useState(() => {
        if (documentPreset && documentPreset.toLowerCase) {
            const presetCategory = documentPreset.toLowerCase();
            if (categories.includes(presetCategory)) {
                return presetCategory;
            }
        }
        return 'all';
    });

    // Update selectedCategory when documentPreset changes
    useEffect(() => {
        if (documentPreset && documentPreset.toLowerCase) {
            const presetCategory = documentPreset.toLowerCase();
            if (categories.includes(presetCategory)) {
                setSelectedCategory(presetCategory);
            }
        }
    }, [documentPreset, categories]);

    // Filter documents based on search term and selected category
    useEffect(() => {
        let filtered = commonDocuments;
        
        // Filter by document preset if provided
        if (documentPreset) {
            const presetCategory = documentPreset.toLowerCase();
            // If the preset matches a category, prioritize those documents
            if (categories.includes(presetCategory) && presetCategory !== 'all') {
                filtered = [
                    ...filtered.filter(doc => doc.category === presetCategory),
                    ...filtered.filter(doc => doc.category !== presetCategory)
                ];
            }
        }
        
        // Filter by selected category
        if (selectedCategory !== 'all') {
            filtered = filtered.filter(doc => doc.category === selectedCategory);
        }
        
        // Filter by search term
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            filtered = filtered.filter(
                doc => doc.label.toLowerCase().includes(term) || 
                       doc.text.toLowerCase().includes(term)
            );
        }
        
        setFilteredDocuments(filtered);
    }, [searchTerm, selectedCategory, documentPreset, categories]);

    // Close the dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (selectorRef.current && !selectorRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        }
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Add this new effect to calculate dropdown position
    useEffect(() => {
        if (!isExpanded || !selectorRef.current) return;
        
        const buttonRect = selectorRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - buttonRect.bottom;
        const spaceAbove = buttonRect.top;
        
        // If space below is less than 300px and we have more space above, position dropdown above
        if (spaceBelow < 300 && spaceAbove > spaceBelow) {
            setDropdownPosition('top');
        } else {
            setDropdownPosition('bottom');
        }
    }, [isExpanded]);

    const handleSelect = (document) => {
        onSelect(document.text);
        setIsExpanded(false);
        setSearchTerm('');
    };

    return (
        <div ref={selectorRef} className="relative">
            <button 
                className="flex items-center px-3 py-1.5 rounded-md hover:text-white hover:bg-[#323232] transition-colors duration-200"
                onClick={() => setIsExpanded(!isExpanded)}
                title="Quick Prompts"
            >
                <span className="material-symbols-outlined mr-1">smart_button</span>
                <span className="text-sm">Quick Prompts</span>
            </button>
            
            {isExpanded && (
                <div 
                    className={`absolute z-10 w-80 md:w-96 right-0 base-surface rounded-md shadow-lg border border-[#323232] ${
                        dropdownPosition === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'
                    }`}
                    style={{
                        maxHeight: '50vh',
                        overflow: 'auto'
                    }}
                >
                    <div className="p-2">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search prompts..."
                            className="w-full input-base rounded-md p-2 mb-2 sticky top-0"
                            autoFocus
                        />
                        
                        <div className="flex flex-wrap gap-1 mb-2 sticky top-[52px] bg-inherit pt-1">
                            {categories.map(category => (
                                <button
                                    key={category}
                                    onClick={() => setSelectedCategory(category)}
                                    className={`px-2 py-1 text-xs rounded-full ${
                                        selectedCategory === category
                                            ? 'bg-[#1976d2] text-white'
                                            : 'bg-[#323232] text-[#ababab] hover:bg-[#424242]'
                                    }`}
                                >
                                    {category.charAt(0).toUpperCase() + category.slice(1)}
                                </button>
                            ))}
                        </div>
                        
                        <div className="max-h-60 overflow-y-auto">
                            {filteredDocuments.length > 0 ? (
                                filteredDocuments.map(doc => (
                                    <div
                                        key={doc.id}
                                        onClick={() => handleSelect(doc)}
                                        className="p-2 hover:bg-[#323232] cursor-pointer rounded-md"
                                    >
                                        <div className="font-medium text-white">{doc.label}</div>
                                        <div className="text-sm text-[#ababab] truncate">{doc.text}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="p-2 text-[#ababab]">No matching prompts found</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CommonDocumentsSelector; 