import { useCallback } from 'react';

export const usePhraseNavigation = (
    value, 
    setIsVisible, 
    selectedIndex, 
    setSelectedIndex, 
    items, 
    handleItemSelect,
    ref, 
    cursorPosition,
    setCursorPosition
) => {
    // Navigate through highlighted items with F2
    const handleF2Navigation = useCallback((e) => {
        e.preventDefault();
        const curPos = e.target.selectionStart;
        const navigablePositions = [];
        let searchPos = 0;
        
        // Find all *** markers
        let found;
        while ((found = value.indexOf('***', searchPos)) !== -1) {
            navigablePositions.push({
                position: found,
                length: 3,
                type: 'asterisk'
            });
            searchPos = found + 1;
        }
        
        // Find all {listName} patterns using the improved pattern matching
        const textToCheck = value;
        for (let i = 0; i < textToCheck.length; i++) {
            if (textToCheck[i] === '{') {
                // Found an opening brace, check if it's a complete smart list pattern
                const potentialPattern = textToCheck.substring(i);
                const match = potentialPattern.match(/^\{([a-zA-Z]+)\}/);
                
                if (match) {
                    navigablePositions.push({
                        position: i,
                        length: match[0].length,
                        type: 'smartList',
                        listName: match[1]
                    });
                    
                    // Skip ahead to after this pattern
                    i += match[0].length - 1;
                }
            }
        }
        
        // Sort positions by their location in the text
        navigablePositions.sort((a, b) => a.position - b.position);
        
        if (navigablePositions.length > 0) {
            // Find the next position after cursor
            const nextIndex = navigablePositions.findIndex(item => item.position > curPos);
            const targetItem = nextIndex === -1 
                ? navigablePositions[0] // Wrap around to the beginning
                : navigablePositions[nextIndex];
            
            // Set selection to the found position
            e.target.setSelectionRange(targetItem.position, targetItem.position + targetItem.length);
            
            // Create a temporary element to mark the target position
            const tempElement = document.createElement('div');
            tempElement.style.position = 'absolute';
            tempElement.style.height = '1px';
            tempElement.style.width = '1px';
            tempElement.style.opacity = '0';
            
            // Insert the temporary element at the target position
            const textBeforeTarget = value.substring(0, targetItem.position);
            const lines = textBeforeTarget.split('\n');
            const targetLine = lines[lines.length - 1];
            
            // Create a temporary textarea to measure the exact position. This is a bit 'hacky' but it works across all screen sizes for now.
            const measureTextarea = document.createElement('textarea');
            measureTextarea.style.cssText = window.getComputedStyle(e.target).cssText;
            measureTextarea.style.position = 'absolute';
            measureTextarea.style.visibility = 'hidden';
            measureTextarea.style.height = 'auto';
            measureTextarea.value = targetLine;
            document.body.appendChild(measureTextarea);
            
            // Calculate the exact position
            const lineHeight = measureTextarea.scrollHeight;
            const targetPosition = (lines.length - 1) * lineHeight;
            
            // Clean up the measurement textarea
            document.body.removeChild(measureTextarea);
            
            // Scroll to the calculated position
            e.target.scrollTop = Math.max(0, targetPosition - (e.target.clientHeight / 2));
            
            // Update cursor position to trigger the dropdown
            if (setCursorPosition) {
                setCursorPosition(targetItem.position);
            }
        }
    }, [value, setCursorPosition]);

    // Handle dropdown navigation (up/down/enter/escape)
    const handleDropdownNavigation = useCallback((e, isVisible) => {
        if (!isVisible || !items.length) return false;
        
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
                return true;
            case 'ArrowUp':
                e.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
                return true;
            case 'Enter':
                e.preventDefault();
                if (items[selectedIndex]) {
                    handleItemSelect(items[selectedIndex]);
                }
                return true;
            case 'Escape':
                e.preventDefault();
                setIsVisible(false);
                return true;
            default:
                return false;
        }
    }, [items, selectedIndex, setSelectedIndex, setIsVisible, handleItemSelect]);

    return {
        handleF2Navigation,
        handleDropdownNavigation
    };
}; 