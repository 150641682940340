import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

function DropdownContent({
    items,
    selectedIndex,
    onItemClick,
    getItemLabel = (item) => item,
    getItemDescription = () => null,
    dropdownRef,
    position
}) {
    return (
        <div
            ref={dropdownRef}
            className="fixed z-[9999] bg-[#1e1e1e] rounded-md"
            style={{
                top: `${position.top}px`,
                left: `${position.left}px`,
                width: 'max-content',
                minWidth: `${position.width * 0.5}px`,
                maxWidth: `${position.width}px`,
                maxHeight: '200px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.3), 0 0 1px rgba(0,0,0,0.5)',
            }}
        >
            <div 
                className="overflow-y-auto"
                style={{
                    maxHeight: '200px',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#666666 transparent',
                    WebkitScrollbarWidth: '6px',
                    WebkitScrollbarTrack: 'transparent',
                    WebkitScrollbarThumb: '#666666',
                    WebkitOverflowScrolling: 'touch',
                    msOverflowStyle: '-ms-autohiding-scrollbar',
                    overscrollBehavior: 'contain'
                }}
                onTouchStart={(e) => {
                    e.stopPropagation();
                }}
                onTouchMove={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="py-1">
                    {items.map((item, index) => {
                        const description = getItemDescription(item);
                        return (
                            <div
                                key={typeof item === 'object' ? item.id : item}
                                data-index={index}
                                className={`px-2 py-1 cursor-pointer transition-colors duration-150 ease-in-out ${
                                    index === selectedIndex
                                        ? 'bg-[#1976d2] text-white ' + (index === 0 ? 'rounded-t-md' : '')
                                        : 'text-[#e0e0e0] hover:bg-[#2a2a2a]'
                                }`}
                                onClick={() => onItemClick(item)}
                            >
                                <span className="font-medium truncate block text-sm">{getItemLabel(item)}</span>
                                {description && (
                                    <span className="block text-xs text-white opacity-70 truncate italic">
                                        {description}
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function DropdownList({ isVisible, ...props }) {
    useEffect(() => {
        if (props.dropdownRef.current) {
            const container = props.dropdownRef.current;
            const selectedElement = container.querySelector(`[data-index="${props.selectedIndex}"]`);
            if (selectedElement) {
                selectedElement.scrollIntoView({ block: 'nearest' });
            }
        }
    }, [props.selectedIndex, props.dropdownRef]);

    if (!isVisible) return null;

    return ReactDOM.createPortal(
        <DropdownContent {...props} />,
        document.body
    );
}

export default DropdownList; 