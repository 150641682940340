import React, { useState } from 'react';

const ANCCalculator = () => {
    const [wbc, setWbc] = useState('');
    const [neutrophils, setNeutrophils] = useState('');
    const [bands, setBands] = useState('');
    const [ancResult, setAncResult] = useState(null);
    const [interpretation, setInterpretation] = useState('');

    const calculateANC = () => {
        if (!wbc || !neutrophils) return;

        const wbcNum = parseFloat(wbc);
        const neutrophilsNum = parseFloat(neutrophils);
        const bandsNum = parseFloat(bands || '0');

        if (wbcNum <= 0) {
            alert("WBC must be greater than zero");
            return;
        }

        if (neutrophilsNum < 0 || neutrophilsNum > 100 || bandsNum < 0 || bandsNum > 100) {
            alert("Percentages must be between 0 and 100");
            return;
        }

        // ANC = WBC × (% neutrophils + % bands) / 100
        const anc = wbcNum * (neutrophilsNum + bandsNum) / 100;
        
        // Display ANC value multiplied by 1000
        const displayAnc = anc * 1000;
        const roundedAnc = Math.round(displayAnc * 100) / 100;
        setAncResult(roundedAnc);

        // Interpretation based on common clinical thresholds (still using original ANC value)
        if (anc < 0.5) {
            setInterpretation("Severe neutropenia");
        } else if (anc < 1.0) {
            setInterpretation("Moderate neutropenia");
        } else if (anc < 1.5) {
            setInterpretation("Mild neutropenia");
        } else if (anc >= 1.5 && anc <= 8.0) {
            setInterpretation("Normal ANC");
        }
    };

    const copyToClipboard = () => {
        if (ancResult === null) return;
        navigator.clipboard.writeText(`ANC: ${ancResult} cells/μL - ${interpretation}`);
    };

    return (
        <div className="p-4">
            <div className="space-y-3">
                <div className="flex flex-col md:flex-row md:items-end md:space-x-2 space-y-3 md:space-y-0">
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">WBC (cells/mm³)</label>
                        <input
                            value={wbc}
                            onChange={(e) => setWbc(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Neutrophils (%)</label>
                        <input
                            value={neutrophils}
                            onChange={(e) => setNeutrophils(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>
                    <div className="w-32">
                        <label className="block text-gray-300 text-sm mb-1">Bands (%) [Optional]</label>
                        <input
                            value={bands}
                            onChange={(e) => setBands(e.target.value)}
                            className="w-full h-10 p-2 rounded-md input-base"
                            placeholder="0"
                        />
                    </div>

                    <button
                        onClick={calculateANC}
                        className="px-6 py-2 primary-button text-white rounded-md flash-button w-full md:w-auto"
                    >
                        Calculate
                    </button>
                </div>

                {ancResult !== null && (
                    <div className="mt-8 p-3 base-surface rounded-md">
                        <div className="flex items-center justify-between">
                            <div>
                                <div>
                                    <span className="text-gray-400 text-sm">ANC:</span>
                                    <span className="text-gray-100 ml-2">{ancResult} cells/μL</span>
                                </div>
                                <div>
                                    <span className="text-gray-400 text-sm">Interpretation:</span>
                                    <span className="text-gray-100 ml-2">{interpretation}</span>
                                </div>
                            </div>
                            <button
                                onClick={copyToClipboard}
                                className="px-3 py-1 base-button flash-button rounded-md text-sm"
                            >
                                <span className="material-symbols-outlined text-white text-sm mr-1">
                                    file_copy
                                </span>
                                Copy
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ANCCalculator; 