import { useState, useEffect, useCallback, useRef } from 'react';
import apiService from '../services/api';

// Cache for cards data
let cardsCache = null;
let lastFetchTimestamp = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const useCards = () => {
    const [cards, setCards] = useState(cardsCache || []);
    const [currentCard, setCurrentCard] = useState(null);
    const [isLoading, setIsLoading] = useState(!cardsCache);
    const [transcribingCardId, setTranscribingCardId] = useState(null);
    const [activeAttestationId, setActiveAttestationId] = useState(null);
    const initialFetchDone = useRef(false);


    const invalidateCache = useCallback(() => {
        cardsCache = null;
        lastFetchTimestamp = null;
        initialFetchDone.current = false;
    }, []);

    const shouldRefetch = useCallback(() => {
        return !cardsCache || !lastFetchTimestamp || (Date.now() - lastFetchTimestamp > CACHE_DURATION);
    }, []);

    const fetchCards = useCallback(async (force = false) => {
        // Skip fetching if:
        // 1. We're not forcing a refresh AND
        // 2. Either we've already fetched once in this session OR we shouldn't refetch based on cache
        if (!force && (initialFetchDone.current || !shouldRefetch())) {
            return;
        }

        try {
            setIsLoading(true);
            const fetchedCards = await apiService.fetchCards();
            
            // Only update cache and timestamp when actually fetching from server
            cardsCache = fetchedCards;
            lastFetchTimestamp = Date.now();
            setCards(fetchedCards);
            initialFetchDone.current = true;
        } catch (error) {
            invalidateCache();
        } finally {
            setIsLoading(false);
        }
    }, [shouldRefetch, invalidateCache]);

    useEffect(() => {
        fetchCards();
        
        // Return cleanup function to reset fetchCards when component unmounts
        return () => {
            initialFetchDone.current = false;
        };
    }, [fetchCards]);

    const createCard = useCallback(async () => {
        const newCard = {
            title: '',
            consultInfo: '',
            background: '',
            labs: '',
            imaging: '',
            antimicrobials: '',
            recommendations: '',
            attestation: '',
            supervisee: '',
            seen: true,
            modality: 'in-person'
        };

        // For the first card, ensure the cache is initialized
        if (!cardsCache) {
            cardsCache = [];
            lastFetchTimestamp = Date.now();
        }

        // Create optimistic card with temporary ID
        const tempCard = {
            ...newCard,
            id: Date.now(), // Use timestamp as temporary ID for consistency
            isOptimistic: true
        };
        
        // Optimistically update local state
        setCards(prevCards => [...prevCards, tempCard]);
        setCurrentCard(tempCard);
        
        try {
            const createdCard = await apiService.createCard(newCard);
            
            // Update both state and cache with the server-generated ID
            setCards(prevCards => {
                const updatedCards = prevCards.map(card =>
                    card.id === tempCard.id ? createdCard : card
                );
                // Update cache directly without affecting timestamp
                if (cardsCache) {
                    cardsCache = updatedCards;
                }
                return updatedCards;
            });
            setCurrentCard(createdCard);
            
            return createdCard;
        } catch (error) {
            console.error('Error creating card:', error);
            // Revert the optimistic update
            setCards(prevCards => 
                prevCards.filter(card => card.id !== tempCard.id)
            );
            setCurrentCard(null);
            throw error;
        }
    }, []);

    const deleteCard = useCallback(async (cardId) => {
        // Ensure consistent ID format for comparison
        const currentId = currentCard?.id?.toString();
        const targetId = cardId?.toString();
        const wasCurrentCard = currentId === targetId;

        // Store previous state for rollback
        const previousCards = [...cards];
        const previousCache = cardsCache ? [...cardsCache] : null;

        // Optimistically update state and cache
        const updatedCards = cards.filter(card => card.id?.toString() !== targetId);
        setCards(updatedCards);
        
        // Update cache but keep the timestamp
        if (cardsCache) {
            cardsCache = updatedCards;
        }
        
        // Handle current card selection optimistically
        if (wasCurrentCard) {
            setCurrentCard(null);
        }

        try {
            await apiService.deleteCard(cardId);
        } catch (error) {
            // Restore previous state on error
            console.error('Error deleting card:', error);
            setCards(previousCards);
            cardsCache = previousCache;
            if (wasCurrentCard) {
                const originalCard = previousCards.find(card => card.id?.toString() === targetId);
                if (originalCard) {
                    setCurrentCard(originalCard);
                }
            }
            throw error;
        }
    }, [currentCard?.id, cards]);

    const deleteAllCards = useCallback(async () => {
        // Store previous state for rollback
        const previousCards = [...cards];
        const previousCache = cardsCache ? [...cardsCache] : null;
        const previousCurrentCard = currentCard;

        // Optimistically update state and cache
        setCards([]);
        setCurrentCard(null);
        cardsCache = [];
        lastFetchTimestamp = Date.now();

        try {
            await apiService.deleteAllCards();
        } catch (error) {
            console.error('Error deleting all cards:', error);
            // Restore previous state on error
            setCards(previousCards);
            cardsCache = previousCache;
            setCurrentCard(previousCurrentCard);
            throw error;
        }
    }, [cards, currentCard]);

    // Enhanced updateCard function that also handles API calls
    const updateCardWithAPI = useCallback(async (cardId, updatedCardData) => {
        // Store previous state for rollback
        const previousCards = [...cards];
        
        try {
            // Get the current card to check if there are real changes
            const currentCard = cards.find(card => card.id === cardId);
            if (!currentCard) {
                console.error("Card not found for update:", cardId);
                return null;
            }
            
            // Check if there are actual changes (comparing only the data fields, not metadata)
            const relevantFields = ['title', 'consultInfo', 'background', 'labs', 'imaging', 'antimicrobials', 'recommendations', 'supervisee', 'attestation', 'seen', 'modality'];
            
            // Perform a more thorough check using JSON stringify
            const currentCardData = {};
            const newCardData = {};
            
            // Extract only the relevant fields for comparison
            relevantFields.forEach(field => {
                currentCardData[field] = currentCard[field] || '';
                newCardData[field] = updatedCardData[field] || '';
            });
            
            const hasRealChanges = JSON.stringify(currentCardData) !== JSON.stringify(newCardData);
            
            if (!hasRealChanges) {
                // If no real changes, skip the update and API call
                return currentCard;
            }
            
            // Update backend first before updating local state to reduce potential race conditions
            const serverUpdatedCard = await apiService.updateCard(cardId, updatedCardData);
            
            // Update state with the server response using functional update
            setCards(prevCards => {
                const finalUpdatedCards = prevCards.map(card => 
                    card.id === cardId ? serverUpdatedCard : card
                );
                
                // Update cache with the new state
                if (cardsCache) {
                    cardsCache = finalUpdatedCards;
                }
                
                return finalUpdatedCards;
            });
            
            return serverUpdatedCard;
        } catch (error) {
            console.error('Error updating card:', error);
            // Revert to original state on error
            if (cardsCache) {
                cardsCache = previousCards;
            }
            setCards(previousCards);
            throw error;
        }
    }, [cards]);

    const reorderCards = useCallback(async (newCards) => {
        // Store previous state for rollback
        const previousCards = [...cards];
        
        try {
            // UI is already updated in the component before this function is called
            // Now update the backend
            await Promise.all(newCards.map((card, index) => 
                apiService.reorderCard(card.id, index)
            ));
            
            // Update the cards state with the new order
            setCards(newCards);
        } catch (error) {
            console.error('Error reordering cards:', error);
            
            // Revert to original order on error
            setCards(previousCards);
            
            throw error;
        }
    }, [cards]);

    const createNoteFromCard = useCallback(async (card) => {
        try {
            // Format the card data for a note
            const noteData = {
                title: card.title || '',
                consultInfo: card.consultInfo || '',
                background: card.background || '',
                labs: card.labs || '',
                imaging: card.imaging || '',
                antimicrobials: card.antimicrobials || '',
                recommendations: card.recommendations || '',
            };
            
            // Here you would add any API calls to actually create the note
            // const createdNote = await apiService.createNote(noteData);
            
            return noteData; // or return createdNote if you implement the API call
        } catch (error) {
            console.error('Error creating note from card:', error);
            throw error;
        }
    }, []);

    const convertNoteToCard = useCallback(async (note) => {
        // Create a new card with the note data
        const newCard = {
            title: note.title || '',
            consultInfo: note.consultInfo || '',
            background: note.background || '',
            labs: note.labs || '',
            imaging: note.imaging || '',
            antimicrobials: note.antimicrobials || '',
            recommendations: note.recommendations || '',
            attestation: '', // Empty attestation as it's card-specific
            supervisee: '', // Empty supervisee as it's card-specific
            seen: true,
            modality: 'in-person'
        };

        // For the first card, ensure the cache is initialized
        if (!cardsCache) {
            cardsCache = [];
            lastFetchTimestamp = Date.now();
        }

        // Create optimistic card with temporary ID
        const tempCard = {
            ...newCard,
            id: Date.now(), // Use timestamp as temporary ID for consistency
            isOptimistic: true
        };
        
        try {
            // Optimistically update local state
            setCards(prevCards => [...prevCards, tempCard]);
            setCurrentCard(tempCard);
            
            // Create the card on the server
            const createdCard = await apiService.createCard(newCard);
            
            // Update both state and cache with the server-generated ID
            setCards(prevCards => {
                const updatedCards = prevCards.map(card =>
                    card.id === tempCard.id ? createdCard : card
                );
                // Update cache directly without affecting timestamp
                if (cardsCache) {
                    cardsCache = updatedCards;
                }
                return updatedCards;
            });
            setCurrentCard(createdCard);
            
            return createdCard;
        } catch (error) {
            console.error('Error converting note to card:', error);
            // Revert the optimistic update
            setCards(prevCards => 
                prevCards.filter(card => card.id !== tempCard.id)
            );
            setCurrentCard(null);
            throw error;
        }
    }, []);

    // Utility function for card content analysis
    const countFilledFields = useCallback((card) => {
        if (!card) return 0;
        
        const fieldIds = ['title', 'consultInfo', 'background', 'labs', 
                         'imaging', 'antimicrobials', 'recommendations'];
        
        return fieldIds.reduce((count, fieldId) => {
            const value = card[fieldId];
            return (value && value.trim().length > 0) ? count + 1 : count;
        }, 0);
    }, []);

    const handleAttestationTranscriptionUpdate = useCallback((cardId, text, shouldAppend) => {
        const card = cards.find(c => c.id === cardId);
        if (!card) return;

        const textarea = document.getElementById(`attestation-${cardId}`);
        if (!textarea) return;

        // Get current cursor position from textarea instead of state
        const currentCursorPosition = textarea.selectionStart;
        // Use the textarea's current value instead of card.attestation to get the most up-to-date content
        const currentAttestation = textarea.value || '';
        let newAttestation;
        let newCursorPosition;

        const trimmedText = text.trim();

        if (shouldAppend && currentAttestation) {
            const startsWithPunctuation = /^[.,!?;:]/.test(trimmedText);
            const beforeCursor = currentAttestation.slice(0, currentCursorPosition);
            const afterCursor = currentAttestation.slice(currentCursorPosition);

            // Determine if we need spaces
            const needsSpaceBefore = beforeCursor.length > 0 && 
                                   !beforeCursor.endsWith(' ') && 
                                   !beforeCursor.endsWith('\n') &&
                                   !startsWithPunctuation;
            
            const endsWithPunctuation = /[.,!?;:]$/.test(trimmedText);
            const needsSpaceAfter = afterCursor.length > 0 && 
                                  !afterCursor.startsWith(' ') && 
                                  !afterCursor.startsWith('\n') &&
                                  !endsWithPunctuation;

            // Build new text with appropriate spacing
            newAttestation = beforeCursor +
                           (needsSpaceBefore ? ' ' : '') +
                           trimmedText +
                           (needsSpaceAfter ? ' ' : '') +
                           afterCursor;

            // Calculate new cursor position
            newCursorPosition = currentCursorPosition + 
                              (needsSpaceBefore ? 1 : 0) + 
                              trimmedText.length;
        } else if (shouldAppend) {
            // If there's no current attestation but we should append
            newAttestation = trimmedText;
            newCursorPosition = trimmedText.length;
        } else {
            // If we shouldn't append, replace the entire content
            newAttestation = trimmedText;
            newCursorPosition = trimmedText.length;
        }

        // Return the new values without saving
        return {
            newAttestation,
            newCursorPosition
        };
    }, [cards]);

    const handleTranscriptionStart = useCallback((cardId) => {
        setTranscribingCardId(cardId);
        setActiveAttestationId(cardId);
        console.log('handleTranscriptionStart', cardId);
    }, []);

    const handleTranscriptionEnd = useCallback(() => {
        setTranscribingCardId(null);
    }, []);

    const handleAttestationFocus = useCallback((cardId) => {
        setActiveAttestationId(cardId);
    }, []);

    const handleAttestationBlur = useCallback((event) => {
        const relatedTarget = event.relatedTarget;
        if (!relatedTarget || 
            (!relatedTarget.closest('button') && 
             !relatedTarget.closest('[role="button"]'))) {
            setActiveAttestationId(null);
        }
    }, []);

    return {
        cards,
        setCards,
        isLoading,
        createCard,
        deleteCard,
        deleteAllCards,
        updateCardWithAPI,
        reorderCards,
        fetchCards,
        createNoteFromCard,
        convertNoteToCard,
        countFilledFields,
        handleAttestationTranscriptionUpdate,
        handleTranscriptionStart,
        handleTranscriptionEnd,
        handleAttestationFocus,
        handleAttestationBlur,
        transcribingCardId,
        activeAttestationId
    };
};

export default useCards; 