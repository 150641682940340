import { useState, useCallback, useEffect } from 'react';
import smartLists from '../components/data/smartLists';

export const useSmartList = (value, cursorPosition, onChange, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [dropdownPosition, setDropdownPosition] = useState({});

    const checkForSmartList = useCallback(() => {
        // Get selection information
        const selectionStart = ref?.current?.selectionStart || cursorPosition;
        const selectionEnd = ref?.current?.selectionEnd || cursorPosition;
        const hasSelection = selectionStart !== selectionEnd;
        const selectedText = hasSelection ? value.substring(selectionStart, selectionEnd) : '';

        // Case 1: Currently typing a smart list pattern
        const beforeCursor = value.slice(0, cursorPosition);
        const typingSmartListMatch = beforeCursor.match(/\{([a-zA-Z]+)(\})?$/);
        
        // Case 2: Check if cursor is within an existing smart list pattern
        let cursorWithinSmartList = false;
        let smartListRange = { start: 0, end: 0, listName: '' };
        
        if (!typingSmartListMatch && !hasSelection) {
            // Search for smart list patterns in the entire text
            const textToCheck = value;
            for (let i = 0; i < textToCheck.length; i++) {
                if (textToCheck[i] === '{') {
                    // Found an opening brace, check if it's a complete smart list pattern
                    const potentialPattern = textToCheck.substring(i);
                    const match = potentialPattern.match(/^\{([a-zA-Z]+)\}/);
                    
                    if (match) {
                        const startPos = i;
                        const endPos = i + match[0].length;
                        
                        // Check if cursor is within this pattern
                        if (cursorPosition >= startPos && cursorPosition <= endPos) {
                            cursorWithinSmartList = true;
                            smartListRange = { 
                                start: startPos, 
                                end: endPos,
                                listName: match[1].toLowerCase()
                            };
                            break;
                        }
                        
                        // Skip ahead to after this pattern
                        i = endPos - 1;
                    }
                }
            }
        }
        
        // Case 3: Check if a smart list pattern is highlighted/selected
        const selectedSmartListMatch = hasSelection ? selectedText.match(/^\{([a-zA-Z]+)\}$/) : null;
        const selectedSmartListName = selectedSmartListMatch ? selectedSmartListMatch[1].toLowerCase() : '';

        // Handle the different cases
        if (typingSmartListMatch) {
            const listName = typingSmartListMatch[1].toLowerCase();
            
            // Check if the list exists in our smartLists
            if (smartLists[listName]) {
                setItems(smartLists[listName]);
                setIsVisible(true);
                setSelectedIndex(0);
                return true;
            }
        } else if (cursorWithinSmartList && smartLists[smartListRange.listName]) {
            // Show dropdown when cursor is within a valid smart list pattern
            setItems(smartLists[smartListRange.listName]);
            setIsVisible(true);
            setSelectedIndex(0);
            return true;
        } else if (selectedSmartListMatch && smartLists[selectedSmartListName]) {
            // Show dropdown when a valid smart list pattern is highlighted
            setItems(smartLists[selectedSmartListName]);
            setIsVisible(true);
            setSelectedIndex(0);
            return true;
        }
        
        setIsVisible(false);
        return false;
    }, [value, cursorPosition, ref]);

    const handleSelect = useCallback((item) => {
        const beforeCursor = value.slice(0, cursorPosition);
        const afterCursor = value.slice(cursorPosition);
        let newContent = value;
        let newCursorPosition = cursorPosition;

        // Get selection information
        const selectionStart = ref?.current?.selectionStart || cursorPosition;
        const selectionEnd = ref?.current?.selectionEnd || cursorPosition;
        const hasSelection = selectionStart !== selectionEnd;
        const selectedText = hasSelection ? value.substring(selectionStart, selectionEnd) : '';

        // Case 1: Currently typing a smart list pattern
        const typingSmartListMatch = beforeCursor.match(/\{([a-zA-Z]+)(\})?$/);
        
        // Case 2: Check if cursor is within an existing smart list pattern
        let cursorWithinSmartList = false;
        let smartListRange = { start: 0, end: 0 };
        
        if (!typingSmartListMatch && !hasSelection) {
            // Search for smart list patterns in the entire text
            const textToCheck = value;
            for (let i = 0; i < textToCheck.length; i++) {
                if (textToCheck[i] === '{') {
                    // Found an opening brace, check if it's a complete smart list pattern
                    const potentialPattern = textToCheck.substring(i);
                    const match = potentialPattern.match(/^\{([a-zA-Z]+)\}/);
                    
                    if (match) {
                        const startPos = i;
                        const endPos = i + match[0].length;
                        
                        // Check if cursor is within this pattern
                        if (cursorPosition >= startPos && cursorPosition <= endPos) {
                            cursorWithinSmartList = true;
                            smartListRange = { 
                                start: startPos, 
                                end: endPos,
                                listName: match[1]
                            };
                            break;
                        }
                        
                        // Skip ahead to after this pattern
                        i = endPos - 1;
                    }
                }
            }
        }
        
        // Case 3: Check if a smart list pattern is highlighted/selected
        const selectedSmartListMatch = hasSelection ? selectedText.match(/^\{([a-zA-Z]+)\}$/) : null;

        // Handle the different cases
        if (typingSmartListMatch) {
            // Original behavior - replace the typing pattern
            const fullMatch = typingSmartListMatch[0];
            
            // Make sure we're replacing the entire pattern including any closing brace
            // This handles both "{list" and "{list}" cases
            const replacementStartPos = beforeCursor.length - fullMatch.length;
            
            // Check if there's a closing brace right after the cursor position
            // This handles the case where the cursor is positioned right before the closing brace
            const hasClosingBraceAfterCursor = afterCursor.startsWith('}') && !fullMatch.endsWith('}');
            
            newContent = beforeCursor.slice(0, -fullMatch.length) + item + 
                         (hasClosingBraceAfterCursor ? afterCursor.substring(1) : afterCursor);
            
            newCursorPosition = replacementStartPos + item.length;
        } else if (cursorWithinSmartList) {
            // Replace the entire smart list pattern with the selected item
            newContent = value.substring(0, smartListRange.start) + 
                         item + 
                         value.substring(smartListRange.end);
            newCursorPosition = smartListRange.start + item.length;
        } else if (selectedSmartListMatch) {
            // Replace the selected smart list pattern with the selected item
            newContent = value.substring(0, selectionStart) + 
                         item + 
                         value.substring(selectionEnd);
            newCursorPosition = selectionStart + item.length;
        }

        onChange({ target: { value: newContent } });
        
        // Set the cursor position after the replacement
        setTimeout(() => {
            if (ref?.current) {
                ref.current.selectionStart = newCursorPosition;
                ref.current.selectionEnd = newCursorPosition;
            }
        }, 0);
        
        setIsVisible(false);
        return newCursorPosition;
    }, [value, cursorPosition, onChange, ref]);

    useEffect(() => {
        checkForSmartList();
    }, [checkForSmartList]);

    const calculateDropdownPosition = useCallback(() => {
        if (!ref?.current) {
            return {};
        }

        const textareaRect = ref.current.getBoundingClientRect();
        
        // Calculate viewport-relative position
        const dropdownWidth = Math.max(200, textareaRect.width * 0.5);
        
        // Ensure the dropdown doesn't go off-screen
        const leftPosition = Math.min(
            textareaRect.left,
            window.innerWidth - dropdownWidth
        );

        return {
            top: textareaRect.bottom,
            left: leftPosition,
            width: dropdownWidth,
        };
    }, [ref]);

    return {
        isVisible,
        setIsVisible,
        items,
        selectedIndex,
        setSelectedIndex,
        handleSelect,
        dropdownPosition,
        setDropdownPosition,
        calculateDropdownPosition
    };
}; 